@font-face {
  font-family: Material Icons;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("MaterialIcons-Regular.e9e55c63.eot");
  src: local(☺), url("MaterialIcons-Regular.b99eb5ce.woff2") format("woff2"), url("MaterialIcons-Regular.12b3b105.woff") format("woff"), url("MaterialIcons-Regular.333251c4.ttf") format("truetype");
}

.material-icons {
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: inherit;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  font-family: Material Icons;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.material-icons._10k:before {
  content: "";
}

.material-icons._10mp:before {
  content: "";
}

.material-icons._11mp:before {
  content: "";
}

.material-icons._123:before {
  content: "";
}

.material-icons._12mp:before {
  content: "";
}

.material-icons._13mp:before {
  content: "";
}

.material-icons._14mp:before {
  content: "";
}

.material-icons._15mp:before {
  content: "";
}

.material-icons._16mp:before {
  content: "";
}

.material-icons._17mp:before {
  content: "";
}

.material-icons._18_up_rating:before {
  content: "";
}

.material-icons._18mp:before {
  content: "";
}

.material-icons._19mp:before {
  content: "";
}

.material-icons._1k:before {
  content: "";
}

.material-icons._1k_plus:before {
  content: "";
}

.material-icons._1x_mobiledata:before {
  content: "";
}

.material-icons._20mp:before {
  content: "";
}

.material-icons._21mp:before {
  content: "";
}

.material-icons._22mp:before {
  content: "";
}

.material-icons._23mp:before {
  content: "";
}

.material-icons._24mp:before {
  content: "";
}

.material-icons._2k:before {
  content: "";
}

.material-icons._2k_plus:before {
  content: "";
}

.material-icons._2mp:before {
  content: "";
}

.material-icons._30fps:before {
  content: "";
}

.material-icons._30fps_select:before {
  content: "";
}

.material-icons._360:before {
  content: "";
}

.material-icons._3d_rotation:before {
  content: "";
}

.material-icons._3g_mobiledata:before {
  content: "";
}

.material-icons._3k:before {
  content: "";
}

.material-icons._3k_plus:before {
  content: "";
}

.material-icons._3mp:before {
  content: "";
}

.material-icons._3p:before {
  content: "";
}

.material-icons._4g_mobiledata:before {
  content: "";
}

.material-icons._4g_plus_mobiledata:before {
  content: "";
}

.material-icons._4k:before {
  content: "";
}

.material-icons._4k_plus:before {
  content: "";
}

.material-icons._4mp:before {
  content: "";
}

.material-icons._5g:before {
  content: "";
}

.material-icons._5k:before {
  content: "";
}

.material-icons._5k_plus:before {
  content: "";
}

.material-icons._5mp:before {
  content: "";
}

.material-icons._60fps:before {
  content: "";
}

.material-icons._60fps_select:before {
  content: "";
}

.material-icons._6_ft_apart:before {
  content: "";
}

.material-icons._6k:before {
  content: "";
}

.material-icons._6k_plus:before {
  content: "";
}

.material-icons._6mp:before {
  content: "";
}

.material-icons._7k:before {
  content: "";
}

.material-icons._7k_plus:before {
  content: "";
}

.material-icons._7mp:before {
  content: "";
}

.material-icons._8k:before {
  content: "";
}

.material-icons._8k_plus:before {
  content: "";
}

.material-icons._8mp:before {
  content: "";
}

.material-icons._9k:before {
  content: "";
}

.material-icons._9k_plus:before {
  content: "";
}

.material-icons._9mp:before {
  content: "";
}

.material-icons.abc:before {
  content: "";
}

.material-icons.ac_unit:before {
  content: "";
}

.material-icons.access_alarm:before {
  content: "";
}

.material-icons.access_alarms:before {
  content: "";
}

.material-icons.access_time:before {
  content: "";
}

.material-icons.access_time_filled:before {
  content: "";
}

.material-icons.accessibility:before {
  content: "";
}

.material-icons.accessibility_new:before {
  content: "";
}

.material-icons.accessible:before {
  content: "";
}

.material-icons.accessible_forward:before {
  content: "";
}

.material-icons.account_balance:before {
  content: "";
}

.material-icons.account_balance_wallet:before {
  content: "";
}

.material-icons.account_box:before {
  content: "";
}

.material-icons.account_circle:before {
  content: "";
}

.material-icons.account_tree:before {
  content: "";
}

.material-icons.ad_units:before {
  content: "";
}

.material-icons.adb:before {
  content: "";
}

.material-icons.add:before {
  content: "";
}

.material-icons.add_a_photo:before {
  content: "";
}

.material-icons.add_alarm:before {
  content: "";
}

.material-icons.add_alert:before {
  content: "";
}

.material-icons.add_box:before {
  content: "";
}

.material-icons.add_business:before {
  content: "";
}

.material-icons.add_call:before {
  content: "";
}

.material-icons.add_card:before {
  content: "";
}

.material-icons.add_chart:before {
  content: "";
}

.material-icons.add_circle:before {
  content: "";
}

.material-icons.add_circle_outline:before {
  content: "";
}

.material-icons.add_comment:before {
  content: "";
}

.material-icons.add_home:before {
  content: "";
}

.material-icons.add_home_work:before {
  content: "";
}

.material-icons.add_ic_call:before {
  content: "";
}

.material-icons.add_link:before {
  content: "";
}

.material-icons.add_location:before {
  content: "";
}

.material-icons.add_location_alt:before {
  content: "";
}

.material-icons.add_moderator:before {
  content: "";
}

.material-icons.add_photo_alternate:before {
  content: "";
}

.material-icons.add_reaction:before {
  content: "";
}

.material-icons.add_road:before {
  content: "";
}

.material-icons.add_shopping_cart:before {
  content: "";
}

.material-icons.add_task:before {
  content: "";
}

.material-icons.add_to_drive:before {
  content: "";
}

.material-icons.add_to_home_screen:before {
  content: "";
}

.material-icons.add_to_photos:before {
  content: "";
}

.material-icons.add_to_queue:before {
  content: "";
}

.material-icons.addchart:before {
  content: "";
}

.material-icons.adf_scanner:before {
  content: "";
}

.material-icons.adjust:before {
  content: "";
}

.material-icons.admin_panel_settings:before {
  content: "";
}

.material-icons.adobe:before {
  content: "";
}

.material-icons.ads_click:before {
  content: "";
}

.material-icons.agriculture:before {
  content: "";
}

.material-icons.air:before {
  content: "";
}

.material-icons.airline_seat_flat:before {
  content: "";
}

.material-icons.airline_seat_flat_angled:before {
  content: "";
}

.material-icons.airline_seat_individual_suite:before {
  content: "";
}

.material-icons.airline_seat_legroom_extra:before {
  content: "";
}

.material-icons.airline_seat_legroom_normal:before {
  content: "";
}

.material-icons.airline_seat_legroom_reduced:before {
  content: "";
}

.material-icons.airline_seat_recline_extra:before {
  content: "";
}

.material-icons.airline_seat_recline_normal:before {
  content: "";
}

.material-icons.airline_stops:before {
  content: "";
}

.material-icons.airlines:before {
  content: "";
}

.material-icons.airplane_ticket:before {
  content: "";
}

.material-icons.airplanemode_active:before {
  content: "";
}

.material-icons.airplanemode_inactive:before, .material-icons.airplanemode_off:before {
  content: "";
}

.material-icons.airplanemode_on:before {
  content: "";
}

.material-icons.airplay:before {
  content: "";
}

.material-icons.airport_shuttle:before {
  content: "";
}

.material-icons.alarm:before {
  content: "";
}

.material-icons.alarm_add:before {
  content: "";
}

.material-icons.alarm_off:before {
  content: "";
}

.material-icons.alarm_on:before {
  content: "";
}

.material-icons.album:before {
  content: "";
}

.material-icons.align_horizontal_center:before {
  content: "";
}

.material-icons.align_horizontal_left:before {
  content: "";
}

.material-icons.align_horizontal_right:before {
  content: "";
}

.material-icons.align_vertical_bottom:before {
  content: "";
}

.material-icons.align_vertical_center:before {
  content: "";
}

.material-icons.align_vertical_top:before {
  content: "";
}

.material-icons.all_inbox:before {
  content: "";
}

.material-icons.all_inclusive:before {
  content: "";
}

.material-icons.all_out:before {
  content: "";
}

.material-icons.alt_route:before {
  content: "";
}

.material-icons.alternate_email:before {
  content: "";
}

.material-icons.amp_stories:before {
  content: "";
}

.material-icons.analytics:before {
  content: "";
}

.material-icons.anchor:before {
  content: "";
}

.material-icons.android:before {
  content: "";
}

.material-icons.animation:before {
  content: "";
}

.material-icons.announcement:before {
  content: "";
}

.material-icons.aod:before {
  content: "";
}

.material-icons.apartment:before {
  content: "";
}

.material-icons.api:before {
  content: "";
}

.material-icons.app_blocking:before {
  content: "";
}

.material-icons.app_registration:before {
  content: "";
}

.material-icons.app_settings_alt:before {
  content: "";
}

.material-icons.app_shortcut:before {
  content: "";
}

.material-icons.apple:before {
  content: "";
}

.material-icons.approval:before {
  content: "";
}

.material-icons.apps:before {
  content: "";
}

.material-icons.apps_outage:before {
  content: "";
}

.material-icons.architecture:before {
  content: "";
}

.material-icons.archive:before {
  content: "";
}

.material-icons.area_chart:before {
  content: "";
}

.material-icons.arrow_back:before {
  content: "";
}

.material-icons.arrow_back_ios:before {
  content: "";
}

.material-icons.arrow_back_ios_new:before {
  content: "";
}

.material-icons.arrow_circle_down:before {
  content: "";
}

.material-icons.arrow_circle_left:before {
  content: "";
}

.material-icons.arrow_circle_right:before {
  content: "";
}

.material-icons.arrow_circle_up:before {
  content: "";
}

.material-icons.arrow_downward:before {
  content: "";
}

.material-icons.arrow_drop_down:before {
  content: "";
}

.material-icons.arrow_drop_down_circle:before {
  content: "";
}

.material-icons.arrow_drop_up:before {
  content: "";
}

.material-icons.arrow_forward:before {
  content: "";
}

.material-icons.arrow_forward_ios:before {
  content: "";
}

.material-icons.arrow_left:before {
  content: "";
}

.material-icons.arrow_right:before {
  content: "";
}

.material-icons.arrow_right_alt:before {
  content: "";
}

.material-icons.arrow_upward:before {
  content: "";
}

.material-icons.art_track:before {
  content: "";
}

.material-icons.article:before {
  content: "";
}

.material-icons.aspect_ratio:before {
  content: "";
}

.material-icons.assessment:before {
  content: "";
}

.material-icons.assignment:before {
  content: "";
}

.material-icons.assignment_ind:before {
  content: "";
}

.material-icons.assignment_late:before {
  content: "";
}

.material-icons.assignment_return:before {
  content: "";
}

.material-icons.assignment_returned:before {
  content: "";
}

.material-icons.assignment_turned_in:before {
  content: "";
}

.material-icons.assistant:before {
  content: "";
}

.material-icons.assistant_direction:before {
  content: "";
}

.material-icons.assistant_navigation:before {
  content: "";
}

.material-icons.assistant_photo:before {
  content: "";
}

.material-icons.assured_workload:before {
  content: "";
}

.material-icons.atm:before {
  content: "";
}

.material-icons.attach_email:before {
  content: "";
}

.material-icons.attach_file:before {
  content: "";
}

.material-icons.attach_money:before {
  content: "";
}

.material-icons.attachment:before {
  content: "";
}

.material-icons.attractions:before {
  content: "";
}

.material-icons.attribution:before {
  content: "";
}

.material-icons.audio_file:before {
  content: "";
}

.material-icons.audiotrack:before {
  content: "";
}

.material-icons.auto_awesome:before {
  content: "";
}

.material-icons.auto_awesome_mosaic:before {
  content: "";
}

.material-icons.auto_awesome_motion:before {
  content: "";
}

.material-icons.auto_delete:before {
  content: "";
}

.material-icons.auto_fix_high:before {
  content: "";
}

.material-icons.auto_fix_normal:before {
  content: "";
}

.material-icons.auto_fix_off:before {
  content: "";
}

.material-icons.auto_graph:before {
  content: "";
}

.material-icons.auto_mode:before {
  content: "";
}

.material-icons.auto_stories:before {
  content: "";
}

.material-icons.autofps_select:before {
  content: "";
}

.material-icons.autorenew:before {
  content: "";
}

.material-icons.av_timer:before {
  content: "";
}

.material-icons.baby_changing_station:before {
  content: "";
}

.material-icons.back_hand:before {
  content: "";
}

.material-icons.backpack:before {
  content: "";
}

.material-icons.backspace:before {
  content: "";
}

.material-icons.backup:before {
  content: "";
}

.material-icons.backup_table:before {
  content: "";
}

.material-icons.badge:before {
  content: "";
}

.material-icons.bakery_dining:before {
  content: "";
}

.material-icons.balance:before {
  content: "";
}

.material-icons.balcony:before {
  content: "";
}

.material-icons.ballot:before {
  content: "";
}

.material-icons.bar_chart:before {
  content: "";
}

.material-icons.batch_prediction:before {
  content: "";
}

.material-icons.bathroom:before {
  content: "";
}

.material-icons.bathtub:before {
  content: "";
}

.material-icons.battery_0_bar:before {
  content: "";
}

.material-icons.battery_1_bar:before {
  content: "";
}

.material-icons.battery_2_bar:before {
  content: "";
}

.material-icons.battery_3_bar:before {
  content: "";
}

.material-icons.battery_4_bar:before {
  content: "";
}

.material-icons.battery_5_bar:before {
  content: "";
}

.material-icons.battery_6_bar:before {
  content: "";
}

.material-icons.battery_alert:before {
  content: "";
}

.material-icons.battery_charging_full:before {
  content: "";
}

.material-icons.battery_full:before {
  content: "";
}

.material-icons.battery_saver:before {
  content: "";
}

.material-icons.battery_std:before {
  content: "";
}

.material-icons.battery_unknown:before {
  content: "";
}

.material-icons.beach_access:before {
  content: "";
}

.material-icons.bed:before {
  content: "";
}

.material-icons.bedroom_baby:before {
  content: "";
}

.material-icons.bedroom_child:before {
  content: "";
}

.material-icons.bedroom_parent:before {
  content: "";
}

.material-icons.bedtime:before {
  content: "";
}

.material-icons.bedtime_off:before {
  content: "";
}

.material-icons.beenhere:before {
  content: "";
}

.material-icons.bento:before {
  content: "";
}

.material-icons.bike_scooter:before {
  content: "";
}

.material-icons.biotech:before {
  content: "";
}

.material-icons.blender:before {
  content: "";
}

.material-icons.blinds:before {
  content: "";
}

.material-icons.blinds_closed:before {
  content: "";
}

.material-icons.block:before {
  content: "";
}

.material-icons.block_flipped:before {
  content: "";
}

.material-icons.bloodtype:before {
  content: "";
}

.material-icons.bluetooth:before {
  content: "";
}

.material-icons.bluetooth_audio:before {
  content: "";
}

.material-icons.bluetooth_connected:before {
  content: "";
}

.material-icons.bluetooth_disabled:before {
  content: "";
}

.material-icons.bluetooth_drive:before {
  content: "";
}

.material-icons.bluetooth_searching:before {
  content: "";
}

.material-icons.blur_circular:before {
  content: "";
}

.material-icons.blur_linear:before {
  content: "";
}

.material-icons.blur_off:before {
  content: "";
}

.material-icons.blur_on:before {
  content: "";
}

.material-icons.bolt:before {
  content: "";
}

.material-icons.book:before {
  content: "";
}

.material-icons.book_online:before {
  content: "";
}

.material-icons.bookmark:before {
  content: "";
}

.material-icons.bookmark_add:before {
  content: "";
}

.material-icons.bookmark_added:before {
  content: "";
}

.material-icons.bookmark_border:before, .material-icons.bookmark_outline:before {
  content: "";
}

.material-icons.bookmark_remove:before {
  content: "";
}

.material-icons.bookmarks:before {
  content: "";
}

.material-icons.border_all:before {
  content: "";
}

.material-icons.border_bottom:before {
  content: "";
}

.material-icons.border_clear:before {
  content: "";
}

.material-icons.border_color:before {
  content: "";
}

.material-icons.border_horizontal:before {
  content: "";
}

.material-icons.border_inner:before {
  content: "";
}

.material-icons.border_left:before {
  content: "";
}

.material-icons.border_outer:before {
  content: "";
}

.material-icons.border_right:before {
  content: "";
}

.material-icons.border_style:before {
  content: "";
}

.material-icons.border_top:before {
  content: "";
}

.material-icons.border_vertical:before {
  content: "";
}

.material-icons.boy:before {
  content: "";
}

.material-icons.branding_watermark:before {
  content: "";
}

.material-icons.breakfast_dining:before {
  content: "";
}

.material-icons.brightness_1:before {
  content: "";
}

.material-icons.brightness_2:before {
  content: "";
}

.material-icons.brightness_3:before {
  content: "";
}

.material-icons.brightness_4:before {
  content: "";
}

.material-icons.brightness_5:before {
  content: "";
}

.material-icons.brightness_6:before {
  content: "";
}

.material-icons.brightness_7:before {
  content: "";
}

.material-icons.brightness_auto:before {
  content: "";
}

.material-icons.brightness_high:before {
  content: "";
}

.material-icons.brightness_low:before {
  content: "";
}

.material-icons.brightness_medium:before {
  content: "";
}

.material-icons.broadcast_on_home:before {
  content: "";
}

.material-icons.broadcast_on_personal:before {
  content: "";
}

.material-icons.broken_image:before {
  content: "";
}

.material-icons.browse_gallery:before {
  content: "";
}

.material-icons.browser_not_supported:before {
  content: "";
}

.material-icons.browser_updated:before {
  content: "";
}

.material-icons.brunch_dining:before {
  content: "";
}

.material-icons.brush:before {
  content: "";
}

.material-icons.bubble_chart:before {
  content: "";
}

.material-icons.bug_report:before {
  content: "";
}

.material-icons.build:before {
  content: "";
}

.material-icons.build_circle:before {
  content: "";
}

.material-icons.bungalow:before {
  content: "";
}

.material-icons.burst_mode:before {
  content: "";
}

.material-icons.bus_alert:before {
  content: "";
}

.material-icons.business:before {
  content: "";
}

.material-icons.business_center:before {
  content: "";
}

.material-icons.cabin:before {
  content: "";
}

.material-icons.cable:before {
  content: "";
}

.material-icons.cached:before {
  content: "";
}

.material-icons.cake:before {
  content: "";
}

.material-icons.calculate:before {
  content: "";
}

.material-icons.calendar_month:before {
  content: "";
}

.material-icons.calendar_today:before {
  content: "";
}

.material-icons.calendar_view_day:before {
  content: "";
}

.material-icons.calendar_view_month:before {
  content: "";
}

.material-icons.calendar_view_week:before {
  content: "";
}

.material-icons.call:before {
  content: "";
}

.material-icons.call_end:before {
  content: "";
}

.material-icons.call_made:before {
  content: "";
}

.material-icons.call_merge:before {
  content: "";
}

.material-icons.call_missed:before {
  content: "";
}

.material-icons.call_missed_outgoing:before {
  content: "";
}

.material-icons.call_received:before {
  content: "";
}

.material-icons.call_split:before {
  content: "";
}

.material-icons.call_to_action:before {
  content: "";
}

.material-icons.camera:before {
  content: "";
}

.material-icons.camera_alt:before {
  content: "";
}

.material-icons.camera_enhance:before {
  content: "";
}

.material-icons.camera_front:before {
  content: "";
}

.material-icons.camera_indoor:before {
  content: "";
}

.material-icons.camera_outdoor:before {
  content: "";
}

.material-icons.camera_rear:before {
  content: "";
}

.material-icons.camera_roll:before {
  content: "";
}

.material-icons.cameraswitch:before {
  content: "";
}

.material-icons.campaign:before {
  content: "";
}

.material-icons.cancel:before {
  content: "";
}

.material-icons.cancel_presentation:before {
  content: "";
}

.material-icons.cancel_schedule_send:before {
  content: "";
}

.material-icons.candlestick_chart:before {
  content: "";
}

.material-icons.car_crash:before {
  content: "";
}

.material-icons.car_rental:before {
  content: "";
}

.material-icons.car_repair:before {
  content: "";
}

.material-icons.card_giftcard:before {
  content: "";
}

.material-icons.card_membership:before {
  content: "";
}

.material-icons.card_travel:before {
  content: "";
}

.material-icons.carpenter:before {
  content: "";
}

.material-icons.cases:before {
  content: "";
}

.material-icons.casino:before {
  content: "";
}

.material-icons.cast:before {
  content: "";
}

.material-icons.cast_connected:before {
  content: "";
}

.material-icons.cast_for_education:before {
  content: "";
}

.material-icons.castle:before {
  content: "";
}

.material-icons.catching_pokemon:before {
  content: "";
}

.material-icons.category:before {
  content: "";
}

.material-icons.celebration:before {
  content: "";
}

.material-icons.cell_tower:before {
  content: "";
}

.material-icons.cell_wifi:before {
  content: "";
}

.material-icons.center_focus_strong:before {
  content: "";
}

.material-icons.center_focus_weak:before {
  content: "";
}

.material-icons.chair:before {
  content: "";
}

.material-icons.chair_alt:before {
  content: "";
}

.material-icons.chalet:before {
  content: "";
}

.material-icons.change_circle:before {
  content: "";
}

.material-icons.change_history:before {
  content: "";
}

.material-icons.charging_station:before {
  content: "";
}

.material-icons.chat:before {
  content: "";
}

.material-icons.chat_bubble:before {
  content: "";
}

.material-icons.chat_bubble_outline:before {
  content: "";
}

.material-icons.check:before {
  content: "";
}

.material-icons.check_box:before {
  content: "";
}

.material-icons.check_box_outline_blank:before {
  content: "";
}

.material-icons.check_circle:before {
  content: "";
}

.material-icons.check_circle_outline:before {
  content: "";
}

.material-icons.checklist:before {
  content: "";
}

.material-icons.checklist_rtl:before {
  content: "";
}

.material-icons.checkroom:before {
  content: "";
}

.material-icons.chevron_left:before {
  content: "";
}

.material-icons.chevron_right:before {
  content: "";
}

.material-icons.child_care:before {
  content: "";
}

.material-icons.child_friendly:before {
  content: "";
}

.material-icons.chrome_reader_mode:before {
  content: "";
}

.material-icons.church:before {
  content: "";
}

.material-icons.circle:before {
  content: "";
}

.material-icons.circle_notifications:before {
  content: "";
}

.material-icons.class:before {
  content: "";
}

.material-icons.clean_hands:before {
  content: "";
}

.material-icons.cleaning_services:before {
  content: "";
}

.material-icons.clear:before {
  content: "";
}

.material-icons.clear_all:before {
  content: "";
}

.material-icons.close:before {
  content: "";
}

.material-icons.close_fullscreen:before {
  content: "";
}

.material-icons.closed_caption:before {
  content: "";
}

.material-icons.closed_caption_disabled:before {
  content: "";
}

.material-icons.closed_caption_off:before {
  content: "";
}

.material-icons.cloud:before {
  content: "";
}

.material-icons.cloud_circle:before {
  content: "";
}

.material-icons.cloud_done:before {
  content: "";
}

.material-icons.cloud_download:before {
  content: "";
}

.material-icons.cloud_off:before {
  content: "";
}

.material-icons.cloud_queue:before {
  content: "";
}

.material-icons.cloud_sync:before {
  content: "";
}

.material-icons.cloud_upload:before {
  content: "";
}

.material-icons.cloudy_snowing:before {
  content: "";
}

.material-icons.co2:before {
  content: "";
}

.material-icons.co_present:before {
  content: "";
}

.material-icons.code:before {
  content: "";
}

.material-icons.code_off:before {
  content: "";
}

.material-icons.coffee:before {
  content: "";
}

.material-icons.coffee_maker:before {
  content: "";
}

.material-icons.collections:before {
  content: "";
}

.material-icons.collections_bookmark:before {
  content: "";
}

.material-icons.color_lens:before {
  content: "";
}

.material-icons.colorize:before {
  content: "";
}

.material-icons.comment:before {
  content: "";
}

.material-icons.comment_bank:before {
  content: "";
}

.material-icons.comments_disabled:before {
  content: "";
}

.material-icons.commit:before {
  content: "";
}

.material-icons.commute:before {
  content: "";
}

.material-icons.compare:before {
  content: "";
}

.material-icons.compare_arrows:before {
  content: "";
}

.material-icons.compass_calibration:before {
  content: "";
}

.material-icons.compost:before {
  content: "";
}

.material-icons.compress:before {
  content: "";
}

.material-icons.computer:before {
  content: "";
}

.material-icons.confirmation_num:before, .material-icons.confirmation_number:before {
  content: "";
}

.material-icons.connect_without_contact:before {
  content: "";
}

.material-icons.connected_tv:before {
  content: "";
}

.material-icons.connecting_airports:before {
  content: "";
}

.material-icons.construction:before {
  content: "";
}

.material-icons.contact_mail:before {
  content: "";
}

.material-icons.contact_page:before {
  content: "";
}

.material-icons.contact_phone:before {
  content: "";
}

.material-icons.contact_support:before {
  content: "";
}

.material-icons.contactless:before {
  content: "";
}

.material-icons.contacts:before {
  content: "";
}

.material-icons.content_copy:before {
  content: "";
}

.material-icons.content_cut:before {
  content: "";
}

.material-icons.content_paste:before {
  content: "";
}

.material-icons.content_paste_go:before {
  content: "";
}

.material-icons.content_paste_off:before {
  content: "";
}

.material-icons.content_paste_search:before {
  content: "";
}

.material-icons.contrast:before {
  content: "";
}

.material-icons.control_camera:before {
  content: "";
}

.material-icons.control_point:before {
  content: "";
}

.material-icons.control_point_duplicate:before {
  content: "";
}

.material-icons.cookie:before {
  content: "";
}

.material-icons.copy_all:before {
  content: "";
}

.material-icons.copyright:before {
  content: "";
}

.material-icons.coronavirus:before {
  content: "";
}

.material-icons.corporate_fare:before {
  content: "";
}

.material-icons.cottage:before {
  content: "";
}

.material-icons.countertops:before {
  content: "";
}

.material-icons.create:before {
  content: "";
}

.material-icons.create_new_folder:before {
  content: "";
}

.material-icons.credit_card:before {
  content: "";
}

.material-icons.credit_card_off:before {
  content: "";
}

.material-icons.credit_score:before {
  content: "";
}

.material-icons.crib:before {
  content: "";
}

.material-icons.crisis_alert:before {
  content: "";
}

.material-icons.crop:before {
  content: "";
}

.material-icons.crop_16_9:before {
  content: "";
}

.material-icons.crop_3_2:before {
  content: "";
}

.material-icons.crop_5_4:before {
  content: "";
}

.material-icons.crop_7_5:before {
  content: "";
}

.material-icons.crop_din:before {
  content: "";
}

.material-icons.crop_free:before {
  content: "";
}

.material-icons.crop_landscape:before {
  content: "";
}

.material-icons.crop_original:before {
  content: "";
}

.material-icons.crop_portrait:before {
  content: "";
}

.material-icons.crop_rotate:before {
  content: "";
}

.material-icons.crop_square:before {
  content: "";
}

.material-icons.cruelty_free:before {
  content: "";
}

.material-icons.css:before {
  content: "";
}

.material-icons.currency_bitcoin:before {
  content: "";
}

.material-icons.currency_exchange:before {
  content: "";
}

.material-icons.currency_franc:before {
  content: "";
}

.material-icons.currency_lira:before {
  content: "";
}

.material-icons.currency_pound:before {
  content: "";
}

.material-icons.currency_ruble:before {
  content: "";
}

.material-icons.currency_rupee:before {
  content: "";
}

.material-icons.currency_yen:before {
  content: "";
}

.material-icons.currency_yuan:before {
  content: "";
}

.material-icons.curtains:before {
  content: "";
}

.material-icons.curtains_closed:before {
  content: "";
}

.material-icons.cyclone:before {
  content: "";
}

.material-icons.dangerous:before {
  content: "";
}

.material-icons.dark_mode:before {
  content: "";
}

.material-icons.dashboard:before {
  content: "";
}

.material-icons.dashboard_customize:before {
  content: "";
}

.material-icons.data_array:before {
  content: "";
}

.material-icons.data_exploration:before {
  content: "";
}

.material-icons.data_object:before {
  content: "";
}

.material-icons.data_saver_off:before {
  content: "";
}

.material-icons.data_saver_on:before {
  content: "";
}

.material-icons.data_thresholding:before {
  content: "";
}

.material-icons.data_usage:before {
  content: "";
}

.material-icons.dataset:before {
  content: "";
}

.material-icons.dataset_linked:before {
  content: "";
}

.material-icons.date_range:before {
  content: "";
}

.material-icons.deblur:before {
  content: "";
}

.material-icons.deck:before {
  content: "";
}

.material-icons.dehaze:before {
  content: "";
}

.material-icons.delete:before {
  content: "";
}

.material-icons.delete_forever:before {
  content: "";
}

.material-icons.delete_outline:before {
  content: "";
}

.material-icons.delete_sweep:before {
  content: "";
}

.material-icons.delivery_dining:before {
  content: "";
}

.material-icons.density_large:before {
  content: "";
}

.material-icons.density_medium:before {
  content: "";
}

.material-icons.density_small:before {
  content: "";
}

.material-icons.departure_board:before {
  content: "";
}

.material-icons.description:before {
  content: "";
}

.material-icons.deselect:before {
  content: "";
}

.material-icons.design_services:before {
  content: "";
}

.material-icons.desk:before {
  content: "";
}

.material-icons.desktop_access_disabled:before {
  content: "";
}

.material-icons.desktop_mac:before {
  content: "";
}

.material-icons.desktop_windows:before {
  content: "";
}

.material-icons.details:before {
  content: "";
}

.material-icons.developer_board:before {
  content: "";
}

.material-icons.developer_board_off:before {
  content: "";
}

.material-icons.developer_mode:before {
  content: "";
}

.material-icons.device_hub:before {
  content: "";
}

.material-icons.device_thermostat:before {
  content: "";
}

.material-icons.device_unknown:before {
  content: "";
}

.material-icons.devices:before {
  content: "";
}

.material-icons.devices_fold:before {
  content: "";
}

.material-icons.devices_other:before {
  content: "";
}

.material-icons.dialer_sip:before {
  content: "";
}

.material-icons.dialpad:before {
  content: "";
}

.material-icons.diamond:before {
  content: "";
}

.material-icons.difference:before {
  content: "";
}

.material-icons.dining:before {
  content: "";
}

.material-icons.dinner_dining:before {
  content: "";
}

.material-icons.directions:before {
  content: "";
}

.material-icons.directions_bike:before {
  content: "";
}

.material-icons.directions_boat:before {
  content: "";
}

.material-icons.directions_boat_filled:before {
  content: "";
}

.material-icons.directions_bus:before {
  content: "";
}

.material-icons.directions_bus_filled:before {
  content: "";
}

.material-icons.directions_car:before {
  content: "";
}

.material-icons.directions_car_filled:before {
  content: "";
}

.material-icons.directions_ferry:before {
  content: "";
}

.material-icons.directions_off:before {
  content: "";
}

.material-icons.directions_railway:before {
  content: "";
}

.material-icons.directions_railway_filled:before {
  content: "";
}

.material-icons.directions_run:before {
  content: "";
}

.material-icons.directions_subway:before {
  content: "";
}

.material-icons.directions_subway_filled:before {
  content: "";
}

.material-icons.directions_train:before {
  content: "";
}

.material-icons.directions_transit:before {
  content: "";
}

.material-icons.directions_transit_filled:before {
  content: "";
}

.material-icons.directions_walk:before {
  content: "";
}

.material-icons.dirty_lens:before {
  content: "";
}

.material-icons.disabled_by_default:before {
  content: "";
}

.material-icons.disabled_visible:before {
  content: "";
}

.material-icons.disc_full:before {
  content: "";
}

.material-icons.discord:before {
  content: "";
}

.material-icons.discount:before {
  content: "";
}

.material-icons.display_settings:before {
  content: "";
}

.material-icons.dnd_forwardslash:before {
  content: "";
}

.material-icons.dns:before {
  content: "";
}

.material-icons.do_disturb:before {
  content: "";
}

.material-icons.do_disturb_alt:before {
  content: "";
}

.material-icons.do_disturb_off:before {
  content: "";
}

.material-icons.do_disturb_on:before {
  content: "";
}

.material-icons.do_not_disturb:before {
  content: "";
}

.material-icons.do_not_disturb_alt:before {
  content: "";
}

.material-icons.do_not_disturb_off:before {
  content: "";
}

.material-icons.do_not_disturb_on:before {
  content: "";
}

.material-icons.do_not_disturb_on_total_silence:before {
  content: "";
}

.material-icons.do_not_step:before {
  content: "";
}

.material-icons.do_not_touch:before {
  content: "";
}

.material-icons.dock:before {
  content: "";
}

.material-icons.document_scanner:before {
  content: "";
}

.material-icons.domain:before {
  content: "";
}

.material-icons.domain_add:before {
  content: "";
}

.material-icons.domain_disabled:before {
  content: "";
}

.material-icons.domain_verification:before {
  content: "";
}

.material-icons.done:before {
  content: "";
}

.material-icons.done_all:before {
  content: "";
}

.material-icons.done_outline:before {
  content: "";
}

.material-icons.donut_large:before {
  content: "";
}

.material-icons.donut_small:before {
  content: "";
}

.material-icons.door_back:before {
  content: "";
}

.material-icons.door_front:before {
  content: "";
}

.material-icons.door_sliding:before {
  content: "";
}

.material-icons.doorbell:before {
  content: "";
}

.material-icons.double_arrow:before {
  content: "";
}

.material-icons.downhill_skiing:before {
  content: "";
}

.material-icons.download:before {
  content: "";
}

.material-icons.download_done:before {
  content: "";
}

.material-icons.download_for_offline:before {
  content: "";
}

.material-icons.downloading:before {
  content: "";
}

.material-icons.drafts:before {
  content: "";
}

.material-icons.drag_handle:before {
  content: "";
}

.material-icons.drag_indicator:before {
  content: "";
}

.material-icons.draw:before {
  content: "";
}

.material-icons.drive_eta:before {
  content: "";
}

.material-icons.drive_file_move:before {
  content: "";
}

.material-icons.drive_file_move_outline:before {
  content: "";
}

.material-icons.drive_file_move_rtl:before {
  content: "";
}

.material-icons.drive_file_rename_outline:before {
  content: "";
}

.material-icons.drive_folder_upload:before {
  content: "";
}

.material-icons.dry:before {
  content: "";
}

.material-icons.dry_cleaning:before {
  content: "";
}

.material-icons.duo:before {
  content: "";
}

.material-icons.dvr:before {
  content: "";
}

.material-icons.dynamic_feed:before {
  content: "";
}

.material-icons.dynamic_form:before {
  content: "";
}

.material-icons.e_mobiledata:before {
  content: "";
}

.material-icons.earbuds:before {
  content: "";
}

.material-icons.earbuds_battery:before {
  content: "";
}

.material-icons.east:before {
  content: "";
}

.material-icons.eco:before {
  content: "";
}

.material-icons.edgesensor_high:before {
  content: "";
}

.material-icons.edgesensor_low:before {
  content: "";
}

.material-icons.edit:before {
  content: "";
}

.material-icons.edit_attributes:before {
  content: "";
}

.material-icons.edit_calendar:before {
  content: "";
}

.material-icons.edit_location:before {
  content: "";
}

.material-icons.edit_location_alt:before {
  content: "";
}

.material-icons.edit_note:before {
  content: "";
}

.material-icons.edit_notifications:before {
  content: "";
}

.material-icons.edit_off:before {
  content: "";
}

.material-icons.edit_road:before {
  content: "";
}

.material-icons.egg:before {
  content: "";
}

.material-icons.egg_alt:before {
  content: "";
}

.material-icons.eject:before {
  content: "";
}

.material-icons.elderly:before {
  content: "";
}

.material-icons.elderly_woman:before {
  content: "";
}

.material-icons.electric_bike:before {
  content: "";
}

.material-icons.electric_bolt:before {
  content: "";
}

.material-icons.electric_car:before {
  content: "";
}

.material-icons.electric_meter:before {
  content: "";
}

.material-icons.electric_moped:before {
  content: "";
}

.material-icons.electric_rickshaw:before {
  content: "";
}

.material-icons.electric_scooter:before {
  content: "";
}

.material-icons.electrical_services:before {
  content: "";
}

.material-icons.elevator:before {
  content: "";
}

.material-icons.email:before {
  content: "";
}

.material-icons.emergency:before {
  content: "";
}

.material-icons.emergency_recording:before {
  content: "";
}

.material-icons.emergency_share:before {
  content: "";
}

.material-icons.emoji_emotions:before {
  content: "";
}

.material-icons.emoji_events:before {
  content: "";
}

.material-icons.emoji_flags:before {
  content: "";
}

.material-icons.emoji_food_beverage:before {
  content: "";
}

.material-icons.emoji_nature:before {
  content: "";
}

.material-icons.emoji_objects:before {
  content: "";
}

.material-icons.emoji_people:before {
  content: "";
}

.material-icons.emoji_symbols:before {
  content: "";
}

.material-icons.emoji_transportation:before {
  content: "";
}

.material-icons.energy_savings_leaf:before {
  content: "";
}

.material-icons.engineering:before {
  content: "";
}

.material-icons.enhance_photo_translate:before {
  content: "";
}

.material-icons.enhanced_encryption:before {
  content: "";
}

.material-icons.equalizer:before {
  content: "";
}

.material-icons.error:before {
  content: "";
}

.material-icons.error_outline:before {
  content: "";
}

.material-icons.escalator:before {
  content: "";
}

.material-icons.escalator_warning:before {
  content: "";
}

.material-icons.euro:before {
  content: "";
}

.material-icons.euro_symbol:before {
  content: "";
}

.material-icons.ev_station:before {
  content: "";
}

.material-icons.event:before {
  content: "";
}

.material-icons.event_available:before {
  content: "";
}

.material-icons.event_busy:before {
  content: "";
}

.material-icons.event_note:before {
  content: "";
}

.material-icons.event_repeat:before {
  content: "";
}

.material-icons.event_seat:before {
  content: "";
}

.material-icons.exit_to_app:before {
  content: "";
}

.material-icons.expand:before {
  content: "";
}

.material-icons.expand_circle_down:before {
  content: "";
}

.material-icons.expand_less:before {
  content: "";
}

.material-icons.expand_more:before {
  content: "";
}

.material-icons.explicit:before {
  content: "";
}

.material-icons.explore:before {
  content: "";
}

.material-icons.explore_off:before {
  content: "";
}

.material-icons.exposure:before {
  content: "";
}

.material-icons.exposure_minus_1:before {
  content: "";
}

.material-icons.exposure_minus_2:before {
  content: "";
}

.material-icons.exposure_neg_1:before {
  content: "";
}

.material-icons.exposure_neg_2:before {
  content: "";
}

.material-icons.exposure_plus_1:before {
  content: "";
}

.material-icons.exposure_plus_2:before {
  content: "";
}

.material-icons.exposure_zero:before {
  content: "";
}

.material-icons.extension:before {
  content: "";
}

.material-icons.extension_off:before {
  content: "";
}

.material-icons.face:before {
  content: "";
}

.material-icons.face_retouching_natural:before {
  content: "";
}

.material-icons.face_retouching_off:before {
  content: "";
}

.material-icons.facebook:before {
  content: "";
}

.material-icons.fact_check:before {
  content: "";
}

.material-icons.factory:before {
  content: "";
}

.material-icons.family_restroom:before {
  content: "";
}

.material-icons.fast_forward:before {
  content: "";
}

.material-icons.fast_rewind:before {
  content: "";
}

.material-icons.fastfood:before {
  content: "";
}

.material-icons.favorite:before {
  content: "";
}

.material-icons.favorite_border:before, .material-icons.favorite_outline:before {
  content: "";
}

.material-icons.fax:before {
  content: "";
}

.material-icons.featured_play_list:before {
  content: "";
}

.material-icons.featured_video:before {
  content: "";
}

.material-icons.feed:before {
  content: "";
}

.material-icons.feedback:before {
  content: "";
}

.material-icons.female:before {
  content: "";
}

.material-icons.fence:before {
  content: "";
}

.material-icons.festival:before {
  content: "";
}

.material-icons.fiber_dvr:before {
  content: "";
}

.material-icons.fiber_manual_record:before {
  content: "";
}

.material-icons.fiber_new:before {
  content: "";
}

.material-icons.fiber_pin:before {
  content: "";
}

.material-icons.fiber_smart_record:before {
  content: "";
}

.material-icons.file_copy:before {
  content: "";
}

.material-icons.file_download:before {
  content: "";
}

.material-icons.file_download_done:before {
  content: "";
}

.material-icons.file_download_off:before {
  content: "";
}

.material-icons.file_open:before {
  content: "";
}

.material-icons.file_present:before {
  content: "";
}

.material-icons.file_upload:before {
  content: "";
}

.material-icons.filter:before {
  content: "";
}

.material-icons.filter_1:before {
  content: "";
}

.material-icons.filter_2:before {
  content: "";
}

.material-icons.filter_3:before {
  content: "";
}

.material-icons.filter_4:before {
  content: "";
}

.material-icons.filter_5:before {
  content: "";
}

.material-icons.filter_6:before {
  content: "";
}

.material-icons.filter_7:before {
  content: "";
}

.material-icons.filter_8:before {
  content: "";
}

.material-icons.filter_9:before {
  content: "";
}

.material-icons.filter_9_plus:before {
  content: "";
}

.material-icons.filter_alt:before {
  content: "";
}

.material-icons.filter_alt_off:before {
  content: "";
}

.material-icons.filter_b_and_w:before {
  content: "";
}

.material-icons.filter_center_focus:before {
  content: "";
}

.material-icons.filter_drama:before {
  content: "";
}

.material-icons.filter_frames:before {
  content: "";
}

.material-icons.filter_hdr:before {
  content: "";
}

.material-icons.filter_list:before {
  content: "";
}

.material-icons.filter_list_alt:before {
  content: "";
}

.material-icons.filter_list_off:before {
  content: "";
}

.material-icons.filter_none:before {
  content: "";
}

.material-icons.filter_tilt_shift:before {
  content: "";
}

.material-icons.filter_vintage:before {
  content: "";
}

.material-icons.find_in_page:before {
  content: "";
}

.material-icons.find_replace:before {
  content: "";
}

.material-icons.fingerprint:before {
  content: "";
}

.material-icons.fire_extinguisher:before {
  content: "";
}

.material-icons.fire_hydrant:before {
  content: "";
}

.material-icons.fire_hydrant_alt:before {
  content: "";
}

.material-icons.fire_truck:before {
  content: "";
}

.material-icons.fireplace:before {
  content: "";
}

.material-icons.first_page:before {
  content: "";
}

.material-icons.fit_screen:before {
  content: "";
}

.material-icons.fitbit:before {
  content: "";
}

.material-icons.fitness_center:before {
  content: "";
}

.material-icons.flag:before {
  content: "";
}

.material-icons.flag_circle:before {
  content: "";
}

.material-icons.flaky:before {
  content: "";
}

.material-icons.flare:before {
  content: "";
}

.material-icons.flash_auto:before {
  content: "";
}

.material-icons.flash_off:before {
  content: "";
}

.material-icons.flash_on:before {
  content: "";
}

.material-icons.flashlight_off:before {
  content: "";
}

.material-icons.flashlight_on:before {
  content: "";
}

.material-icons.flatware:before {
  content: "";
}

.material-icons.flight:before {
  content: "";
}

.material-icons.flight_class:before {
  content: "";
}

.material-icons.flight_land:before {
  content: "";
}

.material-icons.flight_takeoff:before {
  content: "";
}

.material-icons.flip:before {
  content: "";
}

.material-icons.flip_camera_android:before {
  content: "";
}

.material-icons.flip_camera_ios:before {
  content: "";
}

.material-icons.flip_to_back:before {
  content: "";
}

.material-icons.flip_to_front:before {
  content: "";
}

.material-icons.flood:before {
  content: "";
}

.material-icons.flourescent:before {
  content: "";
}

.material-icons.flutter_dash:before {
  content: "";
}

.material-icons.fmd_bad:before {
  content: "";
}

.material-icons.fmd_good:before {
  content: "";
}

.material-icons.foggy:before {
  content: "";
}

.material-icons.folder:before {
  content: "";
}

.material-icons.folder_copy:before {
  content: "";
}

.material-icons.folder_delete:before {
  content: "";
}

.material-icons.folder_off:before {
  content: "";
}

.material-icons.folder_open:before {
  content: "";
}

.material-icons.folder_shared:before {
  content: "";
}

.material-icons.folder_special:before {
  content: "";
}

.material-icons.folder_zip:before {
  content: "";
}

.material-icons.follow_the_signs:before {
  content: "";
}

.material-icons.font_download:before {
  content: "";
}

.material-icons.font_download_off:before {
  content: "";
}

.material-icons.food_bank:before {
  content: "";
}

.material-icons.forest:before {
  content: "";
}

.material-icons.fork_left:before {
  content: "";
}

.material-icons.fork_right:before {
  content: "";
}

.material-icons.format_align_center:before {
  content: "";
}

.material-icons.format_align_justify:before {
  content: "";
}

.material-icons.format_align_left:before {
  content: "";
}

.material-icons.format_align_right:before {
  content: "";
}

.material-icons.format_bold:before {
  content: "";
}

.material-icons.format_clear:before {
  content: "";
}

.material-icons.format_color_fill:before {
  content: "";
}

.material-icons.format_color_reset:before {
  content: "";
}

.material-icons.format_color_text:before {
  content: "";
}

.material-icons.format_indent_decrease:before {
  content: "";
}

.material-icons.format_indent_increase:before {
  content: "";
}

.material-icons.format_italic:before {
  content: "";
}

.material-icons.format_line_spacing:before {
  content: "";
}

.material-icons.format_list_bulleted:before {
  content: "";
}

.material-icons.format_list_numbered:before {
  content: "";
}

.material-icons.format_list_numbered_rtl:before {
  content: "";
}

.material-icons.format_overline:before {
  content: "";
}

.material-icons.format_paint:before {
  content: "";
}

.material-icons.format_quote:before {
  content: "";
}

.material-icons.format_shapes:before {
  content: "";
}

.material-icons.format_size:before {
  content: "";
}

.material-icons.format_strikethrough:before {
  content: "";
}

.material-icons.format_textdirection_l_to_r:before {
  content: "";
}

.material-icons.format_textdirection_r_to_l:before {
  content: "";
}

.material-icons.format_underline:before, .material-icons.format_underlined:before {
  content: "";
}

.material-icons.fort:before {
  content: "";
}

.material-icons.forum:before {
  content: "";
}

.material-icons.forward:before {
  content: "";
}

.material-icons.forward_10:before {
  content: "";
}

.material-icons.forward_30:before {
  content: "";
}

.material-icons.forward_5:before {
  content: "";
}

.material-icons.forward_to_inbox:before {
  content: "";
}

.material-icons.foundation:before {
  content: "";
}

.material-icons.free_breakfast:before {
  content: "";
}

.material-icons.free_cancellation:before {
  content: "";
}

.material-icons.front_hand:before {
  content: "";
}

.material-icons.fullscreen:before {
  content: "";
}

.material-icons.fullscreen_exit:before {
  content: "";
}

.material-icons.functions:before {
  content: "";
}

.material-icons.g_mobiledata:before {
  content: "";
}

.material-icons.g_translate:before {
  content: "";
}

.material-icons.gamepad:before {
  content: "";
}

.material-icons.games:before {
  content: "";
}

.material-icons.garage:before {
  content: "";
}

.material-icons.gas_meter:before {
  content: "";
}

.material-icons.gavel:before {
  content: "";
}

.material-icons.generating_tokens:before {
  content: "";
}

.material-icons.gesture:before {
  content: "";
}

.material-icons.get_app:before {
  content: "";
}

.material-icons.gif:before {
  content: "";
}

.material-icons.gif_box:before {
  content: "";
}

.material-icons.girl:before {
  content: "";
}

.material-icons.gite:before {
  content: "";
}

.material-icons.goat:before {
  content: "";
}

.material-icons.golf_course:before {
  content: "";
}

.material-icons.gpp_bad:before {
  content: "";
}

.material-icons.gpp_good:before {
  content: "";
}

.material-icons.gpp_maybe:before {
  content: "";
}

.material-icons.gps_fixed:before {
  content: "";
}

.material-icons.gps_not_fixed:before {
  content: "";
}

.material-icons.gps_off:before {
  content: "";
}

.material-icons.grade:before {
  content: "";
}

.material-icons.gradient:before {
  content: "";
}

.material-icons.grading:before {
  content: "";
}

.material-icons.grain:before {
  content: "";
}

.material-icons.graphic_eq:before {
  content: "";
}

.material-icons.grass:before {
  content: "";
}

.material-icons.grid_3x3:before {
  content: "";
}

.material-icons.grid_4x4:before {
  content: "";
}

.material-icons.grid_goldenratio:before {
  content: "";
}

.material-icons.grid_off:before {
  content: "";
}

.material-icons.grid_on:before {
  content: "";
}

.material-icons.grid_view:before {
  content: "";
}

.material-icons.group:before {
  content: "";
}

.material-icons.group_add:before {
  content: "";
}

.material-icons.group_off:before {
  content: "";
}

.material-icons.group_remove:before {
  content: "";
}

.material-icons.group_work:before {
  content: "";
}

.material-icons.groups:before {
  content: "";
}

.material-icons.h_mobiledata:before {
  content: "";
}

.material-icons.h_plus_mobiledata:before {
  content: "";
}

.material-icons.hail:before {
  content: "";
}

.material-icons.handshake:before {
  content: "";
}

.material-icons.handyman:before {
  content: "";
}

.material-icons.hardware:before {
  content: "";
}

.material-icons.hd:before {
  content: "";
}

.material-icons.hdr_auto:before {
  content: "";
}

.material-icons.hdr_auto_select:before {
  content: "";
}

.material-icons.hdr_enhanced_select:before {
  content: "";
}

.material-icons.hdr_off:before {
  content: "";
}

.material-icons.hdr_off_select:before {
  content: "";
}

.material-icons.hdr_on:before {
  content: "";
}

.material-icons.hdr_on_select:before {
  content: "";
}

.material-icons.hdr_plus:before {
  content: "";
}

.material-icons.hdr_strong:before {
  content: "";
}

.material-icons.hdr_weak:before {
  content: "";
}

.material-icons.headphones:before {
  content: "";
}

.material-icons.headphones_battery:before {
  content: "";
}

.material-icons.headset:before {
  content: "";
}

.material-icons.headset_mic:before {
  content: "";
}

.material-icons.headset_off:before {
  content: "";
}

.material-icons.healing:before {
  content: "";
}

.material-icons.health_and_safety:before {
  content: "";
}

.material-icons.hearing:before {
  content: "";
}

.material-icons.hearing_disabled:before {
  content: "";
}

.material-icons.heart_broken:before {
  content: "";
}

.material-icons.heat_pump:before {
  content: "";
}

.material-icons.height:before {
  content: "";
}

.material-icons.help:before {
  content: "";
}

.material-icons.help_center:before {
  content: "";
}

.material-icons.help_outline:before {
  content: "";
}

.material-icons.hevc:before {
  content: "";
}

.material-icons.hexagon:before {
  content: "";
}

.material-icons.hide_image:before {
  content: "";
}

.material-icons.hide_source:before {
  content: "";
}

.material-icons.high_quality:before {
  content: "";
}

.material-icons.highlight:before {
  content: "";
}

.material-icons.highlight_alt:before {
  content: "";
}

.material-icons.highlight_off:before, .material-icons.highlight_remove:before {
  content: "";
}

.material-icons.hiking:before {
  content: "";
}

.material-icons.history:before {
  content: "";
}

.material-icons.history_edu:before {
  content: "";
}

.material-icons.history_toggle_off:before {
  content: "";
}

.material-icons.hive:before {
  content: "";
}

.material-icons.hls:before {
  content: "";
}

.material-icons.hls_off:before {
  content: "";
}

.material-icons.holiday_village:before {
  content: "";
}

.material-icons.home:before {
  content: "";
}

.material-icons.home_filled:before {
  content: "";
}

.material-icons.home_max:before {
  content: "";
}

.material-icons.home_mini:before {
  content: "";
}

.material-icons.home_repair_service:before {
  content: "";
}

.material-icons.home_work:before {
  content: "";
}

.material-icons.horizontal_distribute:before {
  content: "";
}

.material-icons.horizontal_rule:before {
  content: "";
}

.material-icons.horizontal_split:before {
  content: "";
}

.material-icons.hot_tub:before {
  content: "";
}

.material-icons.hotel:before {
  content: "";
}

.material-icons.hotel_class:before {
  content: "";
}

.material-icons.hourglass_bottom:before {
  content: "";
}

.material-icons.hourglass_disabled:before {
  content: "";
}

.material-icons.hourglass_empty:before {
  content: "";
}

.material-icons.hourglass_full:before {
  content: "";
}

.material-icons.hourglass_top:before {
  content: "";
}

.material-icons.house:before {
  content: "";
}

.material-icons.house_siding:before {
  content: "";
}

.material-icons.houseboat:before {
  content: "";
}

.material-icons.how_to_reg:before {
  content: "";
}

.material-icons.how_to_vote:before {
  content: "";
}

.material-icons.html:before {
  content: "";
}

.material-icons.http:before {
  content: "";
}

.material-icons.https:before {
  content: "";
}

.material-icons.hub:before {
  content: "";
}

.material-icons.hvac:before {
  content: "";
}

.material-icons.ice_skating:before {
  content: "";
}

.material-icons.icecream:before {
  content: "";
}

.material-icons.image:before {
  content: "";
}

.material-icons.image_aspect_ratio:before {
  content: "";
}

.material-icons.image_not_supported:before {
  content: "";
}

.material-icons.image_search:before {
  content: "";
}

.material-icons.imagesearch_roller:before {
  content: "";
}

.material-icons.import_contacts:before {
  content: "";
}

.material-icons.import_export:before {
  content: "";
}

.material-icons.important_devices:before {
  content: "";
}

.material-icons.inbox:before {
  content: "";
}

.material-icons.incomplete_circle:before {
  content: "";
}

.material-icons.indeterminate_check_box:before {
  content: "";
}

.material-icons.info:before {
  content: "";
}

.material-icons.info_outline:before {
  content: "";
}

.material-icons.input:before {
  content: "";
}

.material-icons.insert_chart:before {
  content: "";
}

.material-icons.insert_chart_outlined:before {
  content: "";
}

.material-icons.insert_comment:before {
  content: "";
}

.material-icons.insert_drive_file:before {
  content: "";
}

.material-icons.insert_emoticon:before {
  content: "";
}

.material-icons.insert_invitation:before {
  content: "";
}

.material-icons.insert_link:before {
  content: "";
}

.material-icons.insert_page_break:before {
  content: "";
}

.material-icons.insert_photo:before {
  content: "";
}

.material-icons.insights:before {
  content: "";
}

.material-icons.install_desktop:before {
  content: "";
}

.material-icons.install_mobile:before {
  content: "";
}

.material-icons.integration_instructions:before {
  content: "";
}

.material-icons.interests:before {
  content: "";
}

.material-icons.interpreter_mode:before {
  content: "";
}

.material-icons.inventory:before {
  content: "";
}

.material-icons.inventory_2:before {
  content: "";
}

.material-icons.invert_colors:before {
  content: "";
}

.material-icons.invert_colors_off:before {
  content: "";
}

.material-icons.invert_colors_on:before {
  content: "";
}

.material-icons.ios_share:before {
  content: "";
}

.material-icons.iron:before {
  content: "";
}

.material-icons.iso:before {
  content: "";
}

.material-icons.javascript:before {
  content: "";
}

.material-icons.join_full:before {
  content: "";
}

.material-icons.join_inner:before {
  content: "";
}

.material-icons.join_left:before {
  content: "";
}

.material-icons.join_right:before {
  content: "";
}

.material-icons.kayaking:before {
  content: "";
}

.material-icons.kebab_dining:before {
  content: "";
}

.material-icons.key:before {
  content: "";
}

.material-icons.key_off:before {
  content: "";
}

.material-icons.keyboard:before {
  content: "";
}

.material-icons.keyboard_alt:before {
  content: "";
}

.material-icons.keyboard_arrow_down:before {
  content: "";
}

.material-icons.keyboard_arrow_left:before {
  content: "";
}

.material-icons.keyboard_arrow_right:before {
  content: "";
}

.material-icons.keyboard_arrow_up:before {
  content: "";
}

.material-icons.keyboard_backspace:before {
  content: "";
}

.material-icons.keyboard_capslock:before {
  content: "";
}

.material-icons.keyboard_command:before {
  content: "";
}

.material-icons.keyboard_command_key:before {
  content: "";
}

.material-icons.keyboard_control:before {
  content: "";
}

.material-icons.keyboard_control_key:before {
  content: "";
}

.material-icons.keyboard_double_arrow_down:before {
  content: "";
}

.material-icons.keyboard_double_arrow_left:before {
  content: "";
}

.material-icons.keyboard_double_arrow_right:before {
  content: "";
}

.material-icons.keyboard_double_arrow_up:before {
  content: "";
}

.material-icons.keyboard_hide:before {
  content: "";
}

.material-icons.keyboard_option:before {
  content: "";
}

.material-icons.keyboard_option_key:before {
  content: "";
}

.material-icons.keyboard_return:before {
  content: "";
}

.material-icons.keyboard_tab:before {
  content: "";
}

.material-icons.keyboard_voice:before {
  content: "";
}

.material-icons.king_bed:before {
  content: "";
}

.material-icons.kitchen:before {
  content: "";
}

.material-icons.kitesurfing:before {
  content: "";
}

.material-icons.label:before {
  content: "";
}

.material-icons.label_important:before {
  content: "";
}

.material-icons.label_important_outline:before {
  content: "";
}

.material-icons.label_off:before {
  content: "";
}

.material-icons.label_outline:before {
  content: "";
}

.material-icons.lan:before {
  content: "";
}

.material-icons.landscape:before {
  content: "";
}

.material-icons.landslide:before {
  content: "";
}

.material-icons.language:before {
  content: "";
}

.material-icons.laptop:before {
  content: "";
}

.material-icons.laptop_chromebook:before {
  content: "";
}

.material-icons.laptop_mac:before {
  content: "";
}

.material-icons.laptop_windows:before {
  content: "";
}

.material-icons.last_page:before {
  content: "";
}

.material-icons.launch:before {
  content: "";
}

.material-icons.layers:before {
  content: "";
}

.material-icons.layers_clear:before {
  content: "";
}

.material-icons.leaderboard:before {
  content: "";
}

.material-icons.leak_add:before {
  content: "";
}

.material-icons.leak_remove:before {
  content: "";
}

.material-icons.leave_bags_at_home:before {
  content: "";
}

.material-icons.legend_toggle:before {
  content: "";
}

.material-icons.lens:before {
  content: "";
}

.material-icons.lens_blur:before {
  content: "";
}

.material-icons.library_add:before {
  content: "";
}

.material-icons.library_add_check:before {
  content: "";
}

.material-icons.library_books:before {
  content: "";
}

.material-icons.library_music:before {
  content: "";
}

.material-icons.light:before {
  content: "";
}

.material-icons.light_mode:before {
  content: "";
}

.material-icons.lightbulb:before {
  content: "";
}

.material-icons.lightbulb_circle:before {
  content: "";
}

.material-icons.lightbulb_outline:before {
  content: "";
}

.material-icons.line_axis:before {
  content: "";
}

.material-icons.line_style:before {
  content: "";
}

.material-icons.line_weight:before {
  content: "";
}

.material-icons.linear_scale:before {
  content: "";
}

.material-icons.link:before {
  content: "";
}

.material-icons.link_off:before {
  content: "";
}

.material-icons.linked_camera:before {
  content: "";
}

.material-icons.liquor:before {
  content: "";
}

.material-icons.list:before {
  content: "";
}

.material-icons.list_alt:before {
  content: "";
}

.material-icons.live_help:before {
  content: "";
}

.material-icons.live_tv:before {
  content: "";
}

.material-icons.living:before {
  content: "";
}

.material-icons.local_activity:before {
  content: "";
}

.material-icons.local_airport:before {
  content: "";
}

.material-icons.local_atm:before {
  content: "";
}

.material-icons.local_attraction:before {
  content: "";
}

.material-icons.local_bar:before {
  content: "";
}

.material-icons.local_cafe:before {
  content: "";
}

.material-icons.local_car_wash:before {
  content: "";
}

.material-icons.local_convenience_store:before {
  content: "";
}

.material-icons.local_dining:before {
  content: "";
}

.material-icons.local_drink:before {
  content: "";
}

.material-icons.local_fire_department:before {
  content: "";
}

.material-icons.local_florist:before {
  content: "";
}

.material-icons.local_gas_station:before {
  content: "";
}

.material-icons.local_grocery_store:before {
  content: "";
}

.material-icons.local_hospital:before {
  content: "";
}

.material-icons.local_hotel:before {
  content: "";
}

.material-icons.local_laundry_service:before {
  content: "";
}

.material-icons.local_library:before {
  content: "";
}

.material-icons.local_mall:before {
  content: "";
}

.material-icons.local_movies:before {
  content: "";
}

.material-icons.local_offer:before {
  content: "";
}

.material-icons.local_parking:before {
  content: "";
}

.material-icons.local_pharmacy:before {
  content: "";
}

.material-icons.local_phone:before {
  content: "";
}

.material-icons.local_pizza:before {
  content: "";
}

.material-icons.local_play:before {
  content: "";
}

.material-icons.local_police:before {
  content: "";
}

.material-icons.local_post_office:before {
  content: "";
}

.material-icons.local_print_shop:before, .material-icons.local_printshop:before {
  content: "";
}

.material-icons.local_restaurant:before {
  content: "";
}

.material-icons.local_see:before {
  content: "";
}

.material-icons.local_shipping:before {
  content: "";
}

.material-icons.local_taxi:before {
  content: "";
}

.material-icons.location_city:before {
  content: "";
}

.material-icons.location_disabled:before {
  content: "";
}

.material-icons.location_history:before {
  content: "";
}

.material-icons.location_off:before {
  content: "";
}

.material-icons.location_on:before {
  content: "";
}

.material-icons.location_pin:before {
  content: "";
}

.material-icons.location_searching:before {
  content: "";
}

.material-icons.lock:before {
  content: "";
}

.material-icons.lock_clock:before {
  content: "";
}

.material-icons.lock_open:before {
  content: "";
}

.material-icons.lock_outline:before {
  content: "";
}

.material-icons.lock_person:before {
  content: "";
}

.material-icons.lock_reset:before {
  content: "";
}

.material-icons.login:before {
  content: "";
}

.material-icons.logo_dev:before {
  content: "";
}

.material-icons.logout:before {
  content: "";
}

.material-icons.looks:before {
  content: "";
}

.material-icons.looks_3:before {
  content: "";
}

.material-icons.looks_4:before {
  content: "";
}

.material-icons.looks_5:before {
  content: "";
}

.material-icons.looks_6:before {
  content: "";
}

.material-icons.looks_one:before {
  content: "";
}

.material-icons.looks_two:before {
  content: "";
}

.material-icons.loop:before {
  content: "";
}

.material-icons.loupe:before {
  content: "";
}

.material-icons.low_priority:before {
  content: "";
}

.material-icons.loyalty:before {
  content: "";
}

.material-icons.lte_mobiledata:before {
  content: "";
}

.material-icons.lte_plus_mobiledata:before {
  content: "";
}

.material-icons.luggage:before {
  content: "";
}

.material-icons.lunch_dining:before {
  content: "";
}

.material-icons.lyrics:before {
  content: "";
}

.material-icons.mail:before {
  content: "";
}

.material-icons.mail_lock:before {
  content: "";
}

.material-icons.mail_outline:before {
  content: "";
}

.material-icons.male:before {
  content: "";
}

.material-icons.man:before {
  content: "";
}

.material-icons.manage_accounts:before {
  content: "";
}

.material-icons.manage_history:before {
  content: "";
}

.material-icons.manage_search:before {
  content: "";
}

.material-icons.map:before {
  content: "";
}

.material-icons.maps_home_work:before {
  content: "";
}

.material-icons.maps_ugc:before {
  content: "";
}

.material-icons.margin:before {
  content: "";
}

.material-icons.mark_as_unread:before {
  content: "";
}

.material-icons.mark_chat_read:before {
  content: "";
}

.material-icons.mark_chat_unread:before {
  content: "";
}

.material-icons.mark_email_read:before {
  content: "";
}

.material-icons.mark_email_unread:before {
  content: "";
}

.material-icons.mark_unread_chat_alt:before {
  content: "";
}

.material-icons.markunread:before {
  content: "";
}

.material-icons.markunread_mailbox:before {
  content: "";
}

.material-icons.masks:before {
  content: "";
}

.material-icons.maximize:before {
  content: "";
}

.material-icons.media_bluetooth_off:before {
  content: "";
}

.material-icons.media_bluetooth_on:before {
  content: "";
}

.material-icons.mediation:before {
  content: "";
}

.material-icons.medical_information:before {
  content: "";
}

.material-icons.medical_services:before {
  content: "";
}

.material-icons.medication:before {
  content: "";
}

.material-icons.medication_liquid:before {
  content: "";
}

.material-icons.meeting_room:before {
  content: "";
}

.material-icons.memory:before {
  content: "";
}

.material-icons.menu:before {
  content: "";
}

.material-icons.menu_book:before {
  content: "";
}

.material-icons.menu_open:before {
  content: "";
}

.material-icons.merge:before {
  content: "";
}

.material-icons.merge_type:before {
  content: "";
}

.material-icons.message:before {
  content: "";
}

.material-icons.messenger:before {
  content: "";
}

.material-icons.messenger_outline:before {
  content: "";
}

.material-icons.mic:before {
  content: "";
}

.material-icons.mic_external_off:before {
  content: "";
}

.material-icons.mic_external_on:before {
  content: "";
}

.material-icons.mic_none:before {
  content: "";
}

.material-icons.mic_off:before {
  content: "";
}

.material-icons.microwave:before {
  content: "";
}

.material-icons.military_tech:before {
  content: "";
}

.material-icons.minimize:before {
  content: "";
}

.material-icons.minor_crash:before {
  content: "";
}

.material-icons.miscellaneous_services:before {
  content: "";
}

.material-icons.missed_video_call:before {
  content: "";
}

.material-icons.mms:before {
  content: "";
}

.material-icons.mobile_friendly:before {
  content: "";
}

.material-icons.mobile_off:before {
  content: "";
}

.material-icons.mobile_screen_share:before {
  content: "";
}

.material-icons.mobiledata_off:before {
  content: "";
}

.material-icons.mode:before {
  content: "";
}

.material-icons.mode_comment:before {
  content: "";
}

.material-icons.mode_edit:before {
  content: "";
}

.material-icons.mode_edit_outline:before {
  content: "";
}

.material-icons.mode_fan_off:before {
  content: "";
}

.material-icons.mode_night:before {
  content: "";
}

.material-icons.mode_of_travel:before {
  content: "";
}

.material-icons.mode_standby:before {
  content: "";
}

.material-icons.model_training:before {
  content: "";
}

.material-icons.monetization_on:before {
  content: "";
}

.material-icons.money:before {
  content: "";
}

.material-icons.money_off:before {
  content: "";
}

.material-icons.money_off_csred:before {
  content: "";
}

.material-icons.monitor:before {
  content: "";
}

.material-icons.monitor_heart:before {
  content: "";
}

.material-icons.monitor_weight:before {
  content: "";
}

.material-icons.monochrome_photos:before {
  content: "";
}

.material-icons.mood:before {
  content: "";
}

.material-icons.mood_bad:before {
  content: "";
}

.material-icons.moped:before {
  content: "";
}

.material-icons.more:before {
  content: "";
}

.material-icons.more_horiz:before {
  content: "";
}

.material-icons.more_time:before {
  content: "";
}

.material-icons.more_vert:before {
  content: "";
}

.material-icons.mosque:before {
  content: "";
}

.material-icons.motion_photos_auto:before {
  content: "";
}

.material-icons.motion_photos_off:before {
  content: "";
}

.material-icons.motion_photos_on:before {
  content: "";
}

.material-icons.motion_photos_pause:before {
  content: "";
}

.material-icons.motion_photos_paused:before {
  content: "";
}

.material-icons.motorcycle:before {
  content: "";
}

.material-icons.mouse:before {
  content: "";
}

.material-icons.move_down:before {
  content: "";
}

.material-icons.move_to_inbox:before {
  content: "";
}

.material-icons.move_up:before {
  content: "";
}

.material-icons.movie:before {
  content: "";
}

.material-icons.movie_creation:before {
  content: "";
}

.material-icons.movie_filter:before {
  content: "";
}

.material-icons.moving:before {
  content: "";
}

.material-icons.mp:before {
  content: "";
}

.material-icons.multiline_chart:before {
  content: "";
}

.material-icons.multiple_stop:before {
  content: "";
}

.material-icons.multitrack_audio:before {
  content: "";
}

.material-icons.museum:before {
  content: "";
}

.material-icons.music_note:before {
  content: "";
}

.material-icons.music_off:before {
  content: "";
}

.material-icons.music_video:before {
  content: "";
}

.material-icons.my_library_add:before {
  content: "";
}

.material-icons.my_library_books:before {
  content: "";
}

.material-icons.my_library_music:before {
  content: "";
}

.material-icons.my_location:before {
  content: "";
}

.material-icons.nat:before {
  content: "";
}

.material-icons.nature:before {
  content: "";
}

.material-icons.nature_people:before {
  content: "";
}

.material-icons.navigate_before:before {
  content: "";
}

.material-icons.navigate_next:before {
  content: "";
}

.material-icons.navigation:before {
  content: "";
}

.material-icons.near_me:before {
  content: "";
}

.material-icons.near_me_disabled:before {
  content: "";
}

.material-icons.nearby_error:before {
  content: "";
}

.material-icons.nearby_off:before {
  content: "";
}

.material-icons.nest_cam_wired_stand:before {
  content: "";
}

.material-icons.network_cell:before {
  content: "";
}

.material-icons.network_check:before {
  content: "";
}

.material-icons.network_locked:before {
  content: "";
}

.material-icons.network_ping:before {
  content: "";
}

.material-icons.network_wifi:before {
  content: "";
}

.material-icons.network_wifi_1_bar:before {
  content: "";
}

.material-icons.network_wifi_2_bar:before {
  content: "";
}

.material-icons.network_wifi_3_bar:before {
  content: "";
}

.material-icons.new_label:before {
  content: "";
}

.material-icons.new_releases:before {
  content: "";
}

.material-icons.newspaper:before {
  content: "";
}

.material-icons.next_plan:before {
  content: "";
}

.material-icons.next_week:before {
  content: "";
}

.material-icons.nfc:before {
  content: "";
}

.material-icons.night_shelter:before {
  content: "";
}

.material-icons.nightlife:before {
  content: "";
}

.material-icons.nightlight:before {
  content: "";
}

.material-icons.nightlight_round:before {
  content: "";
}

.material-icons.nights_stay:before {
  content: "";
}

.material-icons.no_accounts:before {
  content: "";
}

.material-icons.no_adult_content:before {
  content: "";
}

.material-icons.no_backpack:before {
  content: "";
}

.material-icons.no_cell:before {
  content: "";
}

.material-icons.no_crash:before {
  content: "";
}

.material-icons.no_drinks:before {
  content: "";
}

.material-icons.no_encryption:before {
  content: "";
}

.material-icons.no_encryption_gmailerrorred:before {
  content: "";
}

.material-icons.no_flash:before {
  content: "";
}

.material-icons.no_food:before {
  content: "";
}

.material-icons.no_luggage:before {
  content: "";
}

.material-icons.no_meals:before {
  content: "";
}

.material-icons.no_meals_ouline:before {
  content: "";
}

.material-icons.no_meeting_room:before {
  content: "";
}

.material-icons.no_photography:before {
  content: "";
}

.material-icons.no_sim:before {
  content: "";
}

.material-icons.no_stroller:before {
  content: "";
}

.material-icons.no_transfer:before {
  content: "";
}

.material-icons.noise_aware:before {
  content: "";
}

.material-icons.noise_control_off:before {
  content: "";
}

.material-icons.nordic_walking:before {
  content: "";
}

.material-icons.north:before {
  content: "";
}

.material-icons.north_east:before {
  content: "";
}

.material-icons.north_west:before {
  content: "";
}

.material-icons.not_accessible:before {
  content: "";
}

.material-icons.not_interested:before {
  content: "";
}

.material-icons.not_listed_location:before {
  content: "";
}

.material-icons.not_started:before {
  content: "";
}

.material-icons.note:before {
  content: "";
}

.material-icons.note_add:before {
  content: "";
}

.material-icons.note_alt:before {
  content: "";
}

.material-icons.notes:before {
  content: "";
}

.material-icons.notification_add:before {
  content: "";
}

.material-icons.notification_important:before {
  content: "";
}

.material-icons.notifications:before {
  content: "";
}

.material-icons.notifications_active:before {
  content: "";
}

.material-icons.notifications_none:before {
  content: "";
}

.material-icons.notifications_off:before {
  content: "";
}

.material-icons.notifications_on:before {
  content: "";
}

.material-icons.notifications_paused:before {
  content: "";
}

.material-icons.now_wallpaper:before {
  content: "";
}

.material-icons.now_widgets:before {
  content: "";
}

.material-icons.numbers:before {
  content: "";
}

.material-icons.offline_bolt:before {
  content: "";
}

.material-icons.offline_pin:before {
  content: "";
}

.material-icons.offline_share:before {
  content: "";
}

.material-icons.oil_barrel:before {
  content: "";
}

.material-icons.on_device_training:before {
  content: "";
}

.material-icons.ondemand_video:before {
  content: "";
}

.material-icons.online_prediction:before {
  content: "";
}

.material-icons.opacity:before {
  content: "";
}

.material-icons.open_in_browser:before {
  content: "";
}

.material-icons.open_in_full:before {
  content: "";
}

.material-icons.open_in_new:before {
  content: "";
}

.material-icons.open_in_new_off:before {
  content: "";
}

.material-icons.open_with:before {
  content: "";
}

.material-icons.other_houses:before {
  content: "";
}

.material-icons.outbond:before {
  content: "";
}

.material-icons.outbound:before {
  content: "";
}

.material-icons.outbox:before {
  content: "";
}

.material-icons.outdoor_grill:before {
  content: "";
}

.material-icons.outgoing_mail:before {
  content: "";
}

.material-icons.outlet:before {
  content: "";
}

.material-icons.outlined_flag:before {
  content: "";
}

.material-icons.output:before {
  content: "";
}

.material-icons.padding:before {
  content: "";
}

.material-icons.pages:before {
  content: "";
}

.material-icons.pageview:before {
  content: "";
}

.material-icons.paid:before {
  content: "";
}

.material-icons.palette:before {
  content: "";
}

.material-icons.pan_tool:before {
  content: "";
}

.material-icons.pan_tool_alt:before {
  content: "";
}

.material-icons.panorama:before {
  content: "";
}

.material-icons.panorama_fish_eye:before, .material-icons.panorama_fisheye:before {
  content: "";
}

.material-icons.panorama_horizontal:before {
  content: "";
}

.material-icons.panorama_horizontal_select:before {
  content: "";
}

.material-icons.panorama_photosphere:before {
  content: "";
}

.material-icons.panorama_photosphere_select:before {
  content: "";
}

.material-icons.panorama_vertical:before {
  content: "";
}

.material-icons.panorama_vertical_select:before {
  content: "";
}

.material-icons.panorama_wide_angle:before {
  content: "";
}

.material-icons.panorama_wide_angle_select:before {
  content: "";
}

.material-icons.paragliding:before {
  content: "";
}

.material-icons.park:before {
  content: "";
}

.material-icons.party_mode:before {
  content: "";
}

.material-icons.password:before {
  content: "";
}

.material-icons.pattern:before {
  content: "";
}

.material-icons.pause:before {
  content: "";
}

.material-icons.pause_circle:before {
  content: "";
}

.material-icons.pause_circle_filled:before {
  content: "";
}

.material-icons.pause_circle_outline:before {
  content: "";
}

.material-icons.pause_presentation:before {
  content: "";
}

.material-icons.payment:before {
  content: "";
}

.material-icons.payments:before {
  content: "";
}

.material-icons.paypal:before {
  content: "";
}

.material-icons.pedal_bike:before {
  content: "";
}

.material-icons.pending:before {
  content: "";
}

.material-icons.pending_actions:before {
  content: "";
}

.material-icons.pentagon:before {
  content: "";
}

.material-icons.people:before {
  content: "";
}

.material-icons.people_alt:before {
  content: "";
}

.material-icons.people_outline:before {
  content: "";
}

.material-icons.percent:before {
  content: "";
}

.material-icons.perm_camera_mic:before {
  content: "";
}

.material-icons.perm_contact_cal:before, .material-icons.perm_contact_calendar:before {
  content: "";
}

.material-icons.perm_data_setting:before {
  content: "";
}

.material-icons.perm_device_info:before, .material-icons.perm_device_information:before {
  content: "";
}

.material-icons.perm_identity:before {
  content: "";
}

.material-icons.perm_media:before {
  content: "";
}

.material-icons.perm_phone_msg:before {
  content: "";
}

.material-icons.perm_scan_wifi:before {
  content: "";
}

.material-icons.person:before {
  content: "";
}

.material-icons.person_add:before {
  content: "";
}

.material-icons.person_add_alt:before {
  content: "";
}

.material-icons.person_add_alt_1:before {
  content: "";
}

.material-icons.person_add_disabled:before {
  content: "";
}

.material-icons.person_off:before {
  content: "";
}

.material-icons.person_outline:before {
  content: "";
}

.material-icons.person_pin:before {
  content: "";
}

.material-icons.person_pin_circle:before {
  content: "";
}

.material-icons.person_remove:before {
  content: "";
}

.material-icons.person_remove_alt_1:before {
  content: "";
}

.material-icons.person_search:before {
  content: "";
}

.material-icons.personal_injury:before {
  content: "";
}

.material-icons.personal_video:before {
  content: "";
}

.material-icons.pest_control:before {
  content: "";
}

.material-icons.pest_control_rodent:before {
  content: "";
}

.material-icons.pets:before {
  content: "";
}

.material-icons.phishing:before {
  content: "";
}

.material-icons.phone:before {
  content: "";
}

.material-icons.phone_android:before {
  content: "";
}

.material-icons.phone_bluetooth_speaker:before {
  content: "";
}

.material-icons.phone_callback:before {
  content: "";
}

.material-icons.phone_disabled:before {
  content: "";
}

.material-icons.phone_enabled:before {
  content: "";
}

.material-icons.phone_forwarded:before {
  content: "";
}

.material-icons.phone_in_talk:before {
  content: "";
}

.material-icons.phone_iphone:before {
  content: "";
}

.material-icons.phone_locked:before {
  content: "";
}

.material-icons.phone_missed:before {
  content: "";
}

.material-icons.phone_paused:before {
  content: "";
}

.material-icons.phonelink:before {
  content: "";
}

.material-icons.phonelink_erase:before {
  content: "";
}

.material-icons.phonelink_lock:before {
  content: "";
}

.material-icons.phonelink_off:before {
  content: "";
}

.material-icons.phonelink_ring:before {
  content: "";
}

.material-icons.phonelink_setup:before {
  content: "";
}

.material-icons.photo:before {
  content: "";
}

.material-icons.photo_album:before {
  content: "";
}

.material-icons.photo_camera:before {
  content: "";
}

.material-icons.photo_camera_back:before {
  content: "";
}

.material-icons.photo_camera_front:before {
  content: "";
}

.material-icons.photo_filter:before {
  content: "";
}

.material-icons.photo_library:before {
  content: "";
}

.material-icons.photo_size_select_actual:before {
  content: "";
}

.material-icons.photo_size_select_large:before {
  content: "";
}

.material-icons.photo_size_select_small:before {
  content: "";
}

.material-icons.php:before {
  content: "";
}

.material-icons.piano:before {
  content: "";
}

.material-icons.piano_off:before {
  content: "";
}

.material-icons.picture_as_pdf:before {
  content: "";
}

.material-icons.picture_in_picture:before {
  content: "";
}

.material-icons.picture_in_picture_alt:before {
  content: "";
}

.material-icons.pie_chart:before {
  content: "";
}

.material-icons.pie_chart_outline:before {
  content: "";
}

.material-icons.pie_chart_outlined:before {
  content: "";
}

.material-icons.pin:before {
  content: "";
}

.material-icons.pin_drop:before {
  content: "";
}

.material-icons.pin_end:before {
  content: "";
}

.material-icons.pin_invoke:before {
  content: "";
}

.material-icons.pinch:before {
  content: "";
}

.material-icons.pivot_table_chart:before {
  content: "";
}

.material-icons.pix:before {
  content: "";
}

.material-icons.place:before {
  content: "";
}

.material-icons.plagiarism:before {
  content: "";
}

.material-icons.play_arrow:before {
  content: "";
}

.material-icons.play_circle:before {
  content: "";
}

.material-icons.play_circle_fill:before, .material-icons.play_circle_filled:before {
  content: "";
}

.material-icons.play_circle_outline:before {
  content: "";
}

.material-icons.play_disabled:before {
  content: "";
}

.material-icons.play_for_work:before {
  content: "";
}

.material-icons.play_lesson:before {
  content: "";
}

.material-icons.playlist_add:before {
  content: "";
}

.material-icons.playlist_add_check:before {
  content: "";
}

.material-icons.playlist_add_check_circle:before {
  content: "";
}

.material-icons.playlist_add_circle:before {
  content: "";
}

.material-icons.playlist_play:before {
  content: "";
}

.material-icons.playlist_remove:before {
  content: "";
}

.material-icons.plumbing:before {
  content: "";
}

.material-icons.plus_one:before {
  content: "";
}

.material-icons.podcasts:before {
  content: "";
}

.material-icons.point_of_sale:before {
  content: "";
}

.material-icons.policy:before {
  content: "";
}

.material-icons.poll:before {
  content: "";
}

.material-icons.polyline:before {
  content: "";
}

.material-icons.polymer:before {
  content: "";
}

.material-icons.pool:before {
  content: "";
}

.material-icons.portable_wifi_off:before {
  content: "";
}

.material-icons.portrait:before {
  content: "";
}

.material-icons.post_add:before {
  content: "";
}

.material-icons.power:before {
  content: "";
}

.material-icons.power_input:before {
  content: "";
}

.material-icons.power_off:before {
  content: "";
}

.material-icons.power_settings_new:before {
  content: "";
}

.material-icons.precision_manufacturing:before {
  content: "";
}

.material-icons.pregnant_woman:before {
  content: "";
}

.material-icons.present_to_all:before {
  content: "";
}

.material-icons.preview:before {
  content: "";
}

.material-icons.price_change:before {
  content: "";
}

.material-icons.price_check:before {
  content: "";
}

.material-icons.print:before {
  content: "";
}

.material-icons.print_disabled:before {
  content: "";
}

.material-icons.priority_high:before {
  content: "";
}

.material-icons.privacy_tip:before {
  content: "";
}

.material-icons.private_connectivity:before {
  content: "";
}

.material-icons.production_quantity_limits:before {
  content: "";
}

.material-icons.propane:before {
  content: "";
}

.material-icons.propane_tank:before {
  content: "";
}

.material-icons.psychology:before {
  content: "";
}

.material-icons.psychology_alt:before {
  content: "";
}

.material-icons.public:before {
  content: "";
}

.material-icons.public_off:before {
  content: "";
}

.material-icons.publish:before {
  content: "";
}

.material-icons.published_with_changes:before {
  content: "";
}

.material-icons.punch_clock:before {
  content: "";
}

.material-icons.push_pin:before {
  content: "";
}

.material-icons.qr_code:before {
  content: "";
}

.material-icons.qr_code_2:before {
  content: "";
}

.material-icons.qr_code_scanner:before {
  content: "";
}

.material-icons.query_builder:before {
  content: "";
}

.material-icons.query_stats:before {
  content: "";
}

.material-icons.question_answer:before {
  content: "";
}

.material-icons.question_mark:before {
  content: "";
}

.material-icons.queue:before {
  content: "";
}

.material-icons.queue_music:before {
  content: "";
}

.material-icons.queue_play_next:before {
  content: "";
}

.material-icons.quick_contacts_dialer:before {
  content: "";
}

.material-icons.quick_contacts_mail:before {
  content: "";
}

.material-icons.quickreply:before {
  content: "";
}

.material-icons.quiz:before {
  content: "";
}

.material-icons.quora:before {
  content: "";
}

.material-icons.r_mobiledata:before {
  content: "";
}

.material-icons.radar:before {
  content: "";
}

.material-icons.radio:before {
  content: "";
}

.material-icons.radio_button_checked:before {
  content: "";
}

.material-icons.radio_button_off:before {
  content: "";
}

.material-icons.radio_button_on:before {
  content: "";
}

.material-icons.radio_button_unchecked:before {
  content: "";
}

.material-icons.railway_alert:before {
  content: "";
}

.material-icons.ramen_dining:before {
  content: "";
}

.material-icons.ramp_left:before {
  content: "";
}

.material-icons.ramp_right:before {
  content: "";
}

.material-icons.rate_review:before {
  content: "";
}

.material-icons.raw_off:before {
  content: "";
}

.material-icons.raw_on:before {
  content: "";
}

.material-icons.read_more:before {
  content: "";
}

.material-icons.real_estate_agent:before {
  content: "";
}

.material-icons.receipt:before {
  content: "";
}

.material-icons.receipt_long:before {
  content: "";
}

.material-icons.recent_actors:before {
  content: "";
}

.material-icons.recommend:before {
  content: "";
}

.material-icons.record_voice_over:before {
  content: "";
}

.material-icons.rectangle:before {
  content: "";
}

.material-icons.recycling:before {
  content: "";
}

.material-icons.reddit:before {
  content: "";
}

.material-icons.redeem:before {
  content: "";
}

.material-icons.redo:before {
  content: "";
}

.material-icons.reduce_capacity:before {
  content: "";
}

.material-icons.refresh:before {
  content: "";
}

.material-icons.remember_me:before {
  content: "";
}

.material-icons.remove:before {
  content: "";
}

.material-icons.remove_circle:before {
  content: "";
}

.material-icons.remove_circle_outline:before {
  content: "";
}

.material-icons.remove_done:before {
  content: "";
}

.material-icons.remove_from_queue:before {
  content: "";
}

.material-icons.remove_moderator:before {
  content: "";
}

.material-icons.remove_red_eye:before {
  content: "";
}

.material-icons.remove_road:before {
  content: "";
}

.material-icons.remove_shopping_cart:before {
  content: "";
}

.material-icons.reorder:before {
  content: "";
}

.material-icons.repartition:before {
  content: "";
}

.material-icons.repeat:before {
  content: "";
}

.material-icons.repeat_on:before {
  content: "";
}

.material-icons.repeat_one:before {
  content: "";
}

.material-icons.repeat_one_on:before {
  content: "";
}

.material-icons.replay:before {
  content: "";
}

.material-icons.replay_10:before {
  content: "";
}

.material-icons.replay_30:before {
  content: "";
}

.material-icons.replay_5:before {
  content: "";
}

.material-icons.replay_circle_filled:before {
  content: "";
}

.material-icons.reply:before {
  content: "";
}

.material-icons.reply_all:before {
  content: "";
}

.material-icons.report:before {
  content: "";
}

.material-icons.report_gmailerrorred:before {
  content: "";
}

.material-icons.report_off:before {
  content: "";
}

.material-icons.report_problem:before {
  content: "";
}

.material-icons.request_page:before {
  content: "";
}

.material-icons.request_quote:before {
  content: "";
}

.material-icons.reset_tv:before {
  content: "";
}

.material-icons.restart_alt:before {
  content: "";
}

.material-icons.restaurant:before {
  content: "";
}

.material-icons.restaurant_menu:before {
  content: "";
}

.material-icons.restore:before {
  content: "";
}

.material-icons.restore_from_trash:before {
  content: "";
}

.material-icons.restore_page:before {
  content: "";
}

.material-icons.reviews:before {
  content: "";
}

.material-icons.rice_bowl:before {
  content: "";
}

.material-icons.ring_volume:before {
  content: "";
}

.material-icons.rocket:before {
  content: "";
}

.material-icons.rocket_launch:before {
  content: "";
}

.material-icons.roller_shades:before {
  content: "";
}

.material-icons.roller_shades_closed:before {
  content: "";
}

.material-icons.roller_skating:before {
  content: "";
}

.material-icons.roofing:before {
  content: "";
}

.material-icons.room:before {
  content: "";
}

.material-icons.room_preferences:before {
  content: "";
}

.material-icons.room_service:before {
  content: "";
}

.material-icons.rotate_90_degrees_ccw:before {
  content: "";
}

.material-icons.rotate_90_degrees_cw:before {
  content: "";
}

.material-icons.rotate_left:before {
  content: "";
}

.material-icons.rotate_right:before {
  content: "";
}

.material-icons.roundabout_left:before {
  content: "";
}

.material-icons.roundabout_right:before {
  content: "";
}

.material-icons.rounded_corner:before {
  content: "";
}

.material-icons.route:before {
  content: "";
}

.material-icons.router:before {
  content: "";
}

.material-icons.rowing:before {
  content: "";
}

.material-icons.rss_feed:before {
  content: "";
}

.material-icons.rsvp:before {
  content: "";
}

.material-icons.rtt:before {
  content: "";
}

.material-icons.rule:before {
  content: "";
}

.material-icons.rule_folder:before {
  content: "";
}

.material-icons.run_circle:before {
  content: "";
}

.material-icons.running_with_errors:before {
  content: "";
}

.material-icons.rv_hookup:before {
  content: "";
}

.material-icons.safety_check:before {
  content: "";
}

.material-icons.safety_divider:before {
  content: "";
}

.material-icons.sailing:before {
  content: "";
}

.material-icons.sanitizer:before {
  content: "";
}

.material-icons.satellite:before {
  content: "";
}

.material-icons.satellite_alt:before {
  content: "";
}

.material-icons.save:before {
  content: "";
}

.material-icons.save_alt:before {
  content: "";
}

.material-icons.save_as:before {
  content: "";
}

.material-icons.saved_search:before {
  content: "";
}

.material-icons.savings:before {
  content: "";
}

.material-icons.scale:before {
  content: "";
}

.material-icons.scanner:before {
  content: "";
}

.material-icons.scatter_plot:before {
  content: "";
}

.material-icons.schedule:before {
  content: "";
}

.material-icons.schedule_send:before {
  content: "";
}

.material-icons.schema:before {
  content: "";
}

.material-icons.school:before {
  content: "";
}

.material-icons.science:before {
  content: "";
}

.material-icons.score:before {
  content: "";
}

.material-icons.scoreboard:before {
  content: "";
}

.material-icons.screen_lock_landscape:before {
  content: "";
}

.material-icons.screen_lock_portrait:before {
  content: "";
}

.material-icons.screen_lock_rotation:before {
  content: "";
}

.material-icons.screen_rotation:before {
  content: "";
}

.material-icons.screen_rotation_alt:before {
  content: "";
}

.material-icons.screen_search_desktop:before {
  content: "";
}

.material-icons.screen_share:before {
  content: "";
}

.material-icons.screenshot:before {
  content: "";
}

.material-icons.screenshot_monitor:before {
  content: "";
}

.material-icons.scuba_diving:before {
  content: "";
}

.material-icons.sd:before {
  content: "";
}

.material-icons.sd_card:before {
  content: "";
}

.material-icons.sd_card_alert:before {
  content: "";
}

.material-icons.sd_storage:before {
  content: "";
}

.material-icons.search:before {
  content: "";
}

.material-icons.search_off:before {
  content: "";
}

.material-icons.security:before {
  content: "";
}

.material-icons.security_update:before {
  content: "";
}

.material-icons.security_update_good:before {
  content: "";
}

.material-icons.security_update_warning:before {
  content: "";
}

.material-icons.segment:before {
  content: "";
}

.material-icons.select_all:before {
  content: "";
}

.material-icons.self_improvement:before {
  content: "";
}

.material-icons.sell:before {
  content: "";
}

.material-icons.send:before {
  content: "";
}

.material-icons.send_and_archive:before {
  content: "";
}

.material-icons.send_time_extension:before {
  content: "";
}

.material-icons.send_to_mobile:before {
  content: "";
}

.material-icons.sensor_door:before {
  content: "";
}

.material-icons.sensor_occupied:before {
  content: "";
}

.material-icons.sensor_window:before {
  content: "";
}

.material-icons.sensors:before {
  content: "";
}

.material-icons.sensors_off:before {
  content: "";
}

.material-icons.sentiment_dissatisfied:before {
  content: "";
}

.material-icons.sentiment_neutral:before {
  content: "";
}

.material-icons.sentiment_satisfied:before {
  content: "";
}

.material-icons.sentiment_satisfied_alt:before {
  content: "";
}

.material-icons.sentiment_very_dissatisfied:before {
  content: "";
}

.material-icons.sentiment_very_satisfied:before {
  content: "";
}

.material-icons.set_meal:before {
  content: "";
}

.material-icons.settings:before {
  content: "";
}

.material-icons.settings_accessibility:before {
  content: "";
}

.material-icons.settings_applications:before {
  content: "";
}

.material-icons.settings_backup_restore:before {
  content: "";
}

.material-icons.settings_bluetooth:before {
  content: "";
}

.material-icons.settings_brightness:before {
  content: "";
}

.material-icons.settings_cell:before {
  content: "";
}

.material-icons.settings_display:before {
  content: "";
}

.material-icons.settings_ethernet:before {
  content: "";
}

.material-icons.settings_input_antenna:before {
  content: "";
}

.material-icons.settings_input_component:before {
  content: "";
}

.material-icons.settings_input_composite:before {
  content: "";
}

.material-icons.settings_input_hdmi:before {
  content: "";
}

.material-icons.settings_input_svideo:before {
  content: "";
}

.material-icons.settings_overscan:before {
  content: "";
}

.material-icons.settings_phone:before {
  content: "";
}

.material-icons.settings_power:before {
  content: "";
}

.material-icons.settings_remote:before {
  content: "";
}

.material-icons.settings_suggest:before {
  content: "";
}

.material-icons.settings_system_daydream:before {
  content: "";
}

.material-icons.settings_voice:before {
  content: "";
}

.material-icons.severe_cold:before {
  content: "";
}

.material-icons.share:before {
  content: "";
}

.material-icons.share_arrival_time:before {
  content: "";
}

.material-icons.share_location:before {
  content: "";
}

.material-icons.shield:before {
  content: "";
}

.material-icons.shield_moon:before {
  content: "";
}

.material-icons.shop:before {
  content: "";
}

.material-icons.shop_2:before {
  content: "";
}

.material-icons.shop_two:before {
  content: "";
}

.material-icons.shopify:before {
  content: "";
}

.material-icons.shopping_bag:before {
  content: "";
}

.material-icons.shopping_basket:before {
  content: "";
}

.material-icons.shopping_cart:before {
  content: "";
}

.material-icons.shopping_cart_checkout:before {
  content: "";
}

.material-icons.short_text:before {
  content: "";
}

.material-icons.shortcut:before {
  content: "";
}

.material-icons.show_chart:before {
  content: "";
}

.material-icons.shower:before {
  content: "";
}

.material-icons.shuffle:before {
  content: "";
}

.material-icons.shuffle_on:before {
  content: "";
}

.material-icons.shutter_speed:before {
  content: "";
}

.material-icons.sick:before {
  content: "";
}

.material-icons.sign_language:before {
  content: "";
}

.material-icons.signal_cellular_0_bar:before {
  content: "";
}

.material-icons.signal_cellular_4_bar:before {
  content: "";
}

.material-icons.signal_cellular_alt:before {
  content: "";
}

.material-icons.signal_cellular_alt_1_bar:before {
  content: "";
}

.material-icons.signal_cellular_alt_2_bar:before {
  content: "";
}

.material-icons.signal_cellular_connected_no_internet_0_bar:before {
  content: "";
}

.material-icons.signal_cellular_connected_no_internet_4_bar:before {
  content: "";
}

.material-icons.signal_cellular_no_sim:before {
  content: "";
}

.material-icons.signal_cellular_nodata:before {
  content: "";
}

.material-icons.signal_cellular_null:before {
  content: "";
}

.material-icons.signal_cellular_off:before {
  content: "";
}

.material-icons.signal_wifi_0_bar:before {
  content: "";
}

.material-icons.signal_wifi_4_bar:before {
  content: "";
}

.material-icons.signal_wifi_4_bar_lock:before {
  content: "";
}

.material-icons.signal_wifi_bad:before {
  content: "";
}

.material-icons.signal_wifi_connected_no_internet_4:before {
  content: "";
}

.material-icons.signal_wifi_off:before {
  content: "";
}

.material-icons.signal_wifi_statusbar_4_bar:before {
  content: "";
}

.material-icons.signal_wifi_statusbar_connected_no_internet_4:before {
  content: "";
}

.material-icons.signal_wifi_statusbar_null:before {
  content: "";
}

.material-icons.signpost:before {
  content: "";
}

.material-icons.sim_card:before {
  content: "";
}

.material-icons.sim_card_alert:before {
  content: "";
}

.material-icons.sim_card_download:before {
  content: "";
}

.material-icons.single_bed:before {
  content: "";
}

.material-icons.sip:before {
  content: "";
}

.material-icons.skateboarding:before {
  content: "";
}

.material-icons.skip_next:before {
  content: "";
}

.material-icons.skip_previous:before {
  content: "";
}

.material-icons.sledding:before {
  content: "";
}

.material-icons.slideshow:before {
  content: "";
}

.material-icons.slow_motion_video:before {
  content: "";
}

.material-icons.smart_button:before {
  content: "";
}

.material-icons.smart_display:before {
  content: "";
}

.material-icons.smart_screen:before {
  content: "";
}

.material-icons.smart_toy:before {
  content: "";
}

.material-icons.smartphone:before {
  content: "";
}

.material-icons.smoke_free:before {
  content: "";
}

.material-icons.smoking_rooms:before {
  content: "";
}

.material-icons.sms:before {
  content: "";
}

.material-icons.sms_failed:before {
  content: "";
}

.material-icons.snapchat:before {
  content: "";
}

.material-icons.snippet_folder:before {
  content: "";
}

.material-icons.snooze:before {
  content: "";
}

.material-icons.snowboarding:before {
  content: "";
}

.material-icons.snowing:before {
  content: "";
}

.material-icons.snowmobile:before {
  content: "";
}

.material-icons.snowshoeing:before {
  content: "";
}

.material-icons.soap:before {
  content: "";
}

.material-icons.social_distance:before {
  content: "";
}

.material-icons.solar_power:before {
  content: "";
}

.material-icons.sort:before {
  content: "";
}

.material-icons.sort_by_alpha:before {
  content: "";
}

.material-icons.sos:before {
  content: "";
}

.material-icons.soup_kitchen:before {
  content: "";
}

.material-icons.source:before {
  content: "";
}

.material-icons.south:before {
  content: "";
}

.material-icons.south_america:before {
  content: "";
}

.material-icons.south_east:before {
  content: "";
}

.material-icons.south_west:before {
  content: "";
}

.material-icons.spa:before {
  content: "";
}

.material-icons.space_bar:before {
  content: "";
}

.material-icons.space_dashboard:before {
  content: "";
}

.material-icons.spatial_audio:before {
  content: "";
}

.material-icons.spatial_audio_off:before {
  content: "";
}

.material-icons.spatial_tracking:before {
  content: "";
}

.material-icons.speaker:before {
  content: "";
}

.material-icons.speaker_group:before {
  content: "";
}

.material-icons.speaker_notes:before {
  content: "";
}

.material-icons.speaker_notes_off:before {
  content: "";
}

.material-icons.speaker_phone:before {
  content: "";
}

.material-icons.speed:before {
  content: "";
}

.material-icons.spellcheck:before {
  content: "";
}

.material-icons.splitscreen:before {
  content: "";
}

.material-icons.spoke:before {
  content: "";
}

.material-icons.sports:before {
  content: "";
}

.material-icons.sports_bar:before {
  content: "";
}

.material-icons.sports_baseball:before {
  content: "";
}

.material-icons.sports_basketball:before {
  content: "";
}

.material-icons.sports_cricket:before {
  content: "";
}

.material-icons.sports_esports:before {
  content: "";
}

.material-icons.sports_football:before {
  content: "";
}

.material-icons.sports_golf:before {
  content: "";
}

.material-icons.sports_gymnastics:before {
  content: "";
}

.material-icons.sports_handball:before {
  content: "";
}

.material-icons.sports_hockey:before {
  content: "";
}

.material-icons.sports_kabaddi:before {
  content: "";
}

.material-icons.sports_martial_arts:before {
  content: "";
}

.material-icons.sports_mma:before {
  content: "";
}

.material-icons.sports_motorsports:before {
  content: "";
}

.material-icons.sports_rugby:before {
  content: "";
}

.material-icons.sports_score:before {
  content: "";
}

.material-icons.sports_soccer:before {
  content: "";
}

.material-icons.sports_tennis:before {
  content: "";
}

.material-icons.sports_volleyball:before {
  content: "";
}

.material-icons.square:before {
  content: "";
}

.material-icons.square_foot:before {
  content: "";
}

.material-icons.ssid_chart:before {
  content: "";
}

.material-icons.stacked_bar_chart:before {
  content: "";
}

.material-icons.stacked_line_chart:before {
  content: "";
}

.material-icons.stadium:before {
  content: "";
}

.material-icons.stairs:before {
  content: "";
}

.material-icons.star:before {
  content: "";
}

.material-icons.star_border:before {
  content: "";
}

.material-icons.star_border_purple500:before {
  content: "";
}

.material-icons.star_half:before {
  content: "";
}

.material-icons.star_outline:before {
  content: "";
}

.material-icons.star_purple500:before {
  content: "";
}

.material-icons.star_rate:before {
  content: "";
}

.material-icons.stars:before {
  content: "";
}

.material-icons.start:before {
  content: "";
}

.material-icons.stay_current_landscape:before {
  content: "";
}

.material-icons.stay_current_portrait:before {
  content: "";
}

.material-icons.stay_primary_landscape:before {
  content: "";
}

.material-icons.stay_primary_portrait:before {
  content: "";
}

.material-icons.sticky_note_2:before {
  content: "";
}

.material-icons.stop:before {
  content: "";
}

.material-icons.stop_circle:before {
  content: "";
}

.material-icons.stop_screen_share:before {
  content: "";
}

.material-icons.storage:before {
  content: "";
}

.material-icons.store:before {
  content: "";
}

.material-icons.store_mall_directory:before {
  content: "";
}

.material-icons.storefront:before {
  content: "";
}

.material-icons.storm:before {
  content: "";
}

.material-icons.straight:before {
  content: "";
}

.material-icons.straighten:before {
  content: "";
}

.material-icons.stream:before {
  content: "";
}

.material-icons.streetview:before {
  content: "";
}

.material-icons.strikethrough_s:before {
  content: "";
}

.material-icons.stroller:before {
  content: "";
}

.material-icons.style:before {
  content: "";
}

.material-icons.subdirectory_arrow_left:before {
  content: "";
}

.material-icons.subdirectory_arrow_right:before {
  content: "";
}

.material-icons.subject:before {
  content: "";
}

.material-icons.subscript:before {
  content: "";
}

.material-icons.subscriptions:before {
  content: "";
}

.material-icons.subtitles:before {
  content: "";
}

.material-icons.subtitles_off:before {
  content: "";
}

.material-icons.subway:before {
  content: "";
}

.material-icons.summarize:before {
  content: "";
}

.material-icons.sunny:before {
  content: "";
}

.material-icons.sunny_snowing:before {
  content: "";
}

.material-icons.superscript:before {
  content: "";
}

.material-icons.supervised_user_circle:before {
  content: "";
}

.material-icons.supervisor_account:before {
  content: "";
}

.material-icons.support:before {
  content: "";
}

.material-icons.support_agent:before {
  content: "";
}

.material-icons.surfing:before {
  content: "";
}

.material-icons.surround_sound:before {
  content: "";
}

.material-icons.swap_calls:before {
  content: "";
}

.material-icons.swap_horiz:before {
  content: "";
}

.material-icons.swap_horizontal_circle:before {
  content: "";
}

.material-icons.swap_vert:before {
  content: "";
}

.material-icons.swap_vert_circle:before, .material-icons.swap_vertical_circle:before {
  content: "";
}

.material-icons.swipe:before {
  content: "";
}

.material-icons.swipe_down:before {
  content: "";
}

.material-icons.swipe_down_alt:before {
  content: "";
}

.material-icons.swipe_left:before {
  content: "";
}

.material-icons.swipe_left_alt:before {
  content: "";
}

.material-icons.swipe_right:before {
  content: "";
}

.material-icons.swipe_right_alt:before {
  content: "";
}

.material-icons.swipe_up:before {
  content: "";
}

.material-icons.swipe_up_alt:before {
  content: "";
}

.material-icons.swipe_vertical:before {
  content: "";
}

.material-icons.switch_access_shortcut:before {
  content: "";
}

.material-icons.switch_access_shortcut_add:before {
  content: "";
}

.material-icons.switch_account:before {
  content: "";
}

.material-icons.switch_camera:before {
  content: "";
}

.material-icons.switch_left:before {
  content: "";
}

.material-icons.switch_right:before {
  content: "";
}

.material-icons.switch_video:before {
  content: "";
}

.material-icons.synagogue:before {
  content: "";
}

.material-icons.sync:before {
  content: "";
}

.material-icons.sync_alt:before {
  content: "";
}

.material-icons.sync_disabled:before {
  content: "";
}

.material-icons.sync_lock:before {
  content: "";
}

.material-icons.sync_problem:before {
  content: "";
}

.material-icons.system_security_update:before {
  content: "";
}

.material-icons.system_security_update_good:before {
  content: "";
}

.material-icons.system_security_update_warning:before {
  content: "";
}

.material-icons.system_update:before {
  content: "";
}

.material-icons.system_update_alt:before, .material-icons.system_update_tv:before {
  content: "";
}

.material-icons.tab:before {
  content: "";
}

.material-icons.tab_unselected:before {
  content: "";
}

.material-icons.table_bar:before {
  content: "";
}

.material-icons.table_chart:before {
  content: "";
}

.material-icons.table_restaurant:before {
  content: "";
}

.material-icons.table_rows:before {
  content: "";
}

.material-icons.table_view:before {
  content: "";
}

.material-icons.tablet:before {
  content: "";
}

.material-icons.tablet_android:before {
  content: "";
}

.material-icons.tablet_mac:before {
  content: "";
}

.material-icons.tag:before {
  content: "";
}

.material-icons.tag_faces:before {
  content: "";
}

.material-icons.takeout_dining:before {
  content: "";
}

.material-icons.tap_and_play:before {
  content: "";
}

.material-icons.tapas:before {
  content: "";
}

.material-icons.task:before {
  content: "";
}

.material-icons.task_alt:before {
  content: "";
}

.material-icons.taxi_alert:before {
  content: "";
}

.material-icons.telegram:before {
  content: "";
}

.material-icons.temple_buddhist:before {
  content: "";
}

.material-icons.temple_hindu:before {
  content: "";
}

.material-icons.terminal:before {
  content: "";
}

.material-icons.terrain:before {
  content: "";
}

.material-icons.text_decrease:before {
  content: "";
}

.material-icons.text_fields:before {
  content: "";
}

.material-icons.text_format:before {
  content: "";
}

.material-icons.text_increase:before {
  content: "";
}

.material-icons.text_rotate_up:before {
  content: "";
}

.material-icons.text_rotate_vertical:before {
  content: "";
}

.material-icons.text_rotation_angledown:before {
  content: "";
}

.material-icons.text_rotation_angleup:before {
  content: "";
}

.material-icons.text_rotation_down:before {
  content: "";
}

.material-icons.text_rotation_none:before {
  content: "";
}

.material-icons.text_snippet:before {
  content: "";
}

.material-icons.textsms:before {
  content: "";
}

.material-icons.texture:before {
  content: "";
}

.material-icons.theater_comedy:before {
  content: "";
}

.material-icons.theaters:before {
  content: "";
}

.material-icons.thermostat:before {
  content: "";
}

.material-icons.thermostat_auto:before {
  content: "";
}

.material-icons.thumb_down:before {
  content: "";
}

.material-icons.thumb_down_alt:before {
  content: "";
}

.material-icons.thumb_down_off_alt:before {
  content: "";
}

.material-icons.thumb_up:before {
  content: "";
}

.material-icons.thumb_up_alt:before {
  content: "";
}

.material-icons.thumb_up_off_alt:before {
  content: "";
}

.material-icons.thumbs_up_down:before {
  content: "";
}

.material-icons.thunderstorm:before {
  content: "";
}

.material-icons.tiktok:before {
  content: "";
}

.material-icons.time_to_leave:before {
  content: "";
}

.material-icons.timelapse:before {
  content: "";
}

.material-icons.timeline:before {
  content: "";
}

.material-icons.timer:before {
  content: "";
}

.material-icons.timer_10:before {
  content: "";
}

.material-icons.timer_10_select:before {
  content: "";
}

.material-icons.timer_3:before {
  content: "";
}

.material-icons.timer_3_select:before {
  content: "";
}

.material-icons.timer_off:before {
  content: "";
}

.material-icons.tips_and_updates:before {
  content: "";
}

.material-icons.tire_repair:before {
  content: "";
}

.material-icons.title:before {
  content: "";
}

.material-icons.toc:before {
  content: "";
}

.material-icons.today:before {
  content: "";
}

.material-icons.toggle_off:before {
  content: "";
}

.material-icons.toggle_on:before {
  content: "";
}

.material-icons.token:before {
  content: "";
}

.material-icons.toll:before {
  content: "";
}

.material-icons.tonality:before {
  content: "";
}

.material-icons.topic:before {
  content: "";
}

.material-icons.tornado:before {
  content: "";
}

.material-icons.touch_app:before {
  content: "";
}

.material-icons.tour:before {
  content: "";
}

.material-icons.toys:before {
  content: "";
}

.material-icons.track_changes:before {
  content: "";
}

.material-icons.traffic:before {
  content: "";
}

.material-icons.train:before {
  content: "";
}

.material-icons.tram:before {
  content: "";
}

.material-icons.transcribe:before {
  content: "";
}

.material-icons.transfer_within_a_station:before {
  content: "";
}

.material-icons.transform:before {
  content: "";
}

.material-icons.transgender:before {
  content: "";
}

.material-icons.transit_enterexit:before {
  content: "";
}

.material-icons.translate:before {
  content: "";
}

.material-icons.travel_explore:before {
  content: "";
}

.material-icons.trending_down:before {
  content: "";
}

.material-icons.trending_flat:before, .material-icons.trending_neutral:before {
  content: "";
}

.material-icons.trending_up:before {
  content: "";
}

.material-icons.trip_origin:before {
  content: "";
}

.material-icons.troubleshoot:before {
  content: "";
}

.material-icons.try:before {
  content: "";
}

.material-icons.tsunami:before {
  content: "";
}

.material-icons.tty:before {
  content: "";
}

.material-icons.tune:before {
  content: "";
}

.material-icons.tungsten:before {
  content: "";
}

.material-icons.turn_left:before {
  content: "";
}

.material-icons.turn_right:before {
  content: "";
}

.material-icons.turn_sharp_left:before {
  content: "";
}

.material-icons.turn_sharp_right:before {
  content: "";
}

.material-icons.turn_slight_left:before {
  content: "";
}

.material-icons.turn_slight_right:before {
  content: "";
}

.material-icons.turned_in:before {
  content: "";
}

.material-icons.turned_in_not:before {
  content: "";
}

.material-icons.tv:before {
  content: "";
}

.material-icons.tv_off:before {
  content: "";
}

.material-icons.two_wheeler:before {
  content: "";
}

.material-icons.type_specimen:before {
  content: "";
}

.material-icons.u_turn_left:before {
  content: "";
}

.material-icons.u_turn_right:before {
  content: "";
}

.material-icons.umbrella:before {
  content: "";
}

.material-icons.unarchive:before {
  content: "";
}

.material-icons.undo:before {
  content: "";
}

.material-icons.unfold_less:before {
  content: "";
}

.material-icons.unfold_more:before {
  content: "";
}

.material-icons.unpublished:before {
  content: "";
}

.material-icons.unsubscribe:before {
  content: "";
}

.material-icons.upcoming:before {
  content: "";
}

.material-icons.update:before {
  content: "";
}

.material-icons.update_disabled:before {
  content: "";
}

.material-icons.upgrade:before {
  content: "";
}

.material-icons.upload:before {
  content: "";
}

.material-icons.upload_file:before {
  content: "";
}

.material-icons.usb:before {
  content: "";
}

.material-icons.usb_off:before {
  content: "";
}

.material-icons.vaccines:before {
  content: "";
}

.material-icons.vape_free:before {
  content: "";
}

.material-icons.vaping_rooms:before {
  content: "";
}

.material-icons.verified:before {
  content: "";
}

.material-icons.verified_user:before {
  content: "";
}

.material-icons.vertical_align_bottom:before {
  content: "";
}

.material-icons.vertical_align_center:before {
  content: "";
}

.material-icons.vertical_align_top:before {
  content: "";
}

.material-icons.vertical_distribute:before {
  content: "";
}

.material-icons.vertical_shades:before {
  content: "";
}

.material-icons.vertical_shades_closed:before {
  content: "";
}

.material-icons.vertical_split:before {
  content: "";
}

.material-icons.vibration:before {
  content: "";
}

.material-icons.video_call:before {
  content: "";
}

.material-icons.video_camera_back:before {
  content: "";
}

.material-icons.video_camera_front:before {
  content: "";
}

.material-icons.video_collection:before {
  content: "";
}

.material-icons.video_file:before {
  content: "";
}

.material-icons.video_label:before {
  content: "";
}

.material-icons.video_library:before {
  content: "";
}

.material-icons.video_settings:before {
  content: "";
}

.material-icons.video_stable:before {
  content: "";
}

.material-icons.videocam:before {
  content: "";
}

.material-icons.videocam_off:before {
  content: "";
}

.material-icons.videogame_asset:before {
  content: "";
}

.material-icons.videogame_asset_off:before {
  content: "";
}

.material-icons.view_agenda:before {
  content: "";
}

.material-icons.view_array:before {
  content: "";
}

.material-icons.view_carousel:before {
  content: "";
}

.material-icons.view_column:before {
  content: "";
}

.material-icons.view_comfortable:before, .material-icons.view_comfy:before {
  content: "";
}

.material-icons.view_comfy_alt:before {
  content: "";
}

.material-icons.view_compact:before {
  content: "";
}

.material-icons.view_compact_alt:before {
  content: "";
}

.material-icons.view_cozy:before {
  content: "";
}

.material-icons.view_day:before {
  content: "";
}

.material-icons.view_headline:before {
  content: "";
}

.material-icons.view_in_ar:before {
  content: "";
}

.material-icons.view_kanban:before {
  content: "";
}

.material-icons.view_list:before {
  content: "";
}

.material-icons.view_module:before {
  content: "";
}

.material-icons.view_quilt:before {
  content: "";
}

.material-icons.view_sidebar:before {
  content: "";
}

.material-icons.view_stream:before {
  content: "";
}

.material-icons.view_timeline:before {
  content: "";
}

.material-icons.view_week:before {
  content: "";
}

.material-icons.vignette:before {
  content: "";
}

.material-icons.villa:before {
  content: "";
}

.material-icons.visibility:before {
  content: "";
}

.material-icons.visibility_off:before {
  content: "";
}

.material-icons.voice_chat:before {
  content: "";
}

.material-icons.voice_over_off:before {
  content: "";
}

.material-icons.voicemail:before {
  content: "";
}

.material-icons.volcano:before {
  content: "";
}

.material-icons.volume_down:before {
  content: "";
}

.material-icons.volume_down_alt:before {
  content: "";
}

.material-icons.volume_mute:before {
  content: "";
}

.material-icons.volume_off:before {
  content: "";
}

.material-icons.volume_up:before {
  content: "";
}

.material-icons.volunteer_activism:before {
  content: "";
}

.material-icons.vpn_key:before {
  content: "";
}

.material-icons.vpn_key_off:before {
  content: "";
}

.material-icons.vpn_lock:before {
  content: "";
}

.material-icons.vrpano:before {
  content: "";
}

.material-icons.wallet:before {
  content: "";
}

.material-icons.wallet_giftcard:before {
  content: "";
}

.material-icons.wallet_membership:before {
  content: "";
}

.material-icons.wallet_travel:before {
  content: "";
}

.material-icons.wallpaper:before {
  content: "";
}

.material-icons.warehouse:before {
  content: "";
}

.material-icons.warning:before {
  content: "";
}

.material-icons.warning_amber:before {
  content: "";
}

.material-icons.wash:before {
  content: "";
}

.material-icons.watch:before {
  content: "";
}

.material-icons.watch_later:before {
  content: "";
}

.material-icons.watch_off:before {
  content: "";
}

.material-icons.water:before {
  content: "";
}

.material-icons.water_damage:before {
  content: "";
}

.material-icons.water_drop:before {
  content: "";
}

.material-icons.waterfall_chart:before {
  content: "";
}

.material-icons.waves:before {
  content: "";
}

.material-icons.waving_hand:before {
  content: "";
}

.material-icons.wb_auto:before {
  content: "";
}

.material-icons.wb_cloudy:before {
  content: "";
}

.material-icons.wb_incandescent:before {
  content: "";
}

.material-icons.wb_iridescent:before {
  content: "";
}

.material-icons.wb_shade:before {
  content: "";
}

.material-icons.wb_sunny:before {
  content: "";
}

.material-icons.wb_twighlight:before {
  content: "";
}

.material-icons.wb_twilight:before {
  content: "";
}

.material-icons.wc:before {
  content: "";
}

.material-icons.web:before {
  content: "";
}

.material-icons.web_asset:before {
  content: "";
}

.material-icons.web_asset_off:before {
  content: "";
}

.material-icons.web_stories:before {
  content: "";
}

.material-icons.webhook:before {
  content: "";
}

.material-icons.wechat:before {
  content: "";
}

.material-icons.weekend:before {
  content: "";
}

.material-icons.west:before {
  content: "";
}

.material-icons.whatsapp:before {
  content: "";
}

.material-icons.whatshot:before {
  content: "";
}

.material-icons.wheelchair_pickup:before {
  content: "";
}

.material-icons.where_to_vote:before {
  content: "";
}

.material-icons.widgets:before {
  content: "";
}

.material-icons.width_full:before {
  content: "";
}

.material-icons.width_normal:before {
  content: "";
}

.material-icons.width_wide:before {
  content: "";
}

.material-icons.wifi:before {
  content: "";
}

.material-icons.wifi_1_bar:before {
  content: "";
}

.material-icons.wifi_2_bar:before {
  content: "";
}

.material-icons.wifi_calling:before {
  content: "";
}

.material-icons.wifi_calling_3:before {
  content: "";
}

.material-icons.wifi_channel:before {
  content: "";
}

.material-icons.wifi_find:before {
  content: "";
}

.material-icons.wifi_lock:before {
  content: "";
}

.material-icons.wifi_off:before {
  content: "";
}

.material-icons.wifi_password:before {
  content: "";
}

.material-icons.wifi_protected_setup:before {
  content: "";
}

.material-icons.wifi_tethering:before {
  content: "";
}

.material-icons.wifi_tethering_error:before {
  content: "";
}

.material-icons.wifi_tethering_error_rounded:before {
  content: "";
}

.material-icons.wifi_tethering_off:before {
  content: "";
}

.material-icons.wind_power:before {
  content: "";
}

.material-icons.window:before {
  content: "";
}

.material-icons.wine_bar:before {
  content: "";
}

.material-icons.woman:before {
  content: "";
}

.material-icons.woo_commerce:before {
  content: "";
}

.material-icons.wordpress:before {
  content: "";
}

.material-icons.work:before {
  content: "";
}

.material-icons.work_history:before {
  content: "";
}

.material-icons.work_off:before {
  content: "";
}

.material-icons.work_outline:before {
  content: "";
}

.material-icons.workspace_premium:before {
  content: "";
}

.material-icons.workspaces:before {
  content: "";
}

.material-icons.workspaces_filled:before {
  content: "";
}

.material-icons.workspaces_outline:before {
  content: "";
}

.material-icons.wrap_text:before {
  content: "";
}

.material-icons.wrong_location:before {
  content: "";
}

.material-icons.wysiwyg:before {
  content: "";
}

.material-icons.yard:before {
  content: "";
}

.material-icons.youtube_searched_for:before {
  content: "";
}

.material-icons.zoom_in:before {
  content: "";
}

.material-icons.zoom_in_map:before {
  content: "";
}

.material-icons.zoom_out:before {
  content: "";
}

.material-icons.zoom_out_map:before {
  content: "";
}

:focus {
  outline: 2px auto #64b5f6;
}

.cui-focus-disabled :focus, .cui-focus-disabled :focus ~ .cui-control-indicator {
  outline: 0 !important;
}

*, :after, :before {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: #26323800;
  font-family: sans-serif;
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #37474f;
  text-align: left;
  background-color: #eceff1;
  margin: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 15px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title], abbr[title] {
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #1565c0;
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #104c90;
  text-decoration: underline;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type="reset"], [type="submit"], button, html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="datetime-local"], input[type="month"], input[type="time"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  width: 100%;
  max-width: 100%;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.cui-h1, .cui-h2, .cui-h3, .cui-h4, .cui-h5, .cui-h6, h1, h2, h3, h4, h5, h6 {
  color: #37474f;
  margin: 0 0 15px;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
}

.cui-h1, h1 {
  font-size: 35px;
}

.cui-h2, h2 {
  font-size: 28px;
}

.cui-h3, h3 {
  font-size: 21px;
}

.cui-h4, h4 {
  font-size: 17.5px;
}

.cui-h5, h5 {
  font-size: 14px;
}

.cui-h6, h6 {
  font-size: 10.5px;
}

p {
  margin: 0 0 15px;
}

.cui-text-muted {
  color: #90a4ae;
}

.cui-text-disabled {
  color: #cfd8dc;
}

code {
  color: #37474f;
  word-break: break-word;
  background: #fff;
  border: 1px solid #cfd8dc;
  border-radius: 5px;
  padding: 2.5px 5px;
  font-size: 13px;
}

pre {
  background: #fff;
  border: 1px solid #cfd8dc;
  border-radius: 5px;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
  background: none;
  border: none;
  padding: 15px 20px;
}

.cui-align-right {
  justify-content: flex-end;
  display: flex;
}

.cui-align-left {
  justify-content: flex-start;
  display: flex;
}

.cui-fluid {
  width: 100%;
}

.cui-disabled {
  cursor: not-allowed !important;
  opacity: .65 !important;
}

.cui-hidden {
  display: none;
}

.cui-control {
  color: #37474f;
  vertical-align: top;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  min-height: 16px;
  padding-left: 24px;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  position: relative;
}

.cui-control .cui-control-indicator {
  width: 16px;
  height: 16px;
}

.cui-control input {
  z-index: -1;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.cui-control .cui-control-indicator {
  background: #fff;
  border-radius: 2px;
  margin: 0;
  transition: background .2s cubic-bezier(.4, 1, .75, .9);
  position: absolute;
  top: 0;
  left: 0;
}

.cui-control input:focus ~ .cui-control-indicator {
  outline: 2px auto #64b5f6;
}

.cui-control:focus .cui-control-indicator, .cui-control:hover .cui-control-indicator {
  background: #f6f7f8;
  border: 1px solid #b0bec5;
}

.cui-control:active .cui-control-indicator {
  background: #eceff1;
  border: 1px solid #b0bec5;
}

.cui-control.cui-disabled .cui-control-indicator {
  -webkit-user-select: none;
  user-select: none;
  cursor: not-allowed;
  opacity: .5;
  background: #fff;
}

.cui-control.cui-xs {
  min-height: 12px;
  padding-left: 18px;
  font-size: 12px;
  line-height: 12px;
}

.cui-control.cui-xs .cui-control-indicator {
  width: 12px;
  height: 12px;
}

.cui-control.cui-sm {
  min-height: 14px;
  padding-left: 21px;
  font-size: 13px;
  line-height: 14px;
}

.cui-control.cui-sm .cui-control-indicator {
  width: 14px;
  height: 14px;
}

.cui-control.cui-lg {
  min-height: 18px;
  padding-left: 27px;
  font-size: 16px;
  line-height: 18px;
}

.cui-control.cui-lg .cui-control-indicator {
  width: 18px;
  height: 18px;
}

.cui-control.cui-xl {
  min-height: 20px;
  padding-left: 30px;
  font-size: 18px;
  line-height: 20px;
}

.cui-control.cui-xl .cui-control-indicator {
  width: 20px;
  height: 20px;
}

.cui-control.cui-primary {
  color: #5c6bc0;
}

.cui-control.cui-positive {
  color: #4caf50;
}

.cui-control.cui-negative {
  color: #f44336;
}

.cui-control.cui-warning {
  color: #f57c00;
}

.cui-breadcrumb {
  align-items: center;
  display: flex;
}

.cui-breadcrumb .cui-breadcrumb-item {
  font-size: 14px;
}

.cui-breadcrumb .cui-breadcrumb-seperator {
  margin: 0 6px;
  font-size: 14px;
}

.cui-breadcrumb .cui-breadcrumb-item {
  color: #607d8b;
  text-decoration: none;
  transition: color .2s cubic-bezier(.4, 1, .75, .9);
  display: flex;
}

.cui-breadcrumb .cui-breadcrumb-item .cui-icon {
  color: #607d8b;
}

.cui-breadcrumb a.cui-breadcrumb-item, .cui-breadcrumb a.cui-breadcrumb-item .cui-icon {
  color: #b0bec5;
}

.cui-breadcrumb a.cui-breadcrumb-item:hover, .cui-breadcrumb a.cui-breadcrumb-item:hover .cui-icon {
  color: #607d8b;
}

.cui-breadcrumb .cui-breadcrumb-seperator {
  color: #607d8b;
  display: flex;
}

.cui-breadcrumb .cui-breadcrumb-seperator:last-child {
  display: none;
}

.cui-breadcrumb.cui-xs .cui-breadcrumb-item {
  font-size: 12px;
}

.cui-breadcrumb.cui-xs .cui-breadcrumb-seperator {
  margin: 0 5px;
  font-size: 12px;
}

.cui-breadcrumb.cui-sm .cui-breadcrumb-item {
  font-size: 13px;
}

.cui-breadcrumb.cui-sm .cui-breadcrumb-seperator {
  margin: 0 5.5px;
  font-size: 13px;
}

.cui-breadcrumb.cui-lg .cui-breadcrumb-item {
  font-size: 16px;
}

.cui-breadcrumb.cui-lg .cui-breadcrumb-seperator {
  margin: 0 7px;
  font-size: 16px;
}

.cui-breadcrumb.cui-xl .cui-breadcrumb-item {
  font-size: 18px;
}

.cui-breadcrumb.cui-xl .cui-breadcrumb-seperator {
  margin: 0 8px;
  font-size: 18px;
}

.cui-button {
  color: #607d8b;
  vertical-align: middle;
  cursor: pointer;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #c5cdd1;
  border-radius: 3px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  padding: 0 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  text-decoration: none;
  transition: background .2s cubic-bezier(.4, 1, .75, .9), border .2s cubic-bezier(.4, 1, .75, .9), box-shadow .2s cubic-bezier(.4, 1, .75, .9);
  display: inline-flex;
  position: relative;
  box-shadow: 0 1px #eef1f2;
}

.cui-button.cui-compact {
  padding-left: 12px;
  padding-right: 12px;
}

.cui-button .cui-button-label:not(:last-child), .cui-button .cui-icon:not(:last-child) {
  margin-right: 10px;
}

.cui-button .cui-button-sublabel:not(:last-child) {
  margin-right: 4px;
}

.cui-button.cui-button-icon {
  width: 40px;
}

.cui-button:hover {
  color: #546e7a;
  background: linear-gradient(#fff 10.5%, #eef1f2 100%);
  border: 1px solid #b0bec5;
  text-decoration: none;
}

.cui-button.cui-active, .cui-button:active {
  background: linear-gradient(#eff1f3 10.5% 100%);
  border: 1px solid #b0bec5;
  box-shadow: 0 1px #eef1f2, inset 0 4px 5px #dadddf;
}

.cui-button.cui-disabled, .cui-button[disabled] {
  color: #607d8b;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #c5cdd1;
  box-shadow: 0 1px #eef1f2;
}

.cui-button.cui-align-center {
  justify-content: center;
}

.cui-button.cui-align-left {
  justify-content: flex-start;
}

.cui-button.cui-align-left .cui-icon:last-child {
  margin-left: auto !important;
}

.cui-button.cui-align-right {
  justify-content: flex-end;
}

.cui-button.cui-align-right .cui-icon:first-child {
  margin-right: auto !important;
}

.cui-button .cui-button-label, .cui-button .cui-button-sublabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-user-select: none;
  user-select: none;
  line-height: initial;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.cui-button .cui-button-sublabel {
  color: #90a4ae;
}

.cui-button.cui-button-icon {
  flex-direction: column;
}

.cui-button.cui-loading {
  pointer-events: none;
}

.cui-button.cui-loading .cui-button-label, .cui-button.cui-loading .cui-button-sublabel, .cui-button.cui-loading svg {
  visibility: hidden;
}

.cui-button-group.cui-basic .cui-button, .cui-button.cui-basic {
  color: #607d8b;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
  font-weight: 400;
}

.cui-button-group.cui-basic .cui-button:hover, .cui-button.cui-basic:hover {
  color: #607d8b;
  background: #eff0f1;
  border: 1px solid #0000;
  text-decoration: none;
}

.cui-button-group.cui-basic .cui-button.cui-active, .cui-button-group.cui-basic .cui-button:active, .cui-button.cui-basic.cui-active, .cui-button.cui-basic:active {
  box-shadow: none;
  background: #e7e8e9;
  border: 1px solid #0000;
}

.cui-button-group.cui-basic .cui-button.cui-disabled, .cui-button-group.cui-basic .cui-button[disabled], .cui-button.cui-basic.cui-disabled, .cui-button.cui-basic[disabled] {
  color: #607d8b;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
}

.cui-button-group.cui-rounded .cui-button, .cui-button.cui-rounded {
  border-radius: 30px;
}

.cui-button-group.cui-fluid .cui-button, .cui-button.cui-fluid {
  width: 100%;
}

.cui-button-group.cui-outlined .cui-button, .cui-button.cui-outlined {
  background: none;
}

.cui-button-group.cui-xs .cui-button, .cui-button.cui-xs, .cui-menu.cui-xs .cui-button {
  height: 30px;
  min-height: 30px;
  padding: 0 12px;
  font-size: 12px;
  line-height: 30px;
}

.cui-button-group.cui-xs .cui-button.cui-compact, .cui-button.cui-xs.cui-compact, .cui-menu.cui-xs .cui-button.cui-compact {
  padding-left: 10px;
  padding-right: 10px;
}

.cui-button-group.cui-xs .cui-button .cui-button-label:not(:last-child), .cui-button-group.cui-xs .cui-button .cui-icon:not(:last-child), .cui-button.cui-xs .cui-button-label:not(:last-child), .cui-button.cui-xs .cui-icon:not(:last-child), .cui-menu.cui-xs .cui-button .cui-button-label:not(:last-child), .cui-menu.cui-xs .cui-button .cui-icon:not(:last-child) {
  margin-right: 8px;
}

.cui-button-group.cui-xs .cui-button .cui-button-sublabel:not(:last-child), .cui-button.cui-xs .cui-button-sublabel:not(:last-child), .cui-menu.cui-xs .cui-button .cui-button-sublabel:not(:last-child) {
  margin-right: 4px;
}

.cui-button-group.cui-xs .cui-button.cui-button-icon, .cui-button.cui-xs.cui-button-icon, .cui-menu.cui-xs .cui-button.cui-button-icon {
  width: 30px;
}

.cui-button-group.cui-sm .cui-button, .cui-button.cui-sm, .cui-menu.cui-sm .cui-button {
  height: 35px;
  min-height: 35px;
  padding: 0 13px;
  font-size: 13px;
  line-height: 35px;
}

.cui-button-group.cui-sm .cui-button.cui-compact, .cui-button.cui-sm.cui-compact, .cui-menu.cui-sm .cui-button.cui-compact {
  padding-left: 11px;
  padding-right: 11px;
}

.cui-button-group.cui-sm .cui-button .cui-button-label:not(:last-child), .cui-button-group.cui-sm .cui-button .cui-icon:not(:last-child), .cui-button.cui-sm .cui-button-label:not(:last-child), .cui-button.cui-sm .cui-icon:not(:last-child), .cui-menu.cui-sm .cui-button .cui-button-label:not(:last-child), .cui-menu.cui-sm .cui-button .cui-icon:not(:last-child) {
  margin-right: 9px;
}

.cui-button-group.cui-sm .cui-button .cui-button-sublabel:not(:last-child), .cui-button.cui-sm .cui-button-sublabel:not(:last-child), .cui-menu.cui-sm .cui-button .cui-button-sublabel:not(:last-child) {
  margin-right: 4px;
}

.cui-button-group.cui-sm .cui-button.cui-button-icon, .cui-button.cui-sm.cui-button-icon, .cui-menu.cui-sm .cui-button.cui-button-icon {
  width: 35px;
}

.cui-button-group.cui-lg .cui-button, .cui-button.cui-lg, .cui-menu.cui-lg .cui-button {
  height: 45px;
  min-height: 45px;
  padding: 0 16px;
  font-size: 16px;
  line-height: 45px;
}

.cui-button-group.cui-lg .cui-button.cui-compact, .cui-button.cui-lg.cui-compact, .cui-menu.cui-lg .cui-button.cui-compact {
  padding-left: 14px;
  padding-right: 14px;
}

.cui-button-group.cui-lg .cui-button .cui-button-label:not(:last-child), .cui-button-group.cui-lg .cui-button .cui-icon:not(:last-child), .cui-button.cui-lg .cui-button-label:not(:last-child), .cui-button.cui-lg .cui-icon:not(:last-child), .cui-menu.cui-lg .cui-button .cui-button-label:not(:last-child), .cui-menu.cui-lg .cui-button .cui-icon:not(:last-child) {
  margin-right: 11px;
}

.cui-button-group.cui-lg .cui-button .cui-button-sublabel:not(:last-child), .cui-button.cui-lg .cui-button-sublabel:not(:last-child), .cui-menu.cui-lg .cui-button .cui-button-sublabel:not(:last-child) {
  margin-right: 5px;
}

.cui-button-group.cui-lg .cui-button.cui-button-icon, .cui-button.cui-lg.cui-button-icon, .cui-menu.cui-lg .cui-button.cui-button-icon {
  width: 45px;
}

.cui-button-group.cui-xl .cui-button, .cui-button.cui-xl, .cui-menu.cui-xl .cui-button {
  height: 50px;
  min-height: 50px;
  padding: 0 19px;
  font-size: 18px;
  line-height: 50px;
}

.cui-button-group.cui-xl .cui-button.cui-compact, .cui-button.cui-xl.cui-compact, .cui-menu.cui-xl .cui-button.cui-compact {
  padding-left: 16px;
  padding-right: 16px;
}

.cui-button-group.cui-xl .cui-button .cui-button-label:not(:last-child), .cui-button-group.cui-xl .cui-button .cui-icon:not(:last-child), .cui-button.cui-xl .cui-button-label:not(:last-child), .cui-button.cui-xl .cui-icon:not(:last-child), .cui-menu.cui-xl .cui-button .cui-button-label:not(:last-child), .cui-menu.cui-xl .cui-button .cui-icon:not(:last-child) {
  margin-right: 13px;
}

.cui-button-group.cui-xl .cui-button .cui-button-sublabel:not(:last-child), .cui-button.cui-xl .cui-button-sublabel:not(:last-child), .cui-menu.cui-xl .cui-button .cui-button-sublabel:not(:last-child) {
  margin-right: 6px;
}

.cui-button-group.cui-xl .cui-button.cui-button-icon, .cui-button.cui-xl.cui-button-icon, .cui-menu.cui-xl .cui-button.cui-button-icon {
  width: 50px;
}

.cui-button.cui-negative .cui-button-sublabel, .cui-button.cui-positive .cui-button-sublabel, .cui-button.cui-primary .cui-button-sublabel, .cui-button.cui-warning .cui-button-sublabel {
  color: #eceff1;
}

.cui-button.cui-negative.cui-basic .cui-button-sublabel, .cui-button.cui-negative.cui-outlined .cui-button-sublabel, .cui-button.cui-positive.cui-basic .cui-button-sublabel, .cui-button.cui-positive.cui-outlined .cui-button-sublabel, .cui-button.cui-primary.cui-basic .cui-button-sublabel, .cui-button.cui-primary.cui-outlined .cui-button-sublabel, .cui-button.cui-warning.cui-basic .cui-button-sublabel, .cui-button.cui-warning.cui-outlined .cui-button-sublabel {
  color: #b0bec5;
}

.cui-button.cui-negative:not(.cui-basic):not(.cui-outlined) .cui-spinner:after, .cui-button.cui-positive:not(.cui-basic):not(.cui-outlined) .cui-spinner:after, .cui-button.cui-primary:not(.cui-basic):not(.cui-outlined) .cui-spinner:after, .cui-button.cui-warning:not(.cui-basic):not(.cui-outlined) .cui-spinner:after {
  border-color: #0000;
  border-top-color: #fff !important;
}

.cui-button-group.cui-primary .cui-button, .cui-button.cui-primary {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#5c6bc0 10.5%, #5766b6 100%);
  border: 1px solid #5360ad;
  font-weight: 400;
}

.cui-button-group.cui-primary .cui-button:hover, .cui-button.cui-primary:hover {
  color: #fff;
  background: linear-gradient(#5c6bc0 10.5%, #3f51b5 100%);
  border: 1px solid #4a569a;
  text-decoration: none;
}

.cui-button-group.cui-primary .cui-button.cui-active, .cui-button-group.cui-primary .cui-button:active, .cui-button.cui-primary.cui-active, .cui-button.cui-primary:active {
  background: linear-gradient(#5c6bc0 10.5%, #3949ab 100%);
  border: 1px solid #404b86;
  box-shadow: inset 0 4px 5px #4a569a;
}

.cui-button-group.cui-primary .cui-button.cui-disabled, .cui-button-group.cui-primary .cui-button[disabled], .cui-button.cui-primary.cui-disabled, .cui-button.cui-primary[disabled] {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#5c6bc0 10.5%, #5766b6 100%);
  border: 1px solid #5360ad;
}

.cui-button-group.cui-primary .cui-button .cui-icon, .cui-button.cui-primary .cui-icon {
  color: #fff;
}

.cui-button-group.cui-primary.cui-basic .cui-button, .cui-button.cui-primary.cui-basic {
  color: #5c6bc0;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
  font-weight: 400;
}

.cui-button-group.cui-primary.cui-basic .cui-button:hover, .cui-button.cui-primary.cui-basic:hover {
  color: #5c6bc0;
  background: #eff0f9;
  border: 1px solid #0000;
  text-decoration: none;
}

.cui-button-group.cui-primary.cui-basic .cui-button.cui-active, .cui-button-group.cui-primary.cui-basic .cui-button:active, .cui-button.cui-primary.cui-basic.cui-active, .cui-button.cui-primary.cui-basic:active {
  box-shadow: none;
  background: #dee1f2;
  border: 1px solid #0000;
}

.cui-button-group.cui-primary.cui-basic .cui-button.cui-disabled, .cui-button-group.cui-primary.cui-basic .cui-button[disabled], .cui-button.cui-primary.cui-basic.cui-disabled, .cui-button.cui-primary.cui-basic[disabled] {
  color: #5c6bc0;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
}

.cui-button-group.cui-primary.cui-basic .cui-button .cui-icon, .cui-button.cui-primary.cui-basic .cui-icon {
  color: #5c6bc0;
}

.cui-button-group.cui-primary.cui-outlined .cui-button, .cui-button.cui-primary.cui-outlined {
  color: #5c6bc0;
  box-shadow: none;
  background: none;
  border: 1px solid #5360ad;
  font-weight: 400;
}

.cui-button-group.cui-primary.cui-outlined .cui-button:hover, .cui-button.cui-primary.cui-outlined:hover {
  color: #5c6bc0;
  background: #f7f8fc;
  border: 1px solid #4a569a;
  text-decoration: none;
}

.cui-button-group.cui-primary.cui-outlined .cui-button.cui-active, .cui-button-group.cui-primary.cui-outlined .cui-button:active, .cui-button.cui-primary.cui-outlined.cui-active, .cui-button.cui-primary.cui-outlined:active {
  box-shadow: none;
  background: #eff0f9;
  border: 1px solid #404b86;
}

.cui-button-group.cui-primary.cui-outlined .cui-button.cui-disabled, .cui-button-group.cui-primary.cui-outlined .cui-button[disabled], .cui-button.cui-primary.cui-outlined.cui-disabled, .cui-button.cui-primary.cui-outlined[disabled] {
  color: #5c6bc0;
  box-shadow: none;
  background: none;
  border: 1px solid #5360ad;
}

.cui-button-group.cui-primary.cui-outlined .cui-button .cui-icon, .cui-button.cui-primary.cui-outlined .cui-icon {
  color: #5c6bc0;
}

.cui-button-group.cui-positive .cui-button, .cui-button.cui-positive {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#4caf50 10.5%, #43a047 100%);
  border: 1px solid #3d8c40;
  font-weight: 400;
}

.cui-button-group.cui-positive .cui-button:hover, .cui-button.cui-positive:hover {
  color: #fff;
  background: linear-gradient(#4caf50 10.5%, #388e3c 100%);
  border: 1px solid #357b38;
  text-decoration: none;
}

.cui-button-group.cui-positive .cui-button.cui-active, .cui-button-group.cui-positive .cui-button:active, .cui-button.cui-positive.cui-active, .cui-button.cui-positive:active {
  background: linear-gradient(#4caf50 10.5%, #388e3c 100%);
  border: 1px solid #357b38;
  box-shadow: inset 0 4px 5px #3d8c40;
}

.cui-button-group.cui-positive .cui-button.cui-disabled, .cui-button-group.cui-positive .cui-button[disabled], .cui-button.cui-positive.cui-disabled, .cui-button.cui-positive[disabled] {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#4caf50 10.5%, #43a047 100%);
  border: 1px solid #3d8c40;
}

.cui-button-group.cui-positive .cui-button .cui-icon, .cui-button.cui-positive .cui-icon {
  color: #fff;
}

.cui-button-group.cui-positive.cui-basic .cui-button, .cui-button.cui-positive.cui-basic {
  color: #4caf50;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
  font-weight: 400;
}

.cui-button-group.cui-positive.cui-basic .cui-button:hover, .cui-button.cui-positive.cui-basic:hover {
  color: #4caf50;
  background: #edf7ee;
  border: 1px solid #0000;
  text-decoration: none;
}

.cui-button-group.cui-positive.cui-basic .cui-button.cui-active, .cui-button-group.cui-positive.cui-basic .cui-button:active, .cui-button.cui-positive.cui-basic.cui-active, .cui-button.cui-positive.cui-basic:active {
  box-shadow: none;
  background: #dbefdc;
  border: 1px solid #0000;
}

.cui-button-group.cui-positive.cui-basic .cui-button.cui-disabled, .cui-button-group.cui-positive.cui-basic .cui-button[disabled], .cui-button.cui-positive.cui-basic.cui-disabled, .cui-button.cui-positive.cui-basic[disabled] {
  color: #4caf50;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
}

.cui-button-group.cui-positive.cui-basic .cui-button .cui-icon, .cui-button.cui-positive.cui-basic .cui-icon {
  color: #4caf50;
}

.cui-button-group.cui-positive.cui-outlined .cui-button, .cui-button.cui-positive.cui-outlined {
  color: #4caf50;
  box-shadow: none;
  background: none;
  border: 1px solid #3d8c40;
  font-weight: 400;
}

.cui-button-group.cui-positive.cui-outlined .cui-button:hover, .cui-button.cui-positive.cui-outlined:hover {
  color: #4caf50;
  background: #f6fbf6;
  border: 1px solid #357b38;
  text-decoration: none;
}

.cui-button-group.cui-positive.cui-outlined .cui-button.cui-active, .cui-button-group.cui-positive.cui-outlined .cui-button:active, .cui-button.cui-positive.cui-outlined.cui-active, .cui-button.cui-positive.cui-outlined:active {
  box-shadow: none;
  background: #edf7ee;
  border: 1px solid #357b38;
}

.cui-button-group.cui-positive.cui-outlined .cui-button.cui-disabled, .cui-button-group.cui-positive.cui-outlined .cui-button[disabled], .cui-button.cui-positive.cui-outlined.cui-disabled, .cui-button.cui-positive.cui-outlined[disabled] {
  color: #4caf50;
  box-shadow: none;
  background: none;
  border: 1px solid #3d8c40;
}

.cui-button-group.cui-positive.cui-outlined .cui-button .cui-icon, .cui-button.cui-positive.cui-outlined .cui-icon {
  color: #4caf50;
}

.cui-button-group.cui-negative .cui-button, .cui-button.cui-negative {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#f44336 10.5%, #e53935 100%);
  border: 1px solid #dc3c31;
  font-weight: 400;
}

.cui-button-group.cui-negative .cui-button:hover, .cui-button.cui-negative:hover {
  color: #fff;
  background: linear-gradient(#f44336 10.5%, #d32f2f 100%);
  border: 1px solid #c3362b;
  text-decoration: none;
}

.cui-button-group.cui-negative .cui-button.cui-active, .cui-button-group.cui-negative .cui-button:active, .cui-button.cui-negative.cui-active, .cui-button.cui-negative:active {
  background: linear-gradient(#f44336 10.5%, #d32f2f 100%);
  border: 1px solid #ab2f26;
  box-shadow: inset 0 4px 5px #c3362b;
}

.cui-button-group.cui-negative .cui-button.cui-disabled, .cui-button-group.cui-negative .cui-button[disabled], .cui-button.cui-negative.cui-disabled, .cui-button.cui-negative[disabled] {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#f44336 10.5%, #e53935 100%);
  border: 1px solid #dc3c31;
}

.cui-button-group.cui-negative .cui-button .cui-icon, .cui-button.cui-negative .cui-icon {
  color: #fff;
}

.cui-button-group.cui-negative.cui-basic .cui-button, .cui-button.cui-negative.cui-basic {
  color: #f44336;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
  font-weight: 400;
}

.cui-button-group.cui-negative.cui-basic .cui-button:hover, .cui-button.cui-negative.cui-basic:hover {
  color: #f44336;
  background: #feeceb;
  border: 1px solid #0000;
  text-decoration: none;
}

.cui-button-group.cui-negative.cui-basic .cui-button.cui-active, .cui-button-group.cui-negative.cui-basic .cui-button:active, .cui-button.cui-negative.cui-basic.cui-active, .cui-button.cui-negative.cui-basic:active {
  box-shadow: none;
  background: #fdd9d7;
  border: 1px solid #0000;
}

.cui-button-group.cui-negative.cui-basic .cui-button.cui-disabled, .cui-button-group.cui-negative.cui-basic .cui-button[disabled], .cui-button.cui-negative.cui-basic.cui-disabled, .cui-button.cui-negative.cui-basic[disabled] {
  color: #f44336;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
}

.cui-button-group.cui-negative.cui-basic .cui-button .cui-icon, .cui-button.cui-negative.cui-basic .cui-icon {
  color: #f44336;
}

.cui-button-group.cui-negative.cui-outlined .cui-button, .cui-button.cui-negative.cui-outlined {
  color: #f44336;
  box-shadow: none;
  background: none;
  border: 1px solid #dc3c31;
  font-weight: 400;
}

.cui-button-group.cui-negative.cui-outlined .cui-button:hover, .cui-button.cui-negative.cui-outlined:hover {
  color: #f44336;
  background: #fef6f5;
  border: 1px solid #c3362b;
  text-decoration: none;
}

.cui-button-group.cui-negative.cui-outlined .cui-button.cui-active, .cui-button-group.cui-negative.cui-outlined .cui-button:active, .cui-button.cui-negative.cui-outlined.cui-active, .cui-button.cui-negative.cui-outlined:active {
  box-shadow: none;
  background: #feeceb;
  border: 1px solid #ab2f26;
}

.cui-button-group.cui-negative.cui-outlined .cui-button.cui-disabled, .cui-button-group.cui-negative.cui-outlined .cui-button[disabled], .cui-button.cui-negative.cui-outlined.cui-disabled, .cui-button.cui-negative.cui-outlined[disabled] {
  color: #f44336;
  box-shadow: none;
  background: none;
  border: 1px solid #dc3c31;
}

.cui-button-group.cui-negative.cui-outlined .cui-button .cui-icon, .cui-button.cui-negative.cui-outlined .cui-icon {
  color: #f44336;
}

.cui-button-group.cui-warning .cui-button, .cui-button.cui-warning {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#fb8c00 10.5%, #f57c00 100%);
  border: 1px solid #dd7000;
  font-weight: 400;
}

.cui-button-group.cui-warning .cui-button:hover, .cui-button.cui-warning:hover {
  color: #fff;
  background: linear-gradient(#fb8c00 10.5%, #ef6c00 100%);
  border: 1px solid #c46300;
  text-decoration: none;
}

.cui-button-group.cui-warning .cui-button.cui-active, .cui-button-group.cui-warning .cui-button:active, .cui-button.cui-warning.cui-active, .cui-button.cui-warning:active {
  background: linear-gradient(#fb8c00 10.5%, #ef6c00 100%);
  border: 1px solid #ac5700;
  box-shadow: inset 0 4px 5px #c46300;
}

.cui-button-group.cui-warning .cui-button.cui-disabled, .cui-button-group.cui-warning .cui-button[disabled], .cui-button.cui-warning.cui-disabled, .cui-button.cui-warning[disabled] {
  color: #fff;
  box-shadow: none;
  background: linear-gradient(#fb8c00 10.5%, #f57c00 100%);
  border: 1px solid #dd7000;
}

.cui-button-group.cui-warning .cui-button .cui-icon, .cui-button.cui-warning .cui-icon {
  color: #fff;
}

.cui-button-group.cui-warning.cui-basic .cui-button, .cui-button.cui-warning.cui-basic {
  color: #f57c00;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
  font-weight: 400;
}

.cui-button-group.cui-warning.cui-basic .cui-button:hover, .cui-button.cui-warning.cui-basic:hover {
  color: #f57c00;
  background: #fef2e6;
  border: 1px solid #0000;
  text-decoration: none;
}

.cui-button-group.cui-warning.cui-basic .cui-button.cui-active, .cui-button-group.cui-warning.cui-basic .cui-button:active, .cui-button.cui-warning.cui-basic.cui-active, .cui-button.cui-warning.cui-basic:active {
  box-shadow: none;
  background: #fde5cc;
  border: 1px solid #0000;
}

.cui-button-group.cui-warning.cui-basic .cui-button.cui-disabled, .cui-button-group.cui-warning.cui-basic .cui-button[disabled], .cui-button.cui-warning.cui-basic.cui-disabled, .cui-button.cui-warning.cui-basic[disabled] {
  color: #f57c00;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
}

.cui-button-group.cui-warning.cui-basic .cui-button .cui-icon, .cui-button.cui-warning.cui-basic .cui-icon {
  color: #f57c00;
}

.cui-button-group.cui-warning.cui-outlined .cui-button, .cui-button.cui-warning.cui-outlined {
  color: #f57c00;
  box-shadow: none;
  background: none;
  border: 1px solid #dd7000;
  font-weight: 400;
}

.cui-button-group.cui-warning.cui-outlined .cui-button:hover, .cui-button.cui-warning.cui-outlined:hover {
  color: #f57c00;
  background: #fff8f2;
  border: 1px solid #c46300;
  text-decoration: none;
}

.cui-button-group.cui-warning.cui-outlined .cui-button.cui-active, .cui-button-group.cui-warning.cui-outlined .cui-button:active, .cui-button.cui-warning.cui-outlined.cui-active, .cui-button.cui-warning.cui-outlined:active {
  box-shadow: none;
  background: #fef2e6;
  border: 1px solid #ac5700;
}

.cui-button-group.cui-warning.cui-outlined .cui-button.cui-disabled, .cui-button-group.cui-warning.cui-outlined .cui-button[disabled], .cui-button.cui-warning.cui-outlined.cui-disabled, .cui-button.cui-warning.cui-outlined[disabled] {
  color: #f57c00;
  box-shadow: none;
  background: none;
  border: 1px solid #dd7000;
}

.cui-button-group.cui-warning.cui-outlined .cui-button .cui-icon, .cui-button.cui-warning.cui-outlined .cui-icon {
  color: #f57c00;
}

.cui-button-group {
  vertical-align: middle;
  display: inline-flex;
}

.cui-button-group .cui-button {
  flex: 1 0 auto;
  margin: 0;
  position: relative;
}

.cui-button-group .cui-button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.cui-button-group .cui-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.cui-button-group .cui-button.cui-active, .cui-button-group .cui-button:active, .cui-button-group .cui-button:focus, .cui-button-group .cui-button:hover {
  z-index: 10;
}

.cui-button-group.cui-basic .cui-button {
  border-radius: 3px;
  margin-right: 10px;
}

.cui-button-group.cui-basic .cui-button:not(:last-child):after {
  content: "";
  background: #c5cdd1;
  width: 1px;
  margin: 5px;
  display: inline-block;
  position: absolute;
  top: 5%;
  bottom: 5%;
  left: 100%;
}

.cui-button-group.cui-fluid {
  width: 100%;
}

.cui-button-group.cui-fluid .cui-button {
  flex: auto;
}

.cui-card {
  background: #fff;
  border: 1px solid #c5cdd1;
  border-radius: 3px;
  max-width: 320px;
  padding: 15px;
  transition: box-shadow .2s ease-in-out;
}

.cui-card.cui-xs {
  padding: 12px;
}

.cui-card.cui-sm {
  padding: 13px;
}

.cui-card.cui-lg {
  padding: 17px;
}

.cui-card.cui-xl {
  padding: 20px;
}

.cui-card.cui-elevation-1 {
  box-shadow: 0 2px 4px -2px #435a6f4d;
}

.cui-card.cui-elevation-2 {
  box-shadow: 0 5px 8px -4px #435a6f4d;
}

.cui-card.cui-elevation-3 {
  box-shadow: 0 8px 10px -4px #435a6f4d;
}

.cui-card.cui-elevation-4 {
  box-shadow: 0 16px 24px -8px #435a6f4d;
}

.cui-card.cui-card-interactive {
  cursor: pointer;
}

.cui-card.cui-card-interactive:focus, .cui-card.cui-card-interactive:hover {
  box-shadow: 0 8px 10px -4px #435a6f4d;
}

.cui-card.cui-card-interactive:active {
  box-shadow: 0 2px 4px -2px #435a6f4d;
}

.cui-card.cui-fluid {
  max-width: none;
}

.cui-callout {
  color: #37474f;
  background: #f2f4f5;
  border: 1px solid #c5cdd1;
  border-radius: 3px;
  width: 100%;
  padding: 12px;
  display: block;
  position: relative;
}

.cui-callout .cui-callout-header {
  font-size: 14px;
}

.cui-callout .cui-callout-header:not(:only-child) {
  margin: 0 0 4px;
}

.cui-callout .cui-callout-content {
  font-size: 14px;
}

.cui-callout > .cui-icon {
  top: 14px;
}

.cui-callout .cui-callout-dismiss {
  right: 12px;
}

.cui-callout.cui-callout-icon .cui-callout-content, .cui-callout.cui-callout-icon .cui-callout-header {
  padding-left: 24px;
}

.cui-callout > .cui-icon {
  color: #37474f;
}

.cui-callout .cui-callout-dismiss, .cui-callout > .cui-icon {
  position: absolute;
}

.cui-callout .cui-callout-header {
  font-weight: 700;
}

.cui-callout.cui-fluid {
  width: 100%;
  display: block;
}

.cui-callout.cui-xs {
  padding: 10px;
}

.cui-callout.cui-xs .cui-callout-header {
  font-size: 12px;
}

.cui-callout.cui-xs .cui-callout-header:not(:only-child) {
  margin: 0 0 4px;
}

.cui-callout.cui-xs .cui-callout-content {
  font-size: 12px;
}

.cui-callout.cui-xs > .cui-icon {
  top: 12px;
}

.cui-callout.cui-xs .cui-callout-dismiss {
  right: 10px;
}

.cui-callout.cui-xs.cui-callout-icon .cui-callout-content, .cui-callout.cui-xs.cui-callout-icon .cui-callout-header {
  padding-left: 20px;
}

.cui-callout.cui-sm {
  padding: 11px;
}

.cui-callout.cui-sm .cui-callout-header {
  font-size: 13px;
}

.cui-callout.cui-sm .cui-callout-header:not(:only-child) {
  margin: 0 0 4px;
}

.cui-callout.cui-sm .cui-callout-content {
  font-size: 13px;
}

.cui-callout.cui-sm > .cui-icon {
  top: 13px;
}

.cui-callout.cui-sm .cui-callout-dismiss {
  right: 11px;
}

.cui-callout.cui-sm.cui-callout-icon .cui-callout-content, .cui-callout.cui-sm.cui-callout-icon .cui-callout-header {
  padding-left: 22px;
}

.cui-callout.cui-lg {
  padding: 14px;
}

.cui-callout.cui-lg .cui-callout-header {
  font-size: 16px;
}

.cui-callout.cui-lg .cui-callout-header:not(:only-child) {
  margin: 0 0 5px;
}

.cui-callout.cui-lg .cui-callout-content {
  font-size: 16px;
}

.cui-callout.cui-lg > .cui-icon {
  top: 16px;
}

.cui-callout.cui-lg .cui-callout-dismiss {
  right: 14px;
}

.cui-callout.cui-lg.cui-callout-icon .cui-callout-content, .cui-callout.cui-lg.cui-callout-icon .cui-callout-header {
  padding-left: 28px;
}

.cui-callout.cui-xl {
  padding: 16px;
}

.cui-callout.cui-xl .cui-callout-header {
  font-size: 18px;
}

.cui-callout.cui-xl .cui-callout-header:not(:only-child) {
  margin: 0 0 6px;
}

.cui-callout.cui-xl .cui-callout-content {
  font-size: 18px;
}

.cui-callout.cui-xl > .cui-icon {
  top: 19px;
}

.cui-callout.cui-xl .cui-callout-dismiss {
  right: 16px;
}

.cui-callout.cui-xl.cui-callout-icon .cui-callout-content, .cui-callout.cui-xl.cui-callout-icon .cui-callout-header {
  padding-left: 32px;
}

.cui-callout.cui-primary {
  color: #303f9f;
  background: #e8eaf6;
  border: 1px solid #5360ad;
}

.cui-callout.cui-primary > .cui-icon {
  color: #303f9f;
}

.cui-callout.cui-positive {
  color: #388e3c;
  background: #e8f5e9;
  border: 1px solid #3d8c40;
}

.cui-callout.cui-positive > .cui-icon {
  color: #388e3c;
}

.cui-callout.cui-negative {
  color: #d32f2f;
  background: #ffebee;
  border: 1px solid #dc3c31;
}

.cui-callout.cui-negative > .cui-icon {
  color: #d32f2f;
}

.cui-callout.cui-warning {
  color: #f57c00;
  background: #fff3e0;
  border: 1px solid #dd7000;
}

.cui-callout.cui-warning > .cui-icon {
  color: #f57c00;
}

.cui-checkbox input:checked ~ .cui-control-indicator:after {
  width: 5px;
  height: 8px;
  margin-top: -1px;
}

.cui-checkbox input:indeterminate ~ .cui-control-indicator:after {
  width: 10px;
  height: 2px;
}

.cui-checkbox .cui-control-indicator {
  border: 1px solid #c5cdd1;
}

.cui-checkbox input:checked ~ .cui-control-indicator, .cui-checkbox input:indeterminate ~ .cui-control-indicator {
  background: #5c6bc0;
  border: 1px solid #404b86;
}

.cui-checkbox input:checked ~ .cui-control-indicator {
  color: #fff;
}

.cui-checkbox input:checked ~ .cui-control-indicator:after {
  content: " ";
  backface-visibility: hidden;
  border: 2px solid #fff;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.cui-checkbox input:indeterminate ~ .cui-control-indicator {
  color: #fff;
}

.cui-checkbox input:indeterminate ~ .cui-control-indicator:after {
  content: "";
  backface-visibility: hidden;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cui-checkbox.cui-xs input:checked ~ .cui-control-indicator:after {
  width: 4px;
  height: 6px;
  margin-top: -1px;
}

.cui-checkbox.cui-xs input:indeterminate ~ .cui-control-indicator:after {
  width: 10px;
  height: 2px;
}

.cui-checkbox.cui-sm input:checked ~ .cui-control-indicator:after {
  width: 4px;
  height: 7px;
  margin-top: -1px;
}

.cui-checkbox.cui-sm input:indeterminate ~ .cui-control-indicator:after {
  width: 10px;
  height: 2px;
}

.cui-checkbox.cui-lg input:checked ~ .cui-control-indicator:after {
  width: 6px;
  height: 9px;
  margin-top: -1px;
}

.cui-checkbox.cui-lg input:indeterminate ~ .cui-control-indicator:after {
  width: 10px;
  height: 2px;
}

.cui-checkbox.cui-xl input:checked ~ .cui-control-indicator:after {
  width: 6px;
  height: 10px;
  margin-top: -1px;
}

.cui-checkbox.cui-xl input:indeterminate ~ .cui-control-indicator:after {
  width: 10px;
  height: 2px;
}

.cui-checkbox.cui-primary .cui-control-indicator {
  border: 1px solid #5360ad;
}

.cui-checkbox.cui-primary input:checked ~ .cui-control-indicator, .cui-checkbox.cui-primary input:indeterminate ~ .cui-control-indicator {
  background: #5c6bc0;
  border: 1px solid #404b86;
}

.cui-checkbox.cui-positive .cui-control-indicator {
  border: 1px solid #3d8c40;
}

.cui-checkbox.cui-positive input:checked ~ .cui-control-indicator, .cui-checkbox.cui-positive input:indeterminate ~ .cui-control-indicator {
  background: #4caf50;
  border: 1px solid #357b38;
}

.cui-checkbox.cui-negative .cui-control-indicator {
  border: 1px solid #dc3c31;
}

.cui-checkbox.cui-negative input:checked ~ .cui-control-indicator, .cui-checkbox.cui-negative input:indeterminate ~ .cui-control-indicator {
  background: #f44336;
  border: 1px solid #ab2f26;
}

.cui-checkbox.cui-warning .cui-control-indicator {
  border: 1px solid #dd7000;
}

.cui-checkbox.cui-warning input:checked ~ .cui-control-indicator, .cui-checkbox.cui-warning input:indeterminate ~ .cui-control-indicator {
  background: #f57c00;
  border: 1px solid #ac5700;
}

.cui-collapse {
  height: 0;
  overflow: hidden;
}

.cui-control-group {
  vertical-align: middle;
  margin-right: 10px;
  display: inline-flex;
}

.cui-control-group:last-child {
  margin: 0;
}

.cui-control-group > :first-child {
  border-radius: 3px 0 0 3px;
  margin-right: -1px;
}

.cui-control-group > :last-child {
  border-radius: 0 3px 3px 0;
}

.cui-control-group > :only-child {
  border-radius: 3px;
  margin: 0;
}

.cui-control-group > :not(:first-child):not(:last-child) {
  border-radius: 0;
  margin-right: -1px;
}

.cui-control-group .cui-custom-select button, .cui-control-group .cui-input input, .cui-control-group .cui-select select {
  border-radius: inherit;
}

.cui-control-group .cui-active, .cui-control-group :active, .cui-control-group :focus, .cui-control-group :hover, .cui-control-group :focus {
  z-index: 1;
}

.cui-control-group.cui-fluid > * {
  flex: auto;
}

.cui-custom-select {
  display: inline-block;
}

.cui-custom-select .cui-overlay {
  position: relative;
}

.cui-custom-select .cui-popover {
  width: 100%;
  transform: none !important;
}

.cui-custom-select .cui-popover-content {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  padding: 0;
}

.cui-custom-select-trigger.cui-active {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.cui-custom-select-input {
  display: none;
}

.cui-dialog {
  z-index: 20;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.cui-dialog.cui-basic .cui-dialog-footer, .cui-dialog.cui-basic .cui-dialog-header {
  background: #fff;
  border: none;
}

.cui-dialog-content {
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  flex-direction: column;
  width: 600px;
  max-width: 97%;
  max-height: 97%;
  display: flex;
  overflow: auto;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 16px 24px -8px #435a6f4d;
}

.fade-enter .cui-dialog-content {
  opacity: .01;
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9), transform .2s cubic-bezier(.54, 1.12, .38, 1.11);
  transform: scale(.95);
}

.fade-enter-active .cui-dialog-content {
  opacity: 1;
  transform: scale(1);
}

.fade-exit .cui-dialog-content {
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9), transform .2s cubic-bezier(.54, 1.12, .38, 1.11);
}

.fade-exit-active .cui-dialog-content {
  opacity: 0;
  transform: scale(.95);
}

.cui-dialog-header {
  background: #eceff1;
  border-bottom: 1px solid #c5cdd1;
  border-radius: 4.5px 4.5px 0 0;
  flex: none;
  align-items: center;
  min-height: 40px;
  padding: 15px;
  display: flex;
}

.cui-dialog-header h3 {
  font-weight: 400;
  line-height: inherit;
  color: #37474f;
  flex: auto;
  margin-bottom: 1px;
}

.cui-dialog-close-button {
  flex: none;
}

.cui-dialog-body {
  background: #fff;
  flex: auto;
  padding: 15px;
  overflow: auto;
}

.cui-dialog-footer {
  background: #eceff1;
  border-top: 1px solid #c5cdd1;
  border-radius: 0 0 4.5px 4.5px;
  flex: none;
  padding: 15px;
}

.cui-dialog-footer .cui-button {
  margin-right: 10px;
}

.cui-dialog-footer .cui-button:last-child {
  margin: 0;
}

.cui-overlay-inline .cui-dialog {
  position: absolute;
}

.cui-drawer {
  z-index: 20;
  position: fixed;
}

.cui-drawer-left, .cui-drawer-right {
  height: 100%;
  top: 0;
}

.cui-drawer-left .cui-drawer-content, .cui-drawer-right .cui-drawer-content {
  min-width: 320px;
  max-width: 97%;
  height: 100%;
}

.cui-drawer-right {
  right: 0;
}

.fade-enter .cui-drawer-right .cui-drawer-content, .fade-exit-active .cui-drawer-right .cui-drawer-content {
  transform: translateX(100%);
}

.fade-enter-active .cui-drawer-right .cui-drawer-content {
  transform: translateX(0);
}

.cui-drawer-left {
  left: 0;
}

.fade-enter .cui-drawer-left .cui-drawer-content, .fade-exit-active .cui-drawer-left .cui-drawer-content {
  transform: translateX(-100%);
}

.fade-enter-active .cui-drawer-left .cui-drawer-content {
  transform: translateX(0);
}

.cui-drawer-bottom, .cui-drawer-top {
  width: 100%;
  left: 0;
}

.cui-drawer-bottom .cui-drawer-content, .cui-drawer-top .cui-drawer-content {
  width: 100%;
  min-height: 160px;
  max-height: 97%;
}

.cui-drawer-top {
  top: 0;
}

.fade-enter .cui-drawer-top .cui-drawer-content, .fade-exit-active .cui-drawer-top .cui-drawer-content {
  transform: translateY(-100%);
}

.fade-enter-active .cui-drawer-top .cui-drawer-content {
  transform: translateY(0);
}

.cui-drawer-bottom {
  bottom: 0;
}

.fade-enter .cui-drawer-bottom .cui-drawer-content, .fade-exit-active .cui-drawer-bottom .cui-drawer-content {
  transform: translateY(100%);
}

.fade-enter-active .cui-drawer-bottom .cui-drawer-content {
  transform: translateY(0);
}

.cui-overlay-inline .cui-drawer {
  position: absolute;
}

.cui-drawer-content {
  z-index: 20;
  background: #fff;
  border: 1px solid #c5cdd1;
  padding: 15px;
  position: relative;
  box-shadow: 0 16px 24px -8px #435a6f4d;
}

.fade-enter .cui-drawer-content {
  opacity: .01;
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9), transform .2s cubic-bezier(.4, 1, .75, .9);
}

.fade-enter-active .cui-drawer-content {
  opacity: 1;
}

.fade-exit .cui-drawer-content {
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9), transform .2s cubic-bezier(.54, 1.12, .38, 1.11);
}

.fade-exit-active .cui-drawer-content {
  opacity: 0;
}

.cui-empty-state {
  vertical-align: middle;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.cui-empty-state.cui-empty-state-fill {
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.cui-empty-state-icon .cui-icon svg {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  margin-bottom: 15px;
}

.cui-empty-state-header {
  font-size: 16px;
  font-weight: 700;
}

.cui-empty-state-header:not(:last-child) {
  margin-bottom: 10px;
}

.cui-empty-state-content {
  text-align: center;
}

.cui-form .cui-form-group {
  margin-bottom: 15px;
}

.cui-form .cui-form-group .cui-custom-select, .cui-form .cui-form-group .cui-input, .cui-form .cui-form-group .cui-input-file {
  width: 100%;
}

.cui-form .cui-form-group:last-child {
  margin: 0;
}

.cui-form .cui-form-group.cui-disabled {
  opacity: .75;
  pointer-events: none;
}

.cui-form .cui-form-label {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 700;
  display: inline-block;
}

.cui-grid {
  flex-flow: wrap;
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

.cui-grid-align-top {
  align-items: flex-start;
}

.cui-grid-align-middle {
  align-items: center;
}

.cui-grid-align-bottom {
  align-items: flex-end;
}

.cui-grid-justify-start {
  justify-content: flex-start;
}

.cui-grid-justify-end {
  justify-content: flex-end;
}

.cui-grid-justify-center {
  justify-content: center;
}

.cui-grid-justify-space-around {
  justify-content: space-around;
}

.cui-grid-justify-space-between {
  justify-content: space-between;
}

[class*="cui-col"] {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.cui-col-1 {
  flex-basis: 8.33333%;
  max-width: 8.33333%;
}

.cui-col-offset-1 {
  margin-left: 8.33333%;
}

.cui-col-order-1 {
  order: 1;
}

.cui-col-2 {
  flex-basis: 16.6667%;
  max-width: 16.6667%;
}

.cui-col-offset-2 {
  margin-left: 16.6667%;
}

.cui-col-order-2 {
  order: 2;
}

.cui-col-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.cui-col-offset-3 {
  margin-left: 25%;
}

.cui-col-order-3 {
  order: 3;
}

.cui-col-4 {
  flex-basis: 33.3333%;
  max-width: 33.3333%;
}

.cui-col-offset-4 {
  margin-left: 33.3333%;
}

.cui-col-order-4 {
  order: 4;
}

.cui-col-5 {
  flex-basis: 41.6667%;
  max-width: 41.6667%;
}

.cui-col-offset-5 {
  margin-left: 41.6667%;
}

.cui-col-order-5 {
  order: 5;
}

.cui-col-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.cui-col-offset-6 {
  margin-left: 50%;
}

.cui-col-order-6 {
  order: 6;
}

.cui-col-7 {
  flex-basis: 58.3333%;
  max-width: 58.3333%;
}

.cui-col-offset-7 {
  margin-left: 58.3333%;
}

.cui-col-order-7 {
  order: 7;
}

.cui-col-8 {
  flex-basis: 66.6667%;
  max-width: 66.6667%;
}

.cui-col-offset-8 {
  margin-left: 66.6667%;
}

.cui-col-order-8 {
  order: 8;
}

.cui-col-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.cui-col-offset-9 {
  margin-left: 75%;
}

.cui-col-order-9 {
  order: 9;
}

.cui-col-10 {
  flex-basis: 83.3333%;
  max-width: 83.3333%;
}

.cui-col-offset-10 {
  margin-left: 83.3333%;
}

.cui-col-order-10 {
  order: 10;
}

.cui-col-11 {
  flex-basis: 91.6667%;
  max-width: 91.6667%;
}

.cui-col-offset-11 {
  margin-left: 91.6667%;
}

.cui-col-order-11 {
  order: 11;
}

.cui-col-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.cui-col-offset-12 {
  margin-left: 100%;
}

.cui-col-order-12 {
  order: 12;
}

@media only screen and (min-width: 0) {
  .cui-col-xs-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }

  .cui-col-xs-offset-1 {
    margin-left: 8.33333%;
  }

  .cui-col-xs-order-1 {
    order: 1;
  }

  .cui-col-xs-2 {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }

  .cui-col-xs-offset-2 {
    margin-left: 16.6667%;
  }

  .cui-col-xs-order-2 {
    order: 2;
  }

  .cui-col-xs-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .cui-col-xs-offset-3 {
    margin-left: 25%;
  }

  .cui-col-xs-order-3 {
    order: 3;
  }

  .cui-col-xs-4 {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .cui-col-xs-offset-4 {
    margin-left: 33.3333%;
  }

  .cui-col-xs-order-4 {
    order: 4;
  }

  .cui-col-xs-5 {
    flex-basis: 41.6667%;
    max-width: 41.6667%;
  }

  .cui-col-xs-offset-5 {
    margin-left: 41.6667%;
  }

  .cui-col-xs-order-5 {
    order: 5;
  }

  .cui-col-xs-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .cui-col-xs-offset-6 {
    margin-left: 50%;
  }

  .cui-col-xs-order-6 {
    order: 6;
  }

  .cui-col-xs-7 {
    flex-basis: 58.3333%;
    max-width: 58.3333%;
  }

  .cui-col-xs-offset-7 {
    margin-left: 58.3333%;
  }

  .cui-col-xs-order-7 {
    order: 7;
  }

  .cui-col-xs-8 {
    flex-basis: 66.6667%;
    max-width: 66.6667%;
  }

  .cui-col-xs-offset-8 {
    margin-left: 66.6667%;
  }

  .cui-col-xs-order-8 {
    order: 8;
  }

  .cui-col-xs-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .cui-col-xs-offset-9 {
    margin-left: 75%;
  }

  .cui-col-xs-order-9 {
    order: 9;
  }

  .cui-col-xs-10 {
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }

  .cui-col-xs-offset-10 {
    margin-left: 83.3333%;
  }

  .cui-col-xs-order-10 {
    order: 10;
  }

  .cui-col-xs-11 {
    flex-basis: 91.6667%;
    max-width: 91.6667%;
  }

  .cui-col-xs-offset-11 {
    margin-left: 91.6667%;
  }

  .cui-col-xs-order-11 {
    order: 11;
  }

  .cui-col-xs-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .cui-col-xs-offset-12 {
    margin-left: 100%;
  }

  .cui-col-xs-order-12 {
    order: 12;
  }
}

@media only screen and (min-width: 576px) {
  .cui-col-sm-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }

  .cui-col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .cui-col-sm-order-1 {
    order: 1;
  }

  .cui-col-sm-2 {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }

  .cui-col-sm-offset-2 {
    margin-left: 16.6667%;
  }

  .cui-col-sm-order-2 {
    order: 2;
  }

  .cui-col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .cui-col-sm-offset-3 {
    margin-left: 25%;
  }

  .cui-col-sm-order-3 {
    order: 3;
  }

  .cui-col-sm-4 {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .cui-col-sm-offset-4 {
    margin-left: 33.3333%;
  }

  .cui-col-sm-order-4 {
    order: 4;
  }

  .cui-col-sm-5 {
    flex-basis: 41.6667%;
    max-width: 41.6667%;
  }

  .cui-col-sm-offset-5 {
    margin-left: 41.6667%;
  }

  .cui-col-sm-order-5 {
    order: 5;
  }

  .cui-col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .cui-col-sm-offset-6 {
    margin-left: 50%;
  }

  .cui-col-sm-order-6 {
    order: 6;
  }

  .cui-col-sm-7 {
    flex-basis: 58.3333%;
    max-width: 58.3333%;
  }

  .cui-col-sm-offset-7 {
    margin-left: 58.3333%;
  }

  .cui-col-sm-order-7 {
    order: 7;
  }

  .cui-col-sm-8 {
    flex-basis: 66.6667%;
    max-width: 66.6667%;
  }

  .cui-col-sm-offset-8 {
    margin-left: 66.6667%;
  }

  .cui-col-sm-order-8 {
    order: 8;
  }

  .cui-col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .cui-col-sm-offset-9 {
    margin-left: 75%;
  }

  .cui-col-sm-order-9 {
    order: 9;
  }

  .cui-col-sm-10 {
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }

  .cui-col-sm-offset-10 {
    margin-left: 83.3333%;
  }

  .cui-col-sm-order-10 {
    order: 10;
  }

  .cui-col-sm-11 {
    flex-basis: 91.6667%;
    max-width: 91.6667%;
  }

  .cui-col-sm-offset-11 {
    margin-left: 91.6667%;
  }

  .cui-col-sm-order-11 {
    order: 11;
  }

  .cui-col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .cui-col-sm-offset-12 {
    margin-left: 100%;
  }

  .cui-col-sm-order-12 {
    order: 12;
  }
}

@media only screen and (min-width: 768px) {
  .cui-col-md-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }

  .cui-col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .cui-col-md-order-1 {
    order: 1;
  }

  .cui-col-md-2 {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }

  .cui-col-md-offset-2 {
    margin-left: 16.6667%;
  }

  .cui-col-md-order-2 {
    order: 2;
  }

  .cui-col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .cui-col-md-offset-3 {
    margin-left: 25%;
  }

  .cui-col-md-order-3 {
    order: 3;
  }

  .cui-col-md-4 {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .cui-col-md-offset-4 {
    margin-left: 33.3333%;
  }

  .cui-col-md-order-4 {
    order: 4;
  }

  .cui-col-md-5 {
    flex-basis: 41.6667%;
    max-width: 41.6667%;
  }

  .cui-col-md-offset-5 {
    margin-left: 41.6667%;
  }

  .cui-col-md-order-5 {
    order: 5;
  }

  .cui-col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .cui-col-md-offset-6 {
    margin-left: 50%;
  }

  .cui-col-md-order-6 {
    order: 6;
  }

  .cui-col-md-7 {
    flex-basis: 58.3333%;
    max-width: 58.3333%;
  }

  .cui-col-md-offset-7 {
    margin-left: 58.3333%;
  }

  .cui-col-md-order-7 {
    order: 7;
  }

  .cui-col-md-8 {
    flex-basis: 66.6667%;
    max-width: 66.6667%;
  }

  .cui-col-md-offset-8 {
    margin-left: 66.6667%;
  }

  .cui-col-md-order-8 {
    order: 8;
  }

  .cui-col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .cui-col-md-offset-9 {
    margin-left: 75%;
  }

  .cui-col-md-order-9 {
    order: 9;
  }

  .cui-col-md-10 {
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }

  .cui-col-md-offset-10 {
    margin-left: 83.3333%;
  }

  .cui-col-md-order-10 {
    order: 10;
  }

  .cui-col-md-11 {
    flex-basis: 91.6667%;
    max-width: 91.6667%;
  }

  .cui-col-md-offset-11 {
    margin-left: 91.6667%;
  }

  .cui-col-md-order-11 {
    order: 11;
  }

  .cui-col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .cui-col-md-offset-12 {
    margin-left: 100%;
  }

  .cui-col-md-order-12 {
    order: 12;
  }
}

@media only screen and (min-width: 992px) {
  .cui-col-lg-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }

  .cui-col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .cui-col-lg-order-1 {
    order: 1;
  }

  .cui-col-lg-2 {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }

  .cui-col-lg-offset-2 {
    margin-left: 16.6667%;
  }

  .cui-col-lg-order-2 {
    order: 2;
  }

  .cui-col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .cui-col-lg-offset-3 {
    margin-left: 25%;
  }

  .cui-col-lg-order-3 {
    order: 3;
  }

  .cui-col-lg-4 {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .cui-col-lg-offset-4 {
    margin-left: 33.3333%;
  }

  .cui-col-lg-order-4 {
    order: 4;
  }

  .cui-col-lg-5 {
    flex-basis: 41.6667%;
    max-width: 41.6667%;
  }

  .cui-col-lg-offset-5 {
    margin-left: 41.6667%;
  }

  .cui-col-lg-order-5 {
    order: 5;
  }

  .cui-col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .cui-col-lg-offset-6 {
    margin-left: 50%;
  }

  .cui-col-lg-order-6 {
    order: 6;
  }

  .cui-col-lg-7 {
    flex-basis: 58.3333%;
    max-width: 58.3333%;
  }

  .cui-col-lg-offset-7 {
    margin-left: 58.3333%;
  }

  .cui-col-lg-order-7 {
    order: 7;
  }

  .cui-col-lg-8 {
    flex-basis: 66.6667%;
    max-width: 66.6667%;
  }

  .cui-col-lg-offset-8 {
    margin-left: 66.6667%;
  }

  .cui-col-lg-order-8 {
    order: 8;
  }

  .cui-col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .cui-col-lg-offset-9 {
    margin-left: 75%;
  }

  .cui-col-lg-order-9 {
    order: 9;
  }

  .cui-col-lg-10 {
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }

  .cui-col-lg-offset-10 {
    margin-left: 83.3333%;
  }

  .cui-col-lg-order-10 {
    order: 10;
  }

  .cui-col-lg-11 {
    flex-basis: 91.6667%;
    max-width: 91.6667%;
  }

  .cui-col-lg-offset-11 {
    margin-left: 91.6667%;
  }

  .cui-col-lg-order-11 {
    order: 11;
  }

  .cui-col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .cui-col-lg-offset-12 {
    margin-left: 100%;
  }

  .cui-col-lg-order-12 {
    order: 12;
  }
}

@media only screen and (min-width: 1200px) {
  .cui-col-xl-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }

  .cui-col-xl-offset-1 {
    margin-left: 8.33333%;
  }

  .cui-col-xl-order-1 {
    order: 1;
  }

  .cui-col-xl-2 {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }

  .cui-col-xl-offset-2 {
    margin-left: 16.6667%;
  }

  .cui-col-xl-order-2 {
    order: 2;
  }

  .cui-col-xl-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .cui-col-xl-offset-3 {
    margin-left: 25%;
  }

  .cui-col-xl-order-3 {
    order: 3;
  }

  .cui-col-xl-4 {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .cui-col-xl-offset-4 {
    margin-left: 33.3333%;
  }

  .cui-col-xl-order-4 {
    order: 4;
  }

  .cui-col-xl-5 {
    flex-basis: 41.6667%;
    max-width: 41.6667%;
  }

  .cui-col-xl-offset-5 {
    margin-left: 41.6667%;
  }

  .cui-col-xl-order-5 {
    order: 5;
  }

  .cui-col-xl-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .cui-col-xl-offset-6 {
    margin-left: 50%;
  }

  .cui-col-xl-order-6 {
    order: 6;
  }

  .cui-col-xl-7 {
    flex-basis: 58.3333%;
    max-width: 58.3333%;
  }

  .cui-col-xl-offset-7 {
    margin-left: 58.3333%;
  }

  .cui-col-xl-order-7 {
    order: 7;
  }

  .cui-col-xl-8 {
    flex-basis: 66.6667%;
    max-width: 66.6667%;
  }

  .cui-col-xl-offset-8 {
    margin-left: 66.6667%;
  }

  .cui-col-xl-order-8 {
    order: 8;
  }

  .cui-col-xl-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .cui-col-xl-offset-9 {
    margin-left: 75%;
  }

  .cui-col-xl-order-9 {
    order: 9;
  }

  .cui-col-xl-10 {
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }

  .cui-col-xl-offset-10 {
    margin-left: 83.3333%;
  }

  .cui-col-xl-order-10 {
    order: 10;
  }

  .cui-col-xl-11 {
    flex-basis: 91.6667%;
    max-width: 91.6667%;
  }

  .cui-col-xl-offset-11 {
    margin-left: 91.6667%;
  }

  .cui-col-xl-order-11 {
    order: 11;
  }

  .cui-col-xl-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .cui-col-xl-offset-12 {
    margin-left: 100%;
  }

  .cui-col-xl-order-12 {
    order: 12;
  }
}

.cui-icon {
  vertical-align: middle;
  color: #546e7a;
  display: inline-flex;
  position: relative;
}

.cui-icon svg {
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  display: block;
}

.cui-icon.cui-icon-action:hover {
  cursor: pointer;
  color: #263238;
}

.cui-icon.cui-xs svg, .cui-xs .cui-icon svg, .cui-icon.cui-sm svg, .cui-sm .cui-icon svg {
  width: 14px;
  min-width: 14px;
  height: 14px;
  min-height: 14px;
}

.cui-icon.cui-lg svg, .cui-lg .cui-icon svg {
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
}

.cui-icon.cui-xl svg, .cui-xl .cui-icon svg {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
}

.cui-icon.cui-primary, .cui-primary > .cui-icon {
  color: #5c6bc0;
}

.cui-icon.cui-primary.cui-icon-action:hover, .cui-primary > .cui-icon.cui-icon-action:hover {
  color: #5360ad;
}

.cui-icon.cui-positive, .cui-positive > .cui-icon {
  color: #4caf50;
}

.cui-icon.cui-positive.cui-icon-action:hover, .cui-positive > .cui-icon.cui-icon-action:hover {
  color: #449e48;
}

.cui-icon.cui-negative, .cui-negative > .cui-icon {
  color: #f44336;
}

.cui-icon.cui-negative.cui-icon-action:hover, .cui-negative > .cui-icon.cui-icon-action:hover {
  color: #dc3c31;
}

.cui-icon.cui-warning, .cui-warning > .cui-icon {
  color: #f57c00;
}

.cui-icon.cui-warning.cui-icon-action:hover, .cui-warning > .cui-icon.cui-icon-action:hover {
  color: #dd7000;
}

.cui-input {
  vertical-align: middle;
  background: #fff;
  display: inline-flex;
  position: relative;
}

.cui-input input, .cui-input textarea {
  height: 40px;
  min-height: 40px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 40px;
}

.cui-input input:not(:first-child), .cui-input textarea:not(:first-child) {
  padding-left: 36px;
}

.cui-input input:not(:last-child), .cui-input textarea:not(:last-child) {
  padding-right: 36px;
}

.cui-input > .cui-button:first-child:not(input), .cui-input > .cui-icon:first-child:not(input), .cui-input > .cui-spinner:first-child:not(input), .cui-input > .cui-tag:first-child:not(input) {
  margin-left: 12px;
}

.cui-input > .cui-button:last-child, .cui-input > .cui-icon:last-child, .cui-input > .cui-spinner:last-child, .cui-input > .cui-tag:last-child {
  margin-right: 12px;
}

.cui-input > .cui-button {
  height: 28px;
  min-height: 28px;
  padding: 0 6px;
  font-size: 11px;
  line-height: 28px;
}

.cui-input input, .cui-input textarea {
  border: 1px solid #c5cdd1;
}

.cui-input input:focus, .cui-input textarea:focus {
  border: 1px solid #5360ad;
}

.cui-input input, .cui-input textarea {
  color: #37474f;
  background: none;
  border-radius: 3px;
  outline: 0;
  width: 100%;
  transition: border .2s cubic-bezier(.4, 1, .75, .9), background .2s cubic-bezier(.4, 1, .75, .9), color .2s cubic-bezier(.4, 1, .75, .9);
  position: relative;
  box-shadow: 0 1px #eef1f2;
}

.cui-input input::placeholder, .cui-input textarea::placeholder {
  color: #b0bec5;
}

.cui-input input:focus::placeholder, .cui-input textarea:focus::placeholder {
  color: #90a4ae;
}

.cui-input input::-ms-clear {
  display: none;
}

.cui-input textarea::-ms-clear {
  display: none;
}

.cui-input > .cui-button, .cui-input > .cui-icon, .cui-input > .cui-spinner, .cui-input > .cui-tag {
  z-index: 1;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.cui-input > .cui-button:first-child, .cui-input > .cui-icon:first-child, .cui-input > .cui-spinner:first-child, .cui-input > .cui-tag:first-child {
  left: 0;
}

.cui-input > .cui-button:last-child, .cui-input > .cui-icon:last-child, .cui-input > .cui-spinner:last-child, .cui-input > .cui-tag:last-child {
  right: 0;
}

.cui-input.cui-xs input, .cui-input.cui-xs textarea {
  height: 30px;
  min-height: 30px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 30px;
}

.cui-input.cui-xs input:not(:first-child), .cui-input.cui-xs textarea:not(:first-child) {
  padding-left: 30px;
}

.cui-input.cui-xs input:not(:last-child), .cui-input.cui-xs textarea:not(:last-child) {
  padding-right: 30px;
}

.cui-input.cui-xs > .cui-button:first-child:not(input), .cui-input.cui-xs > .cui-icon:first-child:not(input), .cui-input.cui-xs > .cui-spinner:first-child:not(input), .cui-input.cui-xs > .cui-tag:first-child:not(input) {
  margin-left: 10px;
}

.cui-input.cui-xs > .cui-button:last-child, .cui-input.cui-xs > .cui-icon:last-child, .cui-input.cui-xs > .cui-spinner:last-child, .cui-input.cui-xs > .cui-tag:last-child {
  margin-right: 10px;
}

.cui-input.cui-xs > .cui-button {
  height: 21px;
  min-height: 21px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 21px;
}

.cui-input.cui-sm input, .cui-input.cui-sm textarea {
  height: 35px;
  min-height: 35px;
  padding: 0 11px;
  font-size: 13px;
  line-height: 35px;
}

.cui-input.cui-sm input:not(:first-child), .cui-input.cui-sm textarea:not(:first-child) {
  padding-left: 33px;
}

.cui-input.cui-sm input:not(:last-child), .cui-input.cui-sm textarea:not(:last-child) {
  padding-right: 33px;
}

.cui-input.cui-sm > .cui-button:first-child:not(input), .cui-input.cui-sm > .cui-icon:first-child:not(input), .cui-input.cui-sm > .cui-spinner:first-child:not(input), .cui-input.cui-sm > .cui-tag:first-child:not(input) {
  margin-left: 11px;
}

.cui-input.cui-sm > .cui-button:last-child, .cui-input.cui-sm > .cui-icon:last-child, .cui-input.cui-sm > .cui-spinner:last-child, .cui-input.cui-sm > .cui-tag:last-child {
  margin-right: 11px;
}

.cui-input.cui-sm > .cui-button {
  height: 25px;
  min-height: 25px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 25px;
}

.cui-input.cui-lg input, .cui-input.cui-lg textarea {
  height: 45px;
  min-height: 45px;
  padding: 0 14px;
  font-size: 16px;
  line-height: 45px;
}

.cui-input.cui-lg input:not(:first-child), .cui-input.cui-lg textarea:not(:first-child) {
  padding-left: 42px;
}

.cui-input.cui-lg input:not(:last-child), .cui-input.cui-lg textarea:not(:last-child) {
  padding-right: 42px;
}

.cui-input.cui-lg > .cui-button:first-child:not(input), .cui-input.cui-lg > .cui-icon:first-child:not(input), .cui-input.cui-lg > .cui-spinner:first-child:not(input), .cui-input.cui-lg > .cui-tag:first-child:not(input) {
  margin-left: 14px;
}

.cui-input.cui-lg > .cui-button:last-child, .cui-input.cui-lg > .cui-icon:last-child, .cui-input.cui-lg > .cui-spinner:last-child, .cui-input.cui-lg > .cui-tag:last-child {
  margin-right: 14px;
}

.cui-input.cui-lg > .cui-button {
  height: 32px;
  min-height: 32px;
  padding: 0 7px;
  font-size: 13px;
  line-height: 32px;
}

.cui-input.cui-xl input, .cui-input.cui-xl textarea {
  height: 50px;
  min-height: 50px;
  padding: 0 16px;
  font-size: 18px;
  line-height: 50px;
}

.cui-input.cui-xl input:not(:first-child), .cui-input.cui-xl textarea:not(:first-child) {
  padding-left: 48px;
}

.cui-input.cui-xl input:not(:last-child), .cui-input.cui-xl textarea:not(:last-child) {
  padding-right: 48px;
}

.cui-input.cui-xl > .cui-button:first-child:not(input), .cui-input.cui-xl > .cui-icon:first-child:not(input), .cui-input.cui-xl > .cui-spinner:first-child:not(input), .cui-input.cui-xl > .cui-tag:first-child:not(input) {
  margin-left: 16px;
}

.cui-input.cui-xl > .cui-button:last-child, .cui-input.cui-xl > .cui-icon:last-child, .cui-input.cui-xl > .cui-spinner:last-child, .cui-input.cui-xl > .cui-tag:last-child {
  margin-right: 16px;
}

.cui-input.cui-xl > .cui-button {
  height: 35px;
  min-height: 35px;
  padding: 0 8px;
  font-size: 15px;
  line-height: 35px;
}

.cui-input.cui-primary input, .cui-input.cui-primary textarea {
  border: 1px solid #5360ad;
}

.cui-input.cui-primary input:focus, .cui-input.cui-primary textarea:focus {
  border: 1px solid #4a569a;
}

.cui-input.cui-positive input, .cui-input.cui-positive textarea {
  border: 1px solid #3d8c40;
}

.cui-input.cui-positive input:focus, .cui-input.cui-positive textarea:focus {
  border: 1px solid #357b38;
}

.cui-input.cui-negative input, .cui-input.cui-negative textarea {
  border: 1px solid #dc3c31;
}

.cui-input.cui-negative input:focus, .cui-input.cui-negative textarea:focus {
  border: 1px solid #c3362b;
}

.cui-input.cui-warning input, .cui-input.cui-warning textarea {
  border: 1px solid #dd7000;
}

.cui-input.cui-warning input:focus, .cui-input.cui-warning textarea:focus {
  border: 1px solid #c46300;
}

.cui-input.cui-disabled .cui-button, .cui-input.cui-disabled .cui-icon, .cui-input.cui-disabled input, .cui-input.cui-disabled textarea {
  cursor: not-allowed;
  pointer-events: none;
}

.cui-input.cui-basic input, .cui-input.cui-basic input:focus, .cui-input.cui-basic textarea, .cui-input.cui-basic textarea:focus {
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.cui-input-file {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  cursor: pointer;
  background: #fff;
  min-width: 200px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.cui-input-file .cui-input-file-content {
  height: 40px;
  min-height: 40px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 40px;
}

.cui-input-file .cui-input-file-content:not(:nth-child(2)) {
  padding-left: 36px;
}

.cui-input-file .cui-input-file-content:not(:last-child) {
  padding-right: 36px;
}

.cui-input-file > .cui-button:first-child:not(input), .cui-input-file > .cui-icon:first-child:not(input), .cui-input-file > .cui-spinner:first-child:not(input), .cui-input-file > .cui-tag:first-child:not(input) {
  margin-left: 12px;
}

.cui-input-file > .cui-button:last-child, .cui-input-file > .cui-icon:last-child, .cui-input-file > .cui-spinner:last-child, .cui-input-file > .cui-tag:last-child {
  margin-right: 12px;
}

.cui-input-file > .cui-button {
  height: 28px;
  min-height: 28px;
  padding: 0 6px;
  font-size: 11px;
  line-height: 28px;
}

.cui-input-file .cui-input-file-content {
  border: 1px solid #c5cdd1;
}

.cui-input-file .cui-input-file-content:focus {
  border: 1px solid #b0bec5;
}

.cui-input-file input {
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  outline: 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cui-input-file .cui-input-file-content {
  color: #b0bec5;
  background: none;
  border-radius: 3px;
  width: 100%;
  transition: border .2s cubic-bezier(.4, 1, .75, .9), background .2s cubic-bezier(.4, 1, .75, .9), color .2s cubic-bezier(.4, 1, .75, .9);
  position: relative;
}

.cui-input-file .cui-input-file-content.cui-input-file-text {
  color: #37474f;
}

.cui-input-file .cui-input-file-button {
  pointer-events: none;
}

.cui-input-file > .cui-button, .cui-input-file > .cui-icon, .cui-input-file > .cui-spinner, .cui-input-file > .cui-tag {
  z-index: 1;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.cui-input-file > .cui-button:first-child, .cui-input-file > .cui-icon:first-child, .cui-input-file > .cui-spinner:first-child, .cui-input-file > .cui-tag:first-child {
  left: 0;
}

.cui-input-file > .cui-button:last-child, .cui-input-file > .cui-icon:last-child, .cui-input-file > .cui-spinner:last-child, .cui-input-file > .cui-tag:last-child {
  right: 0;
}

.cui-input-file.cui-xs .cui-input-file-content {
  height: 30px;
  min-height: 30px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 30px;
}

.cui-input-file.cui-xs .cui-input-file-content:not(:nth-child(2)) {
  padding-left: 30px;
}

.cui-input-file.cui-xs .cui-input-file-content:not(:last-child) {
  padding-right: 30px;
}

.cui-input-file.cui-xs > .cui-button:first-child:not(input), .cui-input-file.cui-xs > .cui-icon:first-child:not(input), .cui-input-file.cui-xs > .cui-spinner:first-child:not(input), .cui-input-file.cui-xs > .cui-tag:first-child:not(input) {
  margin-left: 10px;
}

.cui-input-file.cui-xs > .cui-button:last-child, .cui-input-file.cui-xs > .cui-icon:last-child, .cui-input-file.cui-xs > .cui-spinner:last-child, .cui-input-file.cui-xs > .cui-tag:last-child {
  margin-right: 10px;
}

.cui-input-file.cui-xs > .cui-button {
  height: 21px;
  min-height: 21px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 21px;
}

.cui-input-file.cui-sm .cui-input-file-content {
  height: 35px;
  min-height: 35px;
  padding: 0 11px;
  font-size: 13px;
  line-height: 35px;
}

.cui-input-file.cui-sm .cui-input-file-content:not(:nth-child(2)) {
  padding-left: 33px;
}

.cui-input-file.cui-sm .cui-input-file-content:not(:last-child) {
  padding-right: 33px;
}

.cui-input-file.cui-sm > .cui-button:first-child:not(input), .cui-input-file.cui-sm > .cui-icon:first-child:not(input), .cui-input-file.cui-sm > .cui-spinner:first-child:not(input), .cui-input-file.cui-sm > .cui-tag:first-child:not(input) {
  margin-left: 11px;
}

.cui-input-file.cui-sm > .cui-button:last-child, .cui-input-file.cui-sm > .cui-icon:last-child, .cui-input-file.cui-sm > .cui-spinner:last-child, .cui-input-file.cui-sm > .cui-tag:last-child {
  margin-right: 11px;
}

.cui-input-file.cui-sm > .cui-button {
  height: 25px;
  min-height: 25px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 25px;
}

.cui-input-file.cui-lg .cui-input-file-content {
  height: 45px;
  min-height: 45px;
  padding: 0 14px;
  font-size: 16px;
  line-height: 45px;
}

.cui-input-file.cui-lg .cui-input-file-content:not(:nth-child(2)) {
  padding-left: 42px;
}

.cui-input-file.cui-lg .cui-input-file-content:not(:last-child) {
  padding-right: 42px;
}

.cui-input-file.cui-lg > .cui-button:first-child:not(input), .cui-input-file.cui-lg > .cui-icon:first-child:not(input), .cui-input-file.cui-lg > .cui-spinner:first-child:not(input), .cui-input-file.cui-lg > .cui-tag:first-child:not(input) {
  margin-left: 14px;
}

.cui-input-file.cui-lg > .cui-button:last-child, .cui-input-file.cui-lg > .cui-icon:last-child, .cui-input-file.cui-lg > .cui-spinner:last-child, .cui-input-file.cui-lg > .cui-tag:last-child {
  margin-right: 14px;
}

.cui-input-file.cui-lg > .cui-button {
  height: 32px;
  min-height: 32px;
  padding: 0 7px;
  font-size: 13px;
  line-height: 32px;
}

.cui-input-file.cui-xl .cui-input-file-content {
  height: 50px;
  min-height: 50px;
  padding: 0 16px;
  font-size: 18px;
  line-height: 50px;
}

.cui-input-file.cui-xl .cui-input-file-content:not(:nth-child(2)) {
  padding-left: 48px;
}

.cui-input-file.cui-xl .cui-input-file-content:not(:last-child) {
  padding-right: 48px;
}

.cui-input-file.cui-xl > .cui-button:first-child:not(input), .cui-input-file.cui-xl > .cui-icon:first-child:not(input), .cui-input-file.cui-xl > .cui-spinner:first-child:not(input), .cui-input-file.cui-xl > .cui-tag:first-child:not(input) {
  margin-left: 16px;
}

.cui-input-file.cui-xl > .cui-button:last-child, .cui-input-file.cui-xl > .cui-icon:last-child, .cui-input-file.cui-xl > .cui-spinner:last-child, .cui-input-file.cui-xl > .cui-tag:last-child {
  margin-right: 16px;
}

.cui-input-file.cui-xl > .cui-button {
  height: 35px;
  min-height: 35px;
  padding: 0 8px;
  font-size: 15px;
  line-height: 35px;
}

.cui-input-file.cui-primary .cui-input-file-content {
  border: 1px solid #5360ad;
}

.cui-input-file.cui-primary .cui-input-file-content:focus {
  border: 1px solid #4a569a;
}

.cui-input-file.cui-positive .cui-input-file-content {
  border: 1px solid #3d8c40;
}

.cui-input-file.cui-positive .cui-input-file-content:focus {
  border: 1px solid #357b38;
}

.cui-input-file.cui-negative .cui-input-file-content {
  border: 1px solid #dc3c31;
}

.cui-input-file.cui-negative .cui-input-file-content:focus {
  border: 1px solid #c3362b;
}

.cui-input-file.cui-warning .cui-input-file-content {
  border: 1px solid #dd7000;
}

.cui-input-file.cui-warning .cui-input-file-content:focus {
  border: 1px solid #c46300;
}

.cui-input-popover .cui-input, .cui-input-popover .cui-textarea {
  margin-bottom: 20px;
}

.cui-list {
  background: #fff;
  width: 100%;
  max-height: 400px;
  position: relative;
  overflow-y: auto;
}

.cui-list .cui-list-item {
  padding: 10px;
  font-size: 14px;
}

.cui-list .cui-list-item-content-left {
  padding-right: 10px;
}

.cui-list .cui-list-item-content-right {
  padding-left: 10px;
}

.cui-list .cui-list-item:hover {
  cursor: default;
  background: none;
}

.cui-list.cui-interactive .cui-list-item:hover {
  cursor: pointer;
  background: #f1f3f5;
}

.cui-list-item {
  color: #37474f;
  cursor: pointer;
  border-bottom: 1px solid #c5cdd1;
  align-items: center;
  font-weight: 400;
  display: flex;
  position: relative;
}

.cui-list-item.cui-active, .cui-list-item:hover {
  background: #f1f3f5;
}

.cui-list-item.cui-selected {
  color: #5c6bc0;
  font-weight: 700;
}

.cui-list-item.cui-disabled {
  opacity: .5 !important;
}

.cui-list-item.cui-disabled:hover {
  background: none;
}

.cui-list-item:last-child {
  border-bottom: none;
}

.cui-list-item-content-left .cui-icon {
  display: block;
}

.cui-list-item-content-right {
  margin-left: auto;
}

.cui-list.cui-xs .cui-list-item {
  padding: 8px;
  font-size: 12px;
}

.cui-list.cui-xs .cui-list-item-content-left {
  padding-right: 8px;
}

.cui-list.cui-xs .cui-list-item-content-right {
  padding-left: 8px;
}

.cui-list.cui-sm .cui-list-item {
  padding: 9px;
  font-size: 13px;
}

.cui-list.cui-sm .cui-list-item-content-left {
  padding-right: 9px;
}

.cui-list.cui-sm .cui-list-item-content-right {
  padding-left: 9px;
}

.cui-list.cui-lg .cui-list-item {
  padding: 11px;
  font-size: 16px;
}

.cui-list.cui-lg .cui-list-item-content-left {
  padding-right: 11px;
}

.cui-list.cui-lg .cui-list-item-content-right {
  padding-left: 11px;
}

.cui-list.cui-xl .cui-list-item {
  padding: 13px;
  font-size: 18px;
}

.cui-list.cui-xl .cui-list-item-content-left {
  padding-right: 13px;
}

.cui-list.cui-xl .cui-list-item-content-right {
  padding-left: 13px;
}

.cui-menu {
  vertical-align: middle;
  background: #fff;
  border: 1px solid #c5cdd1;
  border-radius: 3px;
  flex-direction: column;
  min-width: 140px;
  max-width: 180px;
  padding: 6px 0;
  display: inline-flex;
}

.cui-menu .cui-menu-divider {
  margin: 6px 0;
}

.cui-menu .cui-menu-heading {
  padding: 6px 12px;
}

.cui-menu .cui-menu-item {
  border-radius: 0;
}

.cui-menu .cui-menu-divider {
  border-bottom: 1px solid #c5cdd1;
}

.cui-menu .cui-menu-heading {
  color: #263238;
  font-size: 12px;
  font-weight: 700;
}

.cui-menu.cui-basic {
  border: none;
}

.cui-menu.cui-xs {
  min-width: 100px;
  max-width: 140px;
  padding: 5px 0;
}

.cui-menu.cui-xs .cui-menu-divider {
  margin: 5px 0;
}

.cui-menu.cui-xs .cui-menu-heading {
  padding: 5px 10px;
}

.cui-menu.cui-sm {
  min-width: 120px;
  max-width: 160px;
  padding: 5px 0;
}

.cui-menu.cui-sm .cui-menu-divider {
  margin: 5px 0;
}

.cui-menu.cui-sm .cui-menu-heading {
  padding: 5px 11px;
}

.cui-menu.cui-lg {
  min-width: 160px;
  max-width: 200px;
  padding: 7px 0;
}

.cui-menu.cui-lg .cui-menu-divider {
  margin: 7px 0;
}

.cui-menu.cui-lg .cui-menu-heading {
  padding: 7px 14px;
}

.cui-menu.cui-xl {
  min-width: 200px;
  max-width: 240px;
  padding: 8px 0;
}

.cui-menu.cui-xl .cui-menu-divider {
  margin: 8px 0;
}

.cui-menu.cui-xl .cui-menu-heading {
  padding: 8px 16px;
}

.cui-overlay-backdrop {
  z-index: 20;
  background-color: #10161ab3;
  position: fixed;
  inset: 0;
}

.fade-enter .cui-overlay-backdrop {
  opacity: .01;
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9);
}

.fade-enter-active .cui-overlay-backdrop {
  opacity: 1;
}

.fade-exit .cui-overlay-backdrop {
  opacity: .01;
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9);
}

.fade-exit-active .cui-overlay-backdrop {
  opacity: 0;
}

body.cui-overlay-open {
  overflow-y: hidden;
}

.cui-overlay-inline .cui-overlay-backdrop {
  position: absolute;
}

.cui-popover {
  z-index: 20;
  position: absolute;
  left: -9999px;
}

.cui-popover.fade-enter .cui-popover, .fade-enter .cui-popover {
  opacity: .01;
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9);
}

.fade-enter-active .cui-popover {
  opacity: 1;
}

.fade-exit .cui-popover {
  opacity: .01;
  transition: opacity .2s cubic-bezier(.4, 1, .75, .9);
}

.fade-exit-active .cui-popover {
  opacity: 0;
}

.cui-popover[x-out-of-boundaries] {
  opacity: 0;
  visibility: hidden;
}

.cui-popover[x-placement^="top"] > .cui-popover-arrow {
  border-bottom: 1px solid #bbc3c7;
  border-left: 1px solid #bbc3c7;
  bottom: -7px;
}

.cui-popover[x-placement^="bottom"] > .cui-popover-arrow {
  border-top: 1px solid #bbc3c7;
  border-right: 1px solid #bbc3c7;
  top: -7px;
}

.cui-popover[x-placement^="right"] > .cui-popover-arrow {
  border-top: 1px solid #bbc3c7;
  border-left: 1px solid #bbc3c7;
  left: -7px;
}

.cui-popover[x-placement^="left"] > .cui-popover-arrow {
  border-bottom: 1px solid #bbc3c7;
  border-right: 1px solid #bbc3c7;
  right: -7px;
}

.cui-popover-content {
  background: #fff;
  border: 1px solid #c5cdd1;
  border-radius: 3px;
  padding: 15px;
  display: inline-block;
  box-shadow: 0 16px 24px -8px #435a6f4d;
}

.cui-popover-arrow {
  pointer-events: none;
  background: #fff;
  width: 14px;
  height: 14px;
  position: absolute;
  transform: rotate(-45deg);
}

.cui-popover-backdrop {
  background: #ffffffa6;
}

.cui-popover-menu .cui-popover-content {
  border: none;
  padding: 0;
}

.cui-portal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.cui-query-list {
  outline: 0;
}

.cui-query-list .cui-control-group {
  margin: 0 0 15px;
}

.cui-query-list .cui-control-group.cui-fluid > * {
  flex: none;
}

.cui-query-list .cui-control-group .cui-input {
  flex-grow: 1;
}

.cui-query-list .cui-list-item {
  border-bottom: none;
}

.cui-query-list-checkmark .cui-list-item {
  padding-left: 36px;
}

.cui-query-list-checkmark .cui-list-item-content-left {
  position: absolute;
  left: 10px;
}

.cui-query-list-checkmark .cui-selected .cui-icon-check {
  color: #5c6bc0;
}

.cui-query-list-checkmark .cui-xs .cui-list-item {
  padding-left: 30px;
}

.cui-query-list-checkmark .cui-xs .cui-list-item-content-left {
  left: 8px;
}

.cui-query-list-checkmark .cui-sm .cui-list-item {
  padding-left: 33px;
}

.cui-query-list-checkmark .cui-sm .cui-list-item-content-left {
  left: 9px;
}

.cui-query-list-checkmark .cui-lg .cui-list-item {
  padding-left: 42px;
}

.cui-query-list-checkmark .cui-lg .cui-list-item-content-left {
  left: 11px;
}

.cui-query-list-checkmark .cui-xl .cui-list-item {
  padding-left: 48px;
}

.cui-query-list-checkmark .cui-xl .cui-list-item-content-left {
  left: 13px;
}

.cui-query-list-empty, .cui-query-list-initial {
  background: none;
}

.cui-query-list-empty .cui-query-list-message, .cui-query-list-initial .cui-query-list-message {
  color: #607d8b;
  padding: 10px;
  font-size: 14px;
}

.cui-radio input:checked ~ .cui-control-indicator:after {
  width: 8px;
  height: 8px;
}

.cui-radio .cui-control-indicator {
  border: 1px solid #c5cdd1;
}

.cui-radio input:checked ~ .cui-control-indicator {
  border: 1px solid #5c6bc0;
}

.cui-radio input:checked ~ .cui-control-indicator:after {
  background: #5c6bc0;
}

.cui-radio .cui-control-indicator {
  border-radius: 50%;
}

.cui-radio input:checked ~ .cui-control-indicator {
  color: #fff;
  background: #fff;
}

.cui-radio input:checked ~ .cui-control-indicator:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cui-radio.cui-xs input:checked ~ .cui-control-indicator:after {
  width: 6px;
  height: 6px;
}

.cui-radio.cui-sm input:checked ~ .cui-control-indicator:after {
  width: 7px;
  height: 7px;
}

.cui-radio.cui-lg input:checked ~ .cui-control-indicator:after {
  width: 9px;
  height: 9px;
}

.cui-radio.cui-xl input:checked ~ .cui-control-indicator:after {
  width: 10px;
  height: 10px;
}

.cui-radio.cui-primary .cui-control-indicator {
  border: 1px solid #5360ad;
}

.cui-radio.cui-primary input:checked ~ .cui-control-indicator {
  border: 1px solid #5c6bc0;
}

.cui-radio.cui-primary input:checked ~ .cui-control-indicator:after {
  background: #5c6bc0;
}

.cui-radio.cui-positive .cui-control-indicator {
  border: 1px solid #3d8c40;
}

.cui-radio.cui-positive input:checked ~ .cui-control-indicator {
  border: 1px solid #4caf50;
}

.cui-radio.cui-positive input:checked ~ .cui-control-indicator:after {
  background: #4caf50;
}

.cui-radio.cui-negative .cui-control-indicator {
  border: 1px solid #dc3c31;
}

.cui-radio.cui-negative input:checked ~ .cui-control-indicator {
  border: 1px solid #f44336;
}

.cui-radio.cui-negative input:checked ~ .cui-control-indicator:after {
  background: #f44336;
}

.cui-radio.cui-warning .cui-control-indicator {
  border: 1px solid #dd7000;
}

.cui-radio.cui-warning input:checked ~ .cui-control-indicator {
  border: 1px solid #f57c00;
}

.cui-radio.cui-warning input:checked ~ .cui-control-indicator:after {
  background: #f57c00;
}

.cui-radio-group .cui-radio {
  margin-right: 24px;
}

.cui-radio-group.cui-xs .cui-control {
  min-height: 12px;
  padding-left: 18px;
  font-size: 12px;
  line-height: 12px;
}

.cui-radio-group.cui-xs .cui-control .cui-control-indicator {
  width: 12px;
  height: 12px;
}

.cui-radio-group.cui-xs .cui-control input:checked ~ .cui-control-indicator:after {
  width: 6px;
  height: 6px;
}

.cui-radio-group.cui-xs .cui-radio {
  margin-right: 18px;
}

.cui-radio-group.cui-sm .cui-control {
  min-height: 14px;
  padding-left: 21px;
  font-size: 13px;
  line-height: 14px;
}

.cui-radio-group.cui-sm .cui-control .cui-control-indicator {
  width: 14px;
  height: 14px;
}

.cui-radio-group.cui-sm .cui-control input:checked ~ .cui-control-indicator:after {
  width: 7px;
  height: 7px;
}

.cui-radio-group.cui-sm .cui-radio {
  margin-right: 21px;
}

.cui-radio-group.cui-lg .cui-control {
  min-height: 18px;
  padding-left: 27px;
  font-size: 16px;
  line-height: 18px;
}

.cui-radio-group.cui-lg .cui-control .cui-control-indicator {
  width: 18px;
  height: 18px;
}

.cui-radio-group.cui-lg .cui-control input:checked ~ .cui-control-indicator:after {
  width: 9px;
  height: 9px;
}

.cui-radio-group.cui-lg .cui-radio {
  margin-right: 27px;
}

.cui-radio-group.cui-xl .cui-control {
  min-height: 20px;
  padding-left: 30px;
  font-size: 18px;
  line-height: 20px;
}

.cui-radio-group.cui-xl .cui-control .cui-control-indicator {
  width: 20px;
  height: 20px;
}

.cui-radio-group.cui-xl .cui-control input:checked ~ .cui-control-indicator:after {
  width: 10px;
  height: 10px;
}

.cui-radio-group.cui-xl .cui-radio {
  margin-right: 30px;
}

.cui-select {
  vertical-align: middle;
  background: #fff;
  display: inline-flex;
  position: relative;
}

.cui-select select {
  height: 40px;
  min-height: 40px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 40px;
}

.cui-select select:not(:first-child) {
  padding-left: 36px;
}

.cui-select select:not(:last-child) {
  padding-right: 36px;
}

.cui-select > .cui-button:first-child:not(input), .cui-select > .cui-icon:first-child:not(input), .cui-select > .cui-spinner:first-child:not(input), .cui-select > .cui-tag:first-child:not(input) {
  margin-left: 12px;
}

.cui-select > .cui-button:last-child, .cui-select > .cui-icon:last-child, .cui-select > .cui-spinner:last-child, .cui-select > .cui-tag:last-child {
  margin-right: 12px;
}

.cui-select > .cui-button {
  height: 28px;
  min-height: 28px;
  padding: 0 6px;
  font-size: 11px;
  line-height: 28px;
}

.cui-select select {
  color: #607d8b;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #c5cdd1;
  box-shadow: 0 1px #eef1f2;
}

.cui-select select:hover {
  color: #546e7a;
  background: linear-gradient(#fff 10.5%, #eef1f2 100%);
  border: 1px solid #b0bec5;
}

.cui-select select.cui-active, .cui-select select:active {
  background: linear-gradient(#eff1f3 10.5% 100%);
  border: 1px solid #b0bec5;
  box-shadow: 0 1px #eef1f2, inset 0 4px 5px #dadddf;
}

.cui-select select .cui-disabled, .cui-select select[disabled] {
  color: #607d8b;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #c5cdd1;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-basic select:hover {
  color: #546e7a;
  background: #f6f7f8;
  border: none;
}

.cui-select.cui-basic select:active {
  background: linear-gradient(#eff1f3 10.5% 100%);
}

.cui-select .cui-icon {
  color: #607d8b;
}

.cui-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  border-radius: 3px;
  width: 100%;
  transition: background .2s cubic-bezier(.4, 1, .75, .9), border .2s cubic-bezier(.4, 1, .75, .9), box-shadow .2s cubic-bezier(.4, 1, .75, .9);
  position: relative;
}

.cui-select select::-ms-expand {
  display: none;
}

.cui-select .cui-icon {
  pointer-events: none;
}

.cui-select > .cui-button, .cui-select > .cui-icon, .cui-select > .cui-spinner, .cui-select > .cui-tag {
  z-index: 1;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.cui-select > .cui-button:first-child, .cui-select > .cui-icon:first-child, .cui-select > .cui-spinner:first-child, .cui-select > .cui-tag:first-child {
  left: 0;
}

.cui-select > .cui-button:last-child, .cui-select > .cui-icon:last-child, .cui-select > .cui-spinner:last-child, .cui-select > .cui-tag:last-child {
  right: 0;
}

.cui-select.cui-disabled .cui-button, .cui-select.cui-disabled .cui-icon, .cui-select.cui-disabled .cui-spinner, .cui-select.cui-disabled .cui-tag, .cui-select.cui-disabled select {
  cursor: not-allowed;
  pointer-events: none;
}

.cui-select.cui-xs select {
  height: 30px;
  min-height: 30px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 30px;
}

.cui-select.cui-xs select:not(:first-child) {
  padding-left: 30px;
}

.cui-select.cui-xs select:not(:last-child) {
  padding-right: 30px;
}

.cui-select.cui-xs > .cui-button:first-child:not(input), .cui-select.cui-xs > .cui-icon:first-child:not(input), .cui-select.cui-xs > .cui-spinner:first-child:not(input), .cui-select.cui-xs > .cui-tag:first-child:not(input) {
  margin-left: 10px;
}

.cui-select.cui-xs > .cui-button:last-child, .cui-select.cui-xs > .cui-icon:last-child, .cui-select.cui-xs > .cui-spinner:last-child, .cui-select.cui-xs > .cui-tag:last-child {
  margin-right: 10px;
}

.cui-select.cui-xs > .cui-button {
  height: 21px;
  min-height: 21px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 21px;
}

.cui-select.cui-sm select {
  height: 35px;
  min-height: 35px;
  padding: 0 11px;
  font-size: 13px;
  line-height: 35px;
}

.cui-select.cui-sm select:not(:first-child) {
  padding-left: 33px;
}

.cui-select.cui-sm select:not(:last-child) {
  padding-right: 33px;
}

.cui-select.cui-sm > .cui-button:first-child:not(input), .cui-select.cui-sm > .cui-icon:first-child:not(input), .cui-select.cui-sm > .cui-spinner:first-child:not(input), .cui-select.cui-sm > .cui-tag:first-child:not(input) {
  margin-left: 11px;
}

.cui-select.cui-sm > .cui-button:last-child, .cui-select.cui-sm > .cui-icon:last-child, .cui-select.cui-sm > .cui-spinner:last-child, .cui-select.cui-sm > .cui-tag:last-child {
  margin-right: 11px;
}

.cui-select.cui-sm > .cui-button {
  height: 25px;
  min-height: 25px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 25px;
}

.cui-select.cui-lg select {
  height: 45px;
  min-height: 45px;
  padding: 0 14px;
  font-size: 16px;
  line-height: 45px;
}

.cui-select.cui-lg select:not(:first-child) {
  padding-left: 42px;
}

.cui-select.cui-lg select:not(:last-child) {
  padding-right: 42px;
}

.cui-select.cui-lg > .cui-button:first-child:not(input), .cui-select.cui-lg > .cui-icon:first-child:not(input), .cui-select.cui-lg > .cui-spinner:first-child:not(input), .cui-select.cui-lg > .cui-tag:first-child:not(input) {
  margin-left: 14px;
}

.cui-select.cui-lg > .cui-button:last-child, .cui-select.cui-lg > .cui-icon:last-child, .cui-select.cui-lg > .cui-spinner:last-child, .cui-select.cui-lg > .cui-tag:last-child {
  margin-right: 14px;
}

.cui-select.cui-lg > .cui-button {
  height: 32px;
  min-height: 32px;
  padding: 0 7px;
  font-size: 13px;
  line-height: 32px;
}

.cui-select.cui-xl select {
  height: 50px;
  min-height: 50px;
  padding: 0 16px;
  font-size: 18px;
  line-height: 50px;
}

.cui-select.cui-xl select:not(:first-child) {
  padding-left: 48px;
}

.cui-select.cui-xl select:not(:last-child) {
  padding-right: 48px;
}

.cui-select.cui-xl > .cui-button:first-child:not(input), .cui-select.cui-xl > .cui-icon:first-child:not(input), .cui-select.cui-xl > .cui-spinner:first-child:not(input), .cui-select.cui-xl > .cui-tag:first-child:not(input) {
  margin-left: 16px;
}

.cui-select.cui-xl > .cui-button:last-child, .cui-select.cui-xl > .cui-icon:last-child, .cui-select.cui-xl > .cui-spinner:last-child, .cui-select.cui-xl > .cui-tag:last-child {
  margin-right: 16px;
}

.cui-select.cui-xl > .cui-button {
  height: 35px;
  min-height: 35px;
  padding: 0 8px;
  font-size: 15px;
  line-height: 35px;
}

.cui-select.cui-primary select {
  color: #5c6bc0;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #5360ad;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-primary select:hover {
  color: #5c6bc0;
  background: linear-gradient(#fff 10.5%, #eef1f2 100%);
  border: 1px solid #4a569a;
}

.cui-select.cui-primary select.cui-active, .cui-select.cui-primary select:active {
  background: linear-gradient(#eff1f3 10.5% 100%);
  border: 1px solid #404b86;
  box-shadow: 0 1px #eef1f2, inset 0 4px 5px #dadddf;
}

.cui-select.cui-primary select .cui-disabled, .cui-select.cui-primary select[disabled] {
  color: #5c6bc0;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #5360ad;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-primary.cui-basic select:hover {
  color: #5c6bc0;
  background: #eff0f9;
  border: none;
}

.cui-select.cui-primary.cui-basic select:active {
  background: #dee1f2;
}

.cui-select.cui-primary .cui-icon {
  color: #5c6bc0;
}

.cui-select.cui-positive select {
  color: #4caf50;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #3d8c40;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-positive select:hover {
  color: #4caf50;
  background: linear-gradient(#fff 10.5%, #eef1f2 100%);
  border: 1px solid #357b38;
}

.cui-select.cui-positive select.cui-active, .cui-select.cui-positive select:active {
  background: linear-gradient(#eff1f3 10.5% 100%);
  border: 1px solid #357b38;
  box-shadow: 0 1px #eef1f2, inset 0 4px 5px #dadddf;
}

.cui-select.cui-positive select .cui-disabled, .cui-select.cui-positive select[disabled] {
  color: #4caf50;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #3d8c40;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-positive.cui-basic select:hover {
  color: #4caf50;
  background: #edf7ee;
  border: none;
}

.cui-select.cui-positive.cui-basic select:active {
  background: #dbefdc;
}

.cui-select.cui-positive .cui-icon {
  color: #4caf50;
}

.cui-select.cui-negative select {
  color: #f44336;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #dc3c31;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-negative select:hover {
  color: #f44336;
  background: linear-gradient(#fff 10.5%, #eef1f2 100%);
  border: 1px solid #c3362b;
}

.cui-select.cui-negative select.cui-active, .cui-select.cui-negative select:active {
  background: linear-gradient(#eff1f3 10.5% 100%);
  border: 1px solid #ab2f26;
  box-shadow: 0 1px #eef1f2, inset 0 4px 5px #dadddf;
}

.cui-select.cui-negative select .cui-disabled, .cui-select.cui-negative select[disabled] {
  color: #f44336;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #dc3c31;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-negative.cui-basic select:hover {
  color: #f44336;
  background: #feeceb;
  border: none;
}

.cui-select.cui-negative.cui-basic select:active {
  background: #fdd9d7;
}

.cui-select.cui-negative .cui-icon {
  color: #f44336;
}

.cui-select.cui-warning select {
  color: #f57c00;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #dd7000;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-warning select:hover {
  color: #f57c00;
  background: linear-gradient(#fff 10.5%, #eef1f2 100%);
  border: 1px solid #c46300;
}

.cui-select.cui-warning select.cui-active, .cui-select.cui-warning select:active {
  background: linear-gradient(#eff1f3 10.5% 100%);
  border: 1px solid #ac5700;
  box-shadow: 0 1px #eef1f2, inset 0 4px 5px #dadddf;
}

.cui-select.cui-warning select .cui-disabled, .cui-select.cui-warning select[disabled] {
  color: #f57c00;
  background: linear-gradient(#fff 10.5%, #f4f6f7 100%);
  border: 1px solid #dd7000;
  box-shadow: 0 1px #eef1f2;
}

.cui-select.cui-warning.cui-basic select:hover {
  color: #f57c00;
  background: #fef2e6;
  border: none;
}

.cui-select.cui-warning.cui-basic select:active {
  background: #fde5cc;
}

.cui-select.cui-warning .cui-icon {
  color: #f57c00;
}

.cui-select.cui-basic select {
  box-shadow: none;
  background: none;
  border: none;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cui-spinner {
  vertical-align: middle;
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
  will-change: opacity;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease-in-out, visibility 0s ease-in-out .3s, background .3s ease-in-out;
  display: inline-flex;
  position: relative;
}

.cui-spinner .cui-spinner-icon {
  width: 20px;
  height: 20px;
}

.cui-spinner .cui-spinner-message {
  font-size: 14px;
}

.cui-spinner .cui-spinner-icon {
  border-top-color: #5c6bc0 !important;
}

.cui-spinner.cui-spinner-active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.cui-spinner.cui-spinner-fill {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.cui-spinner.cui-spinner-bg {
  background: #ffffff80;
}

.cui-spinner.cui-xs .cui-spinner-icon, .cui-xs .cui-spinner .cui-spinner-icon {
  width: 15px;
  height: 15px;
}

.cui-spinner.cui-xs .cui-spinner-message, .cui-xs .cui-spinner .cui-spinner-message {
  font-size: 12px;
}

.cui-sm .cui-spinner .cui-spinner-icon, .cui-spinner.cui-sm .cui-spinner-icon {
  width: 17px;
  height: 17px;
}

.cui-sm .cui-spinner .cui-spinner-message, .cui-spinner.cui-sm .cui-spinner-message {
  font-size: 13px;
}

.cui-lg .cui-spinner .cui-spinner-icon, .cui-spinner.cui-lg .cui-spinner-icon {
  width: 22px;
  height: 22px;
}

.cui-lg .cui-spinner .cui-spinner-message, .cui-spinner.cui-lg .cui-spinner-message {
  font-size: 16px;
}

.cui-spinner.cui-xl .cui-spinner-icon, .cui-xl .cui-spinner .cui-spinner-icon {
  width: 25px;
  height: 25px;
}

.cui-spinner.cui-xl .cui-spinner-message, .cui-xl .cui-spinner .cui-spinner-message {
  font-size: 18px;
}

.cui-primary .cui-spinner .cui-spinner-icon, .cui-spinner.cui-primary .cui-spinner-icon {
  border-top-color: #5c6bc0 !important;
}

.cui-positive .cui-spinner .cui-spinner-icon, .cui-spinner.cui-positive .cui-spinner-icon {
  border-top-color: #4caf50 !important;
}

.cui-negative .cui-spinner .cui-spinner-icon, .cui-spinner.cui-negative .cui-spinner-icon {
  border-top-color: #f44336 !important;
}

.cui-spinner.cui-warning .cui-spinner-icon, .cui-warning .cui-spinner .cui-spinner-icon {
  border-top-color: #f57c00 !important;
}

.cui-spinner-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cui-spinner-icon {
  z-index: 1;
  border: 2px solid #c5cdd1;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: .5s linear infinite loading;
  border-top-color: red !important;
}

.cui-spinner-message {
  color: #607d8b;
  margin-top: 10px;
}

.cui-switch {
  padding-left: 40px;
}

.cui-switch .cui-control-indicator {
  width: 32px;
  height: 16px;
}

.cui-switch .cui-control-indicator:after {
  width: 16px;
  height: 16px;
}

.cui-switch input:checked ~ .cui-control-indicator:after {
  left: 16px;
}

.cui-switch .cui-control-indicator {
  background: #eceff1;
}

.cui-switch:hover .cui-control-indicator {
  background: #d4d7d9;
  border: none;
}

.cui-switch input:checked ~ .cui-control-indicator {
  background: #5c6bc0;
}

.cui-switch .cui-control-indicator {
  border: none;
  border-radius: 40px;
}

.cui-switch .cui-control-indicator:after {
  content: "";
  background: #fff;
  border: 1px solid #c5cdd1;
  border-radius: 40px;
  transition: left .2s cubic-bezier(.4, 1, .75, .9);
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.cui-switch:hover .cui-control-indicator {
  border: none;
}

.cui-switch.cui-xs {
  padding-left: 30px;
}

.cui-switch.cui-xs .cui-control-indicator {
  width: 24px;
  height: 12px;
}

.cui-switch.cui-xs .cui-control-indicator:after {
  width: 12px;
  height: 12px;
}

.cui-switch.cui-xs input:checked ~ .cui-control-indicator:after {
  left: 12px;
}

.cui-switch.cui-sm {
  padding-left: 35px;
}

.cui-switch.cui-sm .cui-control-indicator {
  width: 28px;
  height: 14px;
}

.cui-switch.cui-sm .cui-control-indicator:after {
  width: 14px;
  height: 14px;
}

.cui-switch.cui-sm input:checked ~ .cui-control-indicator:after {
  left: 14px;
}

.cui-switch.cui-lg {
  padding-left: 45px;
}

.cui-switch.cui-lg .cui-control-indicator {
  width: 36px;
  height: 18px;
}

.cui-switch.cui-lg .cui-control-indicator:after {
  width: 18px;
  height: 18px;
}

.cui-switch.cui-lg input:checked ~ .cui-control-indicator:after {
  left: 18px;
}

.cui-switch.cui-xl {
  padding-left: 50px;
}

.cui-switch.cui-xl .cui-control-indicator {
  width: 40px;
  height: 20px;
}

.cui-switch.cui-xl .cui-control-indicator:after {
  width: 20px;
  height: 20px;
}

.cui-switch.cui-xl input:checked ~ .cui-control-indicator:after {
  left: 20px;
}

.cui-switch.cui-primary .cui-control-indicator {
  background: #aeb5e0;
}

.cui-switch.cui-primary:hover .cui-control-indicator {
  background: #8d97d3;
  border: none;
}

.cui-switch.cui-primary input:checked ~ .cui-control-indicator {
  background: #5c6bc0;
}

.cui-switch.cui-positive .cui-control-indicator {
  background: #a6d7a8;
}

.cui-switch.cui-positive:hover .cui-control-indicator {
  background: #82c785;
  border: none;
}

.cui-switch.cui-positive input:checked ~ .cui-control-indicator {
  background: #4caf50;
}

.cui-switch.cui-negative .cui-control-indicator {
  background: #faa19b;
}

.cui-switch.cui-negative:hover .cui-control-indicator {
  background: #f77b72;
  border: none;
}

.cui-switch.cui-negative input:checked ~ .cui-control-indicator {
  background: #f44336;
}

.cui-switch.cui-warning .cui-control-indicator {
  background: #fabe80;
}

.cui-switch.cui-warning:hover .cui-control-indicator {
  background: #f8a34d;
  border: none;
}

.cui-switch.cui-warning input:checked ~ .cui-control-indicator {
  background: #f57c00;
}

.cui-tabs {
  justify-content: center;
  align-items: center;
  display: flex;
}

.cui-tabs .cui-tabs-item {
  padding: 12px;
  font-size: 14px;
}

.cui-tabs.cui-tabs-bordered {
  border-bottom: 1px solid #c5cdd1;
}

.cui-tabs.cui-align-left {
  justify-content: flex-start;
}

.cui-tabs.cui-align-right {
  justify-content: flex-end;
}

.cui-tabs.cui-xs .cui-tabs-item {
  padding: 10px;
  font-size: 12px;
}

.cui-tabs.cui-sm .cui-tabs-item {
  padding: 11px;
  font-size: 13px;
}

.cui-tabs.cui-lg .cui-tabs-item {
  padding: 14px;
  font-size: 16px;
}

.cui-tabs.cui-xl .cui-tabs-item {
  padding: 16px;
  font-size: 18px;
}

.cui-tabs-item {
  color: #90a4ae;
  cursor: pointer;
  border-bottom: 2px solid #0000;
  align-items: center;
  font-weight: 700;
  transition: color .2s cubic-bezier(.4, 1, .75, .9), border .2s cubic-bezier(.4, 1, .75, .9);
  display: flex;
  position: relative;
}

.cui-tabs-item .cui-icon {
  color: #90a4ae;
}

.cui-tabs-item:hover, .cui-tabs-item:hover .cui-icon {
  color: #607d8b;
}

.cui-tabs-item.cui-active {
  color: #5c6bc0;
  border-bottom: 2px solid #5c6bc0;
}

.cui-tabs-item.cui-active .cui-icon {
  color: #5c6bc0;
}

.cui-tabs-item.cui-loading {
  pointer-events: none;
  visibility: hidden;
}

.cui-table {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
}

.cui-table tbody {
  width: 100%;
}

.cui-table td, .cui-table th {
  text-align: left;
  border-bottom: 1px solid #c5cdd1;
  padding: 10px;
  font-size: 14px;
}

.cui-table:not(.cui-table-bordered) tr:last-child td, .cui-table:not(.cui-table-bordered) tr:last-child th {
  border-bottom: none;
}

.cui-table.cui-table-bordered td, .cui-table.cui-table-bordered th {
  border: 1px solid #c5cdd1;
}

.cui-table.cui-table-striped tr:nth-of-type(odd) {
  background: #f1f3f5;
}

.cui-table.cui-table-interactive tr {
  cursor: pointer;
}

.cui-table.cui-table-interactive tr:hover {
  background: #f1f3f5;
}

.cui-table.cui-table-interactive tr:active {
  background: #eceff1;
}

.cui-tag {
  color: #607d8b;
  vertical-align: middle;
  background: #f1f3f5;
  border: 1px solid #d4dce0;
  border-radius: 3px;
  align-items: center;
  margin-right: 8px;
  padding: 2px 8px;
  font-size: 14px;
  display: inline-flex;
  position: relative;
}

.cui-tag.cui-tag-removable .cui-icon {
  margin-left: 8px;
}

.cui-tag.cui-rounded {
  border-radius: 30px;
}

.cui-tag .cui-icon {
  color: inherit;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.cui-tag.cui-negative .cui-icon, .cui-tag.cui-positive .cui-icon, .cui-tag.cui-primary .cui-icon, .cui-tag.cui-warning .cui-icon {
  color: #fff;
}

.cui-tag.cui-negative .cui-icon:focus, .cui-tag.cui-negative .cui-icon:hover, .cui-tag.cui-positive .cui-icon:focus, .cui-tag.cui-positive .cui-icon:hover, .cui-tag.cui-primary .cui-icon:focus, .cui-tag.cui-primary .cui-icon:hover, .cui-tag.cui-warning .cui-icon:focus, .cui-tag.cui-warning .cui-icon:hover {
  color: #eceff1;
}

.cui-tag.cui-xs, .cui-xs .cui-tag {
  margin-right: 6px;
  padding: 1px 6px;
  font-size: 12px;
}

.cui-tag.cui-xs.cui-tag-removable .cui-icon, .cui-xs .cui-tag.cui-tag-removable .cui-icon {
  margin-left: 6px;
}

.cui-sm .cui-tag, .cui-tag.cui-sm {
  margin-right: 7px;
  padding: 1px 7px;
  font-size: 13px;
}

.cui-sm .cui-tag.cui-tag-removable .cui-icon, .cui-tag.cui-sm.cui-tag-removable .cui-icon {
  margin-left: 7px;
}

.cui-lg .cui-tag, .cui-tag.cui-lg {
  margin-right: 9px;
  padding: 2px 9px;
  font-size: 16px;
}

.cui-lg .cui-tag.cui-tag-removable .cui-icon, .cui-tag.cui-lg.cui-tag-removable .cui-icon {
  margin-left: 9px;
}

.cui-tag.cui-xl, .cui-xl .cui-tag {
  margin-right: 10px;
  padding: 2px 10px;
  font-size: 18px;
}

.cui-tag.cui-xl.cui-tag-removable .cui-icon, .cui-xl .cui-tag.cui-tag-removable .cui-icon {
  margin-left: 10px;
}

.cui-tag.cui-primary {
  color: #fff;
  background: #5c6bc0;
  border: 1px solid #4a569a;
}

.cui-tag.cui-positive {
  color: #fff;
  background: #4caf50;
  border: 1px solid #3d8c40;
}

.cui-tag.cui-negative {
  color: #fff;
  background: #f44336;
  border: 1px solid #c3362b;
}

.cui-tag.cui-warning {
  color: #fff;
  background: #f57c00;
  border: 1px solid #c46300;
}

.cui-tag-input {
  color: #37474f;
  background: #fff;
  border: 1px solid #c5cdd1;
  border-radius: 3px;
  transition: border .2s cubic-bezier(.4, 1, .75, .9), background .2s cubic-bezier(.4, 1, .75, .9), color .2s cubic-bezier(.4, 1, .75, .9);
  display: inline-block;
  position: relative;
}

.cui-tag-input.cui-active, .cui-tag-input:focus {
  border: 1px solid #5360ad;
}

.cui-tag-input .cui-tag-input-values {
  height: 40px;
  min-height: 40px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 40px;
}

.cui-tag-input .cui-tag-input-values:not(:first-child) {
  padding-left: 36px;
}

.cui-tag-input .cui-tag-input-values:not(:last-child) {
  padding-right: 36px;
}

.cui-tag-input > .cui-button:first-child:not(input), .cui-tag-input > .cui-icon:first-child:not(input), .cui-tag-input > .cui-spinner:first-child:not(input), .cui-tag-input > .cui-tag:first-child:not(input) {
  margin-left: 12px;
}

.cui-tag-input > .cui-button:last-child, .cui-tag-input > .cui-icon:last-child, .cui-tag-input > .cui-spinner:last-child, .cui-tag-input > .cui-tag:last-child {
  margin-right: 12px;
}

.cui-tag-input > .cui-button {
  height: 28px;
  min-height: 28px;
  padding: 0 6px;
  font-size: 11px;
  line-height: 28px;
}

.cui-tag-input > .cui-icon {
  top: 9px;
}

.cui-tag-input .cui-tag-input-values {
  padding-top: 5px;
}

.cui-tag-input input {
  margin-top: 1px;
  margin-bottom: 6px;
}

.cui-tag-input .cui-tag {
  margin: 0 6px 4px 0;
}

.cui-tag-input:hover {
  cursor: text;
}

.cui-tag-input .cui-tag-input-values {
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  display: flex;
  line-height: inherit !important;
  height: auto !important;
  min-height: auto !important;
}

.cui-tag-input input {
  color: #37474f;
  vertical-align: middle;
  background: none;
  border: none;
  outline: 0;
  flex: auto;
  width: 100px;
}

.cui-tag-input input::placeholder {
  color: #b0bec5;
}

.cui-tag-input input::-ms-clear {
  display: none;
}

.cui-tag-input > .cui-icon {
  z-index: 1;
  margin: 0;
  position: absolute;
}

.cui-tag-input > .cui-icon:first-child {
  left: 0;
}

.cui-tag-input > .cui-icon:last-child {
  right: 0;
}

.cui-tag-input.cui-disabled .cui-button, .cui-tag-input.cui-disabled .cui-icon, .cui-tag-input.cui-disabled input {
  cursor: not-allowed;
  pointer-events: none;
}

.cui-tag-input.cui-xs .cui-tag-input-values {
  height: 30px;
  min-height: 30px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 30px;
}

.cui-tag-input.cui-xs .cui-tag-input-values:not(:first-child) {
  padding-left: 30px;
}

.cui-tag-input.cui-xs .cui-tag-input-values:not(:last-child) {
  padding-right: 30px;
}

.cui-tag-input.cui-xs > .cui-button:first-child:not(input), .cui-tag-input.cui-xs > .cui-icon:first-child:not(input), .cui-tag-input.cui-xs > .cui-spinner:first-child:not(input), .cui-tag-input.cui-xs > .cui-tag:first-child:not(input) {
  margin-left: 10px;
}

.cui-tag-input.cui-xs > .cui-button:last-child, .cui-tag-input.cui-xs > .cui-icon:last-child, .cui-tag-input.cui-xs > .cui-spinner:last-child, .cui-tag-input.cui-xs > .cui-tag:last-child {
  margin-right: 10px;
}

.cui-tag-input.cui-xs > .cui-button {
  height: 21px;
  min-height: 21px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 21px;
}

.cui-tag-input.cui-xs > .cui-icon {
  top: 7px;
}

.cui-tag-input.cui-xs .cui-tag-input-values {
  padding-top: 4px;
}

.cui-tag-input.cui-xs input {
  margin-top: 1px;
  margin-bottom: 5px;
}

.cui-tag-input.cui-xs .cui-tag {
  margin: 0 5px 3px 0;
}

.cui-tag-input.cui-sm .cui-tag-input-values {
  height: 35px;
  min-height: 35px;
  padding: 0 11px;
  font-size: 13px;
  line-height: 35px;
}

.cui-tag-input.cui-sm .cui-tag-input-values:not(:first-child) {
  padding-left: 33px;
}

.cui-tag-input.cui-sm .cui-tag-input-values:not(:last-child) {
  padding-right: 33px;
}

.cui-tag-input.cui-sm > .cui-button:first-child:not(input), .cui-tag-input.cui-sm > .cui-icon:first-child:not(input), .cui-tag-input.cui-sm > .cui-spinner:first-child:not(input), .cui-tag-input.cui-sm > .cui-tag:first-child:not(input) {
  margin-left: 11px;
}

.cui-tag-input.cui-sm > .cui-button:last-child, .cui-tag-input.cui-sm > .cui-icon:last-child, .cui-tag-input.cui-sm > .cui-spinner:last-child, .cui-tag-input.cui-sm > .cui-tag:last-child {
  margin-right: 11px;
}

.cui-tag-input.cui-sm > .cui-button {
  height: 25px;
  min-height: 25px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 25px;
}

.cui-tag-input.cui-sm > .cui-icon {
  top: 8px;
}

.cui-tag-input.cui-sm .cui-tag-input-values {
  padding-top: 4px;
}

.cui-tag-input.cui-sm input {
  margin-top: 1px;
  margin-bottom: 5px;
}

.cui-tag-input.cui-sm .cui-tag {
  margin: 0 5px 3px 0;
}

.cui-tag-input.cui-lg .cui-tag-input-values {
  height: 45px;
  min-height: 45px;
  padding: 0 14px;
  font-size: 16px;
  line-height: 45px;
}

.cui-tag-input.cui-lg .cui-tag-input-values:not(:first-child) {
  padding-left: 42px;
}

.cui-tag-input.cui-lg .cui-tag-input-values:not(:last-child) {
  padding-right: 42px;
}

.cui-tag-input.cui-lg > .cui-button:first-child:not(input), .cui-tag-input.cui-lg > .cui-icon:first-child:not(input), .cui-tag-input.cui-lg > .cui-spinner:first-child:not(input), .cui-tag-input.cui-lg > .cui-tag:first-child:not(input) {
  margin-left: 14px;
}

.cui-tag-input.cui-lg > .cui-button:last-child, .cui-tag-input.cui-lg > .cui-icon:last-child, .cui-tag-input.cui-lg > .cui-spinner:last-child, .cui-tag-input.cui-lg > .cui-tag:last-child {
  margin-right: 14px;
}

.cui-tag-input.cui-lg > .cui-button {
  height: 32px;
  min-height: 32px;
  padding: 0 7px;
  font-size: 13px;
  line-height: 32px;
}

.cui-tag-input.cui-lg > .cui-icon {
  top: 11px;
}

.cui-tag-input.cui-lg .cui-tag-input-values {
  padding-top: 6px;
}

.cui-tag-input.cui-lg input {
  margin-top: 1px;
  margin-bottom: 7px;
}

.cui-tag-input.cui-lg .cui-tag {
  margin: 0 7px 5px 0;
}

.cui-tag-input.cui-xl .cui-tag-input-values {
  height: 50px;
  min-height: 50px;
  padding: 0 16px;
  font-size: 18px;
  line-height: 50px;
}

.cui-tag-input.cui-xl .cui-tag-input-values:not(:first-child) {
  padding-left: 48px;
}

.cui-tag-input.cui-xl .cui-tag-input-values:not(:last-child) {
  padding-right: 48px;
}

.cui-tag-input.cui-xl > .cui-button:first-child:not(input), .cui-tag-input.cui-xl > .cui-icon:first-child:not(input), .cui-tag-input.cui-xl > .cui-spinner:first-child:not(input), .cui-tag-input.cui-xl > .cui-tag:first-child:not(input) {
  margin-left: 16px;
}

.cui-tag-input.cui-xl > .cui-button:last-child, .cui-tag-input.cui-xl > .cui-icon:last-child, .cui-tag-input.cui-xl > .cui-spinner:last-child, .cui-tag-input.cui-xl > .cui-tag:last-child {
  margin-right: 16px;
}

.cui-tag-input.cui-xl > .cui-button {
  height: 35px;
  min-height: 35px;
  padding: 0 8px;
  font-size: 15px;
  line-height: 35px;
}

.cui-tag-input.cui-xl > .cui-icon {
  top: 13px;
}

.cui-tag-input.cui-xl .cui-tag-input-values {
  padding-top: 7px;
}

.cui-tag-input.cui-xl input {
  margin-top: 1px;
  margin-bottom: 8px;
}

.cui-tag-input.cui-xl .cui-tag {
  margin: 0 8px 6px 0;
}

.cui-tag-input.cui-primary {
  border: 1px solid #5360ad;
}

.cui-tag-input.cui-primary.cui-active, .cui-tag-input.cui-primary:focus {
  border: 1px solid #4a569a;
}

.cui-tag-input.cui-positive {
  border: 1px solid #3d8c40;
}

.cui-tag-input.cui-positive.cui-active, .cui-tag-input.cui-positive:focus {
  border: 1px solid #357b38;
}

.cui-tag-input.cui-negative {
  border: 1px solid #dc3c31;
}

.cui-tag-input.cui-negative.cui-active, .cui-tag-input.cui-negative:focus {
  border: 1px solid #c3362b;
}

.cui-tag-input.cui-warning {
  border: 1px solid #dd7000;
}

.cui-tag-input.cui-warning.cui-active, .cui-tag-input.cui-warning:focus {
  border: 1px solid #c46300;
}

.cui-text-area {
  position: relative;
}

.cui-text-area textarea {
  resize: both;
  padding-top: 12px;
  padding-bottom: 12px;
  height: initial !important;
  line-height: initial !important;
}

.cui-text-area.cui-xs textarea {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cui-text-area.cui-sm textarea {
  padding-top: 11px;
  padding-bottom: 11px;
}

.cui-text-area.cui-lg textarea {
  padding-top: 14px;
  padding-bottom: 14px;
}

.cui-text-area.cui-xl textarea {
  padding-top: 16px;
  padding-bottom: 16px;
}

.cui-toaster {
  z-index: 20;
  pointer-events: none;
  flex-direction: column;
  padding: 20px;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
}

.cui-toaster.cui-toaster-inline {
  position: absolute;
}

.cui-toaster.cui-toaster-bottom-start, .cui-toaster.cui-toaster-top-start {
  align-items: flex-start;
}

.cui-toaster.cui-toaster-bottom, .cui-toaster.cui-toaster-top {
  align-items: center;
}

.cui-toaster.cui-toaster-bottom-end, .cui-toaster.cui-toaster-top-end {
  align-items: flex-end;
}

.cui-toaster.cui-toaster-top, .cui-toaster.cui-toaster-top-end, .cui-toaster.cui-toaster-top-start {
  top: 0;
  bottom: auto;
}

.cui-toaster.cui-toaster-bottom, .cui-toaster.cui-toaster-bottom-end, .cui-toaster.cui-toaster-bottom-start {
  top: auto;
  bottom: 0;
}

.cui-toast {
  pointer-events: all;
  color: #607d8b;
  background: #fff;
  border: 1px solid #c5cdd1;
  border-radius: 3px;
  outline: 0;
  align-items: center;
  min-width: 300px;
  max-width: 500px;
  margin-bottom: 10px;
  padding: 12px;
  transition: color .3s ease-in;
  display: flex;
  position: relative;
  box-shadow: 0 8px 10px -4px #435a6f4d;
}

.cui-toast .cui-toast-message {
  padding-right: 12px;
  font-size: 14px;
}

.cui-toast > .cui-icon:first-child {
  margin-right: 12px;
}

.cui-toast.cui-negative > .cui-icon, .cui-toast.cui-positive > .cui-icon, .cui-toast.cui-primary > .cui-icon, .cui-toast.cui-warning > .cui-icon {
  color: #fff;
}

.cui-toast.cui-negative > .cui-icon-x, .cui-toast.cui-positive > .cui-icon-x, .cui-toast.cui-primary > .cui-icon-x, .cui-toast.cui-warning > .cui-icon-x {
  color: #eceff1;
}

.cui-toast.cui-negative > .cui-icon-x:hover, .cui-toast.cui-positive > .cui-icon-x:hover, .cui-toast.cui-primary > .cui-icon-x:hover, .cui-toast.cui-warning > .cui-icon-x:hover {
  color: #fff;
}

.cui-toast .cui-toast-message {
  flex: auto;
}

.cui-toast.cui-xs {
  padding: 10px;
}

.cui-toast.cui-xs .cui-toast-message {
  padding-right: 10px;
  font-size: 12px;
}

.cui-toast.cui-xs > .cui-icon:first-child {
  margin-right: 10px;
}

.cui-toast.cui-sm {
  padding: 11px;
}

.cui-toast.cui-sm .cui-toast-message {
  padding-right: 11px;
  font-size: 13px;
}

.cui-toast.cui-sm > .cui-icon:first-child {
  margin-right: 11px;
}

.cui-toast.cui-lg {
  padding: 14px;
}

.cui-toast.cui-lg .cui-toast-message {
  padding-right: 14px;
  font-size: 16px;
}

.cui-toast.cui-lg > .cui-icon:first-child {
  margin-right: 14px;
}

.cui-toast.cui-xl {
  padding: 16px;
}

.cui-toast.cui-xl .cui-toast-message {
  padding-right: 16px;
  font-size: 18px;
}

.cui-toast.cui-xl > .cui-icon:first-child {
  margin-right: 16px;
}

.cui-toast.cui-primary {
  color: #fff;
  background: #5c6bc0;
  border: 1px solid #5360ad;
  box-shadow: 0 8px 10px -4px #435a6f4d;
}

.cui-toast.cui-positive {
  color: #fff;
  background: #4caf50;
  border: 1px solid #3d8c40;
  box-shadow: 0 8px 10px -4px #435a6f4d;
}

.cui-toast.cui-negative {
  color: #fff;
  background: #f44336;
  border: 1px solid #dc3c31;
  box-shadow: 0 8px 10px -4px #435a6f4d;
}

.cui-toast.cui-warning {
  color: #fff;
  background: #f57c00;
  border: 1px solid #dd7000;
  box-shadow: 0 8px 10px -4px #435a6f4d;
}

.cui-tooltip .cui-popover-arrow {
  width: 10px;
  height: 10px;
}

.cui-tooltip .cui-popover-content {
  padding: 8px;
  font-size: 13px;
}

.cui-tooltip[x-placement^="top"] .cui-popover-arrow {
  bottom: -5px;
}

.cui-tooltip[x-placement^="bottom"] .cui-popover-arrow {
  top: -5px;
}

.cui-tooltip[x-placement^="right"] .cui-popover-arrow {
  left: -5px;
}

.cui-tooltip[x-placement^="left"] .cui-popover-arrow {
  right: -5px;
}

.cui-tooltip .cui-popover-content {
  color: #37474f;
  width: auto;
  min-width: auto;
}

.cui-tooltip.cui-xs .cui-popover-arrow {
  width: 8px;
  height: 8px;
}

.cui-tooltip.cui-xs .cui-popover-content {
  padding: 6px;
  font-size: 11px;
}

.cui-tooltip.cui-xs[x-placement^="top"] .cui-popover-arrow {
  bottom: -4px;
}

.cui-tooltip.cui-xs[x-placement^="bottom"] .cui-popover-arrow {
  top: -4px;
}

.cui-tooltip.cui-xs[x-placement^="right"] .cui-popover-arrow {
  left: -4px;
}

.cui-tooltip.cui-xs[x-placement^="left"] .cui-popover-arrow {
  right: -4px;
}

.cui-tooltip.cui-sm .cui-popover-arrow {
  width: 9px;
  height: 9px;
}

.cui-tooltip.cui-sm .cui-popover-content {
  padding: 7px;
  font-size: 12px;
}

.cui-tooltip.cui-sm[x-placement^="top"] .cui-popover-arrow {
  bottom: -4px;
}

.cui-tooltip.cui-sm[x-placement^="bottom"] .cui-popover-arrow {
  top: -4px;
}

.cui-tooltip.cui-sm[x-placement^="right"] .cui-popover-arrow {
  left: -4px;
}

.cui-tooltip.cui-sm[x-placement^="left"] .cui-popover-arrow {
  right: -4px;
}

.cui-tooltip.cui-lg .cui-popover-arrow {
  width: 11px;
  height: 11px;
}

.cui-tooltip.cui-lg .cui-popover-content {
  padding: 9px;
  font-size: 15px;
}

.cui-tooltip.cui-lg[x-placement^="top"] .cui-popover-arrow {
  bottom: -5px;
}

.cui-tooltip.cui-lg[x-placement^="bottom"] .cui-popover-arrow {
  top: -5px;
}

.cui-tooltip.cui-lg[x-placement^="right"] .cui-popover-arrow {
  left: -5px;
}

.cui-tooltip.cui-lg[x-placement^="left"] .cui-popover-arrow {
  right: -5px;
}

.cui-tooltip.cui-xl .cui-popover-arrow {
  width: 13px;
  height: 13px;
}

.cui-tooltip.cui-xl .cui-popover-content {
  padding: 10px;
  font-size: 17px;
}

.cui-tooltip.cui-xl[x-placement^="top"] .cui-popover-arrow {
  bottom: -6px;
}

.cui-tooltip.cui-xl[x-placement^="bottom"] .cui-popover-arrow {
  top: -6px;
}

.cui-tooltip.cui-xl[x-placement^="right"] .cui-popover-arrow {
  left: -6px;
}

.cui-tooltip.cui-xl[x-placement^="left"] .cui-popover-arrow {
  right: -6px;
}

.cui-tree {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cui-tree-node-list {
  margin: 0;
  padding-left: 20px;
  list-style: none;
}

.cui-tree-node-content {
  align-items: center;
  padding: 5px;
  display: flex;
}

.cui-tree-node-content:hover {
  background-color: #f6f7f8;
}

.cui-tree-node-caret, .cui-tree-node-caret-none {
  text-align: center;
  position: relative;
}

.cui-tree-node-caret-none {
  opacity: 0;
  visibility: hidden;
}

.cui-tree-node-caret {
  color: #37474f;
  transform-origin: center;
  cursor: pointer;
  margin-right: 5px;
  transition: transform .4s cubic-bezier(.4, 1, .75, .9);
  display: inline-block;
}

.cui-tree-node-caret.cui-tree-node-caret-open {
  transform-origin: center;
  transform: rotate(90deg);
}

.cui-tree-node-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-user-select: none;
  user-select: none;
  flex: auto;
  font-size: 14px;
  position: relative;
  overflow: hidden;
}

.cui-tree-node-label span {
  display: inline;
}

.cui-tree-node-content-right {
  margin-left: 5px;
  display: flex;
}

.cui-tree-node-content-left {
  margin-right: 5px;
  display: flex;
}

.cui-tree-node.cui-tree-node-selected > .cui-tree-node-content {
  background: #eceff1;
}

.dark {
  color: #ddd;
  background-color: #222;
}

.dark h1, .dark h2, .dark h3 {
  color: #fff;
}

.dark h4 {
  color: #888;
}

.dark a {
  color: #aac;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.dark a:hover {
  color: #fff;
}

nav a {
  color: #000;
}

.dark nav a {
  color: #ccc;
  text-decoration: none;
}

.layout {
  display: grid;
}

.menu {
  background: #8883;
  justify-content: space-between;
  margin: 1em 1em .5em;
  padding: .5em 1em;
  display: flex;
}

.board {
  padding-bottom: 100%;
}

.gamePage .board {
  grid-area: 3 / 1 / 9 / 9;
  height: 0;
}

.cg-wrap coords.ranks {
  top: 0;
  bottom: 0;
  right: 0;
}

.cg-wrap coords.files {
  top: 0;
  bottom: 0;
  left: 0;
}

.myturn {
  background: #e5e0b6;
}

.dark .myturn {
  background: #777;
}

.gamethumb {
  border: outset wheat;
  width: 100%;
  min-width: -webkit-fill-available;
  margin: 4px;
  padding: 4px;
  overflow: hidden;
}

.gamethumb:hover {
  background: #fff;
  overflow: visible;
}

.dark .gamethumb:hover {
  color: #000;
  background: #fffdea;
}

.selector {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border: groove #deb887;
  grid-template-rows: repeat(auto-fill, minmax(0, 1fr));
  grid-template-columns: repeat(8, 1fr);
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
}

section {
  padding: 1em;
}

.fullscreen {
  max-width: 75vh;
  height: 75vh;
}

.status {
  border: 1.5px solid #fff;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  margin-right: 1em;
  display: inline-flex;
}

.connected {
  background-color: green;
}

.disconnected {
  background-color: red;
}

.logo {
  width: 4em;
}

i {
  border-style: solid;
  border-color: #393939;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: .5rem;
}

i:hover {
  background-color: #393939;
}

i:active {
  animation: .2s blinker;
}

.cui-button {
  border-radius: 5px;
  box-shadow: 0 1px #777;
}

.dark .cui-button {
  color: #aaa;
  background: linear-gradient(#3c3934, #33312e 100%);
  border-style: solid;
  border-color: #393939;
}

.glow {
  box-shadow: 0 0 3px 1px #d753bb;
}

.profile {
  background: #8883;
  border-radius: 1em;
  margin: 1em;
  padding: 1em;
  display: grid;
  overflow: auto;
}

.dark .profile {
  background: #444;
}

.toolbar {
  grid-column: 1 / 10;
  grid-template-columns: repeat(9, 1fr);
  place-items: center;
  display: grid;
}

.inline {
  display: inline-grid;
}

.tool {
  margin-right: 1em;
}

.vl {
  border-left: thick solid #393939;
}

.turn {
  min-width: 2em;
  min-height: 2em;
  padding: .5rem;
}

.w {
  color: #000;
  background-color: #fff;
}

.b {
  color: #fff;
  background-color: #000;
}

.gamePage {
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(9, 1fr);
  width: 100%;
  max-width: min(82vw, 82vh);
  height: 70vh;
  display: grid;
}

.top_user {
  grid-row-start: 2;
}

.bottom_user {
  grid-row-start: 12;
}

.svgicon {
  width: 1.5rem;
}

.cg-wrap {
  box-sizing: content-box;
  display: block;
  position: relative;
}

cg-container {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
}

cg-board {
  -webkit-user-select: none;
  user-select: none;
  background-size: cover;
  width: 100%;
  height: 100%;
  line-height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.cg-wrap.manipulable cg-board {
  cursor: pointer;
}

cg-board square {
  pointer-events: none;
  width: 12.5%;
  height: 12.5%;
  position: absolute;
  top: 0;
  left: 0;
}

cg-board square.move-dest {
  pointer-events: auto;
}

cg-board square.last-move {
  will-change: transform;
}

.cg-wrap piece {
  z-index: 2;
  will-change: transform;
  pointer-events: none;
  background-size: cover;
  width: 12.5%;
  height: 12.5%;
  position: absolute;
  top: 0;
  left: 0;
}

cg-board piece.dragging {
  cursor: move;
  z-index: 11 !important;
}

piece.anim {
  z-index: 8;
}

piece.fading {
  z-index: 1;
  opacity: .5;
}

.cg-wrap piece.ghost {
  opacity: .3;
}

.cg-wrap piece svg {
  pointer-events: none;
  z-index: 2;
  opacity: .6;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.cg-wrap cg-auto-pieces, .cg-wrap .cg-shapes, .cg-wrap .cg-custom-svgs {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
}

.cg-wrap cg-auto-pieces {
  z-index: 2;
}

.cg-wrap cg-auto-pieces piece {
  opacity: .3;
}

.cg-wrap .cg-shapes {
  opacity: .6;
  z-index: 2;
  overflow: hidden;
}

.cg-wrap .cg-custom-svgs {
  z-index: 9;
}

.cg-wrap .cg-custom-svgs svg {
  overflow: visible;
}

.cg-wrap coords {
  pointer-events: none;
  opacity: .8;
  font-family: sans-serif;
  font-size: 9px;
  display: flex;
  position: absolute;
}

.cg-wrap coords.ranks {
  flex-flow: column-reverse;
  width: 12px;
  height: 100%;
  top: -20px;
  left: 4px;
}

.cg-wrap coords.ranks.black {
  flex-flow: column;
}

.cg-wrap coords.ranks.left {
  align-items: flex-end;
  left: -15px;
}

.cg-wrap coords.files {
  text-transform: uppercase;
  text-align: center;
  flex-flow: row;
  width: 100%;
  height: 16px;
  bottom: -4px;
  left: 24px;
}

.cg-wrap coords.files.black {
  flex-flow: row-reverse;
}

.cg-wrap coords coord {
  flex: auto;
}

.cg-wrap coords.ranks coord {
  transform: translateY(39%);
}

cg-board {
  background-color: #f0d9b5;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4PSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgogICAgIHZpZXdCb3g9IjAgMCA4IDgiIHNoYXBlLXJlbmRlcmluZz0iY3Jpc3BFZGdlcyI+CjxnIGlkPSJhIj4KICA8ZyBpZD0iYiI+CiAgICA8ZyBpZD0iYyI+CiAgICAgIDxnIGlkPSJkIj4KICAgICAgICA8cmVjdCB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBpZD0iZSIgb3BhY2l0eT0iMCIvPgogICAgICAgIDx1c2UgeD0iMSIgeT0iMSIgaHJlZj0iI2UiIHg6aHJlZj0iI2UiLz4KICAgICAgICA8cmVjdCB5PSIxIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBpZD0iZiIgb3BhY2l0eT0iMC4yIi8+CiAgICAgICAgPHVzZSB4PSIxIiB5PSItMSIgaHJlZj0iI2YiIHg6aHJlZj0iI2YiLz4KICAgICAgPC9nPgogICAgICA8dXNlIHg9IjIiIGhyZWY9IiNkIiB4OmhyZWY9IiNkIi8+CiAgICA8L2c+CiAgICA8dXNlIHg9IjQiIGhyZWY9IiNjIiB4OmhyZWY9IiNjIi8+CiAgPC9nPgogIDx1c2UgeT0iMiIgaHJlZj0iI2IiIHg6aHJlZj0iI2IiLz4KPC9nPgo8dXNlIHk9IjQiIGhyZWY9IiNhIiB4OmhyZWY9IiNhIi8+Cjwvc3ZnPg==");
}

cg-board square.move-dest {
  background: radial-gradient(#14551e80 22%, #208530 0, #0000004d 0, #0000 0);
}

cg-board square.premove-dest {
  background: radial-gradient(#141e5580 22%, #203085 0, #0000004d 0, #0000 0);
}

cg-board square.oc.move-dest {
  background: radial-gradient(#0000 0% 80%, #1455004d 80%);
}

cg-board square.oc.premove-dest {
  background: radial-gradient(#0000 0% 80%, #141e5533 80%);
}

cg-board square.move-dest:hover {
  background: #14551e4d;
}

cg-board square.premove-dest:hover {
  background: #141e5533;
}

cg-board square.last-move {
  background-color: #9bc70069;
}

cg-board square.selected {
  background-color: #14551e80;
}

cg-board square.check {
  background: radial-gradient(red 0%, #e70000 25%, #a9000000 89%, #9e000000 100%);
}

cg-board square.current-premove {
  background-color: #141e5580;
}

.cg-wrap.orientation-white coords.ranks coord:nth-child(2n), .cg-wrap.orientation-white coords.files coord:nth-child(2n), .cg-wrap.orientation-black coords.ranks coord:nth-child(odd), .cg-wrap.orientation-black coords.files coord:nth-child(odd) {
  color: #484848cc;
}

.cg-wrap.orientation-black coords.ranks coord:nth-child(2n), .cg-wrap.orientation-black coords.files coord:nth-child(2n), .cg-wrap.orientation-white coords.ranks coord:nth-child(odd), .cg-wrap.orientation-white coords.files coord:nth-child(odd) {
  color: #fffc;
}

.cg-wrap piece.pawn.white {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSI+PHBhdGggZD0iTTIyLjUgOWMtMi4yMSAwLTQgMS43OS00IDQgMCAuODkuMjkgMS43MS43OCAyLjM4QzE3LjMzIDE2LjUgMTYgMTguNTkgMTYgMjFjMCAyLjAzLjk0IDMuODQgMi40MSA1LjAzLTMgMS4wNi03LjQxIDUuNTUtNy40MSAxMy40N2gyM2MwLTcuOTItNC40MS0xMi40MS03LjQxLTEzLjQ3IDEuNDctMS4xOSAyLjQxLTMgMi40MS01LjAzIDAtMi40MS0xLjMzLTQuNS0zLjI4LTUuNjIuNDktLjY3Ljc4LTEuNDkuNzgtMi4zOCAwLTIuMjEtMS43OS00LTQtNHoiIGZpbGw9IiNmZmYiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==");
}

.cg-wrap piece.bishop.white {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxnIGZpbGw9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJidXR0Ij48cGF0aCBkPSJNOSAzNmMzLjM5LS45NyAxMC4xMS40MyAxMy41LTIgMy4zOSAyLjQzIDEwLjExIDEuMDMgMTMuNSAyIDAgMCAxLjY1LjU0IDMgMi0uNjguOTctMS42NS45OS0zIC41LTMuMzktLjk3LTEwLjExLjQ2LTEzLjUtMS0zLjM5IDEuNDYtMTAuMTEuMDMtMTMuNSAxLTEuMzU0LjQ5LTIuMzIzLjQ3LTMtLjUgMS4zNTQtMS45NCAzLTIgMy0yeiIvPjxwYXRoIGQ9Ik0xNSAzMmMyLjUgMi41IDEyLjUgMi41IDE1IDAgLjUtMS41IDAtMiAwLTIgMC0yLjUtMi41LTQtMi41LTQgNS41LTEuNSA2LTExLjUtNS0xNS41LTExIDQtMTAuNSAxNC01IDE1LjUgMCAwLTIuNSAxLjUtMi41IDQgMCAwLS41LjUgMCAyeiIvPjxwYXRoIGQ9Ik0yNSA4YTIuNSAyLjUgMCAxIDEtNSAwIDIuNSAyLjUgMCAxIDEgNSAweiIvPjwvZz48cGF0aCBkPSJNMTcuNSAyNmgxME0xNSAzMGgxNW0tNy41LTE0LjV2NU0yMCAxOGg1IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIi8+PC9nPjwvc3ZnPg==");
}

.cg-wrap piece.knight.white {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIGQ9Ik0yMiAxMGMxMC41IDEgMTYuNSA4IDE2IDI5SDE1YzAtOSAxMC02LjUgOC0yMSIgZmlsbD0iI2ZmZiIvPjxwYXRoIGQ9Ik0yNCAxOGMuMzggMi45MS01LjU1IDcuMzctOCA5LTMgMi0yLjgyIDQuMzQtNSA0LTEuMDQyLS45NCAxLjQxLTMuMDQgMC0zLTEgMCAuMTkgMS4yMy0xIDItMSAwLTQuMDAzIDEtNC00IDAtMiA2LTEyIDYtMTJzMS44OS0xLjkgMi0zLjVjLS43My0uOTk0LS41LTItLjUtMyAxLTEgMyAyLjUgMyAyLjVoMnMuNzgtMS45OTIgMi41LTNjMSAwIDEgMyAxIDMiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNOS41IDI1LjVhLjUuNSAwIDEgMS0xIDAgLjUuNSAwIDEgMSAxIDB6bTUuNDMzLTkuNzVhLjUgMS41IDMwIDEgMS0uODY2LS41LjUgMS41IDMwIDEgMSAuODY2LjV6IiBmaWxsPSIjMDAwIi8+PC9nPjwvc3ZnPg==");
}

.cg-wrap piece.rook.white {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSI+PGcgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIGQ9Ik05IDM5aDI3di0zSDl2M3ptMy0zdi00aDIxdjRIMTJ6bS0xLTIyVjloNHYyaDVWOWg1djJoNVY5aDR2NSIgc3Ryb2tlLWxpbmVjYXA9ImJ1dHQiLz48cGF0aCBkPSJNMzQgMTRsLTMgM0gxNGwtMy0zIi8+PHBhdGggZD0iTTMxIDE3djEyLjVIMTRWMTciIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIi8+PHBhdGggZD0iTTMxIDI5LjVsMS41IDIuNWgtMjBsMS41LTIuNSIvPjxwYXRoIGQ9Ik0xMSAxNGgyMyIgZmlsbD0ibm9uZSIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIvPjwvZz48L3N2Zz4=");
}

.cg-wrap piece.queen.white {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSI+PGcgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIGQ9Ik04IDEyYTIgMiAwIDEgMS00IDAgMiAyIDAgMSAxIDQgMHptMTYuNS00LjVhMiAyIDAgMSAxLTQgMCAyIDIgMCAxIDEgNCAwek00MSAxMmEyIDIgMCAxIDEtNCAwIDIgMiAwIDEgMSA0IDB6TTE2IDguNWEyIDIgMCAxIDEtNCAwIDIgMiAwIDEgMSA0IDB6TTMzIDlhMiAyIDAgMSAxLTQgMCAyIDIgMCAxIDEgNCAweiIvPjxwYXRoIGQ9Ik05IDI2YzguNS0xLjUgMjEtMS41IDI3IDBsMi0xMi03IDExVjExbC01LjUgMTMuNS0zLTE1LTMgMTUtNS41LTE0VjI1TDcgMTRsMiAxMnoiIHN0cm9rZS1saW5lY2FwPSJidXR0Ii8+PHBhdGggZD0iTTkgMjZjMCAyIDEuNSAyIDIuNSA0IDEgMS41IDEgMSAuNSAzLjUtMS41IDEtMS41IDIuNS0xLjUgMi41LTEuNSAxLjUuNSAyLjUuNSAyLjUgNi41IDEgMTYuNSAxIDIzIDAgMCAwIDEuNS0xIDAtMi41IDAgMCAuNS0xLjUtMS0yLjUtLjUtMi41LS41LTIgLjUtMy41IDEtMiAyLjUtMiAyLjUtNC04LjUtMS41LTE4LjUtMS41LTI3IDB6IiBzdHJva2UtbGluZWNhcD0iYnV0dCIvPjxwYXRoIGQ9Ik0xMS41IDMwYzMuNS0xIDE4LjUtMSAyMiAwTTEyIDMzLjVjNi0xIDE1LTEgMjEgMCIgZmlsbD0ibm9uZSIvPjwvZz48L3N2Zz4=");
}

.cg-wrap piece.king.white {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIGQ9Ik0yMi41IDExLjYzVjZNMjAgOGg1IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIi8+PHBhdGggZD0iTTIyLjUgMjVzNC41LTcuNSAzLTEwLjVjMCAwLTEtMi41LTMtMi41cy0zIDIuNS0zIDIuNWMtMS41IDMgMyAxMC41IDMgMTAuNSIgZmlsbD0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9ImJ1dHQiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiLz48cGF0aCBkPSJNMTEuNSAzN2M1LjUgMy41IDE1LjUgMy41IDIxIDB2LTdzOS00LjUgNi0xMC41Yy00LTYuNS0xMy41LTMuNS0xNiA0VjI3di0zLjVjLTMuNS03LjUtMTMtMTAuNS0xNi00LTMgNiA1IDEwIDUgMTBWMzd6IiBmaWxsPSIjZmZmIi8+PHBhdGggZD0iTTExLjUgMzBjNS41LTMgMTUuNS0zIDIxIDBtLTIxIDMuNWM1LjUtMyAxNS41LTMgMjEgMG0tMjEgMy41YzUuNS0zIDE1LjUtMyAyMSAwIi8+PC9nPjwvc3ZnPg==");
}

.cg-wrap piece.pawn.black {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSI+PHBhdGggZD0iTTIyLjUgOWMtMi4yMSAwLTQgMS43OS00IDQgMCAuODkuMjkgMS43MS43OCAyLjM4QzE3LjMzIDE2LjUgMTYgMTguNTkgMTYgMjFjMCAyLjAzLjk0IDMuODQgMi40MSA1LjAzLTMgMS4wNi03LjQxIDUuNTUtNy40MSAxMy40N2gyM2MwLTcuOTItNC40MS0xMi40MS03LjQxLTEzLjQ3IDEuNDctMS4xOSAyLjQxLTMgMi40MS01LjAzIDAtMi40MS0xLjMzLTQuNS0zLjI4LTUuNjIuNDktLjY3Ljc4LTEuNDkuNzgtMi4zOCAwLTIuMjEtMS43OS00LTQtNHoiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==");
}

.cg-wrap piece.bishop.black {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxnIGZpbGw9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJidXR0Ij48cGF0aCBkPSJNOSAzNmMzLjM5LS45NyAxMC4xMS40MyAxMy41LTIgMy4zOSAyLjQzIDEwLjExIDEuMDMgMTMuNSAyIDAgMCAxLjY1LjU0IDMgMi0uNjguOTctMS42NS45OS0zIC41LTMuMzktLjk3LTEwLjExLjQ2LTEzLjUtMS0zLjM5IDEuNDYtMTAuMTEuMDMtMTMuNSAxLTEuMzU0LjQ5LTIuMzIzLjQ3LTMtLjUgMS4zNTQtMS45NCAzLTIgMy0yeiIvPjxwYXRoIGQ9Ik0xNSAzMmMyLjUgMi41IDEyLjUgMi41IDE1IDAgLjUtMS41IDAtMiAwLTIgMC0yLjUtMi41LTQtMi41LTQgNS41LTEuNSA2LTExLjUtNS0xNS41LTExIDQtMTAuNSAxNC01IDE1LjUgMCAwLTIuNSAxLjUtMi41IDQgMCAwLS41LjUgMCAyeiIvPjxwYXRoIGQ9Ik0yNSA4YTIuNSAyLjUgMCAxIDEtNSAwIDIuNSAyLjUgMCAxIDEgNSAweiIvPjwvZz48cGF0aCBkPSJNMTcuNSAyNmgxME0xNSAzMGgxNW0tNy41LTE0LjV2NU0yMCAxOGg1IiBzdHJva2U9IiNlY2VjZWMiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiLz48L2c+PC9zdmc+");
}

.cg-wrap piece.knight.black {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIGQ9Ik0yMiAxMGMxMC41IDEgMTYuNSA4IDE2IDI5SDE1YzAtOSAxMC02LjUgOC0yMSIgZmlsbD0iIzAwMCIvPjxwYXRoIGQ9Ik0yNCAxOGMuMzggMi45MS01LjU1IDcuMzctOCA5LTMgMi0yLjgyIDQuMzQtNSA0LTEuMDQyLS45NCAxLjQxLTMuMDQgMC0zLTEgMCAuMTkgMS4yMy0xIDItMSAwLTQuMDAzIDEtNC00IDAtMiA2LTEyIDYtMTJzMS44OS0xLjkgMi0zLjVjLS43My0uOTk0LS41LTItLjUtMyAxLTEgMyAyLjUgMyAyLjVoMnMuNzgtMS45OTIgMi41LTNjMSAwIDEgMyAxIDMiIGZpbGw9IiMwMDAiLz48cGF0aCBkPSJNOS41IDI1LjVhLjUuNSAwIDEgMS0xIDAgLjUuNSAwIDEgMSAxIDB6bTUuNDMzLTkuNzVhLjUgMS41IDMwIDEgMS0uODY2LS41LjUgMS41IDMwIDEgMSAuODY2LjV6IiBmaWxsPSIjZWNlY2VjIiBzdHJva2U9IiNlY2VjZWMiLz48cGF0aCBkPSJNMjQuNTUgMTAuNGwtLjQ1IDEuNDUuNS4xNWMzLjE1IDEgNS42NSAyLjQ5IDcuOSA2Ljc1UzM1Ljc1IDI5LjA2IDM1LjI1IDM5bC0uMDUuNWgyLjI1bC4wNS0uNWMuNS0xMC4wNi0uODgtMTYuODUtMy4yNS0yMS4zNC0yLjM3LTQuNDktNS43OS02LjY0LTkuMTktNy4xNmwtLjUxLS4xeiIgZmlsbD0iI2VjZWNlYyIgc3Ryb2tlPSJub25lIi8+PC9nPjwvc3ZnPg==");
}

.cg-wrap piece.rook.black {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSI+PGcgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIGQ9Ik05IDM5aDI3di0zSDl2M3ptMy41LTdsMS41LTIuNWgxN2wxLjUgMi41aC0yMHptLS41IDR2LTRoMjF2NEgxMnoiIHN0cm9rZS1saW5lY2FwPSJidXR0Ii8+PHBhdGggZD0iTTE0IDI5LjV2LTEzaDE3djEzSDE0eiIgc3Ryb2tlLWxpbmVjYXA9ImJ1dHQiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiLz48cGF0aCBkPSJNMTQgMTYuNUwxMSAxNGgyM2wtMyAyLjVIMTR6TTExIDE0VjloNHYyaDVWOWg1djJoNVY5aDR2NUgxMXoiIHN0cm9rZS1saW5lY2FwPSJidXR0Ii8+PHBhdGggZD0iTTEyIDM1LjVoMjFtLTIwLTRoMTltLTE4LTJoMTdtLTE3LTEzaDE3TTExIDE0aDIzIiBmaWxsPSJub25lIiBzdHJva2U9IiNlY2VjZWMiIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIvPjwvZz48L3N2Zz4=");
}

.cg-wrap piece.queen.black {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSI+PGcgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxnIHN0cm9rZT0ibm9uZSI+PGNpcmNsZSBjeD0iNiIgY3k9IjEyIiByPSIyLjc1Ii8+PGNpcmNsZSBjeD0iMTQiIGN5PSI5IiByPSIyLjc1Ii8+PGNpcmNsZSBjeD0iMjIuNSIgY3k9IjgiIHI9IjIuNzUiLz48Y2lyY2xlIGN4PSIzMSIgY3k9IjkiIHI9IjIuNzUiLz48Y2lyY2xlIGN4PSIzOSIgY3k9IjEyIiByPSIyLjc1Ii8+PC9nPjxwYXRoIGQ9Ik05IDI2YzguNS0xLjUgMjEtMS41IDI3IDBsMi41LTEyLjVMMzEgMjVsLS4zLTE0LjEtNS4yIDEzLjYtMy0xNC41LTMgMTQuNS01LjItMTMuNkwxNCAyNSA2LjUgMTMuNSA5IDI2eiIgc3Ryb2tlLWxpbmVjYXA9ImJ1dHQiLz48cGF0aCBkPSJNOSAyNmMwIDIgMS41IDIgMi41IDQgMSAxLjUgMSAxIC41IDMuNS0xLjUgMS0xLjUgMi41LTEuNSAyLjUtMS41IDEuNS41IDIuNS41IDIuNSA2LjUgMSAxNi41IDEgMjMgMCAwIDAgMS41LTEgMC0yLjUgMCAwIC41LTEuNS0xLTIuNS0uNS0yLjUtLjUtMiAuNS0zLjUgMS0yIDIuNS0yIDIuNS00LTguNS0xLjUtMTguNS0xLjUtMjcgMHoiIHN0cm9rZS1saW5lY2FwPSJidXR0Ii8+PHBhdGggZD0iTTExIDM4LjVhMzUgMzUgMSAwIDAgMjMgMCIgZmlsbD0ibm9uZSIgc3Ryb2tlLWxpbmVjYXA9ImJ1dHQiLz48cGF0aCBkPSJNMTEgMjlhMzUgMzUgMSAwIDEgMjMgMG0tMjEuNSAyLjVoMjBtLTIxIDNhMzUgMzUgMSAwIDAgMjIgMG0tMjMgM2EzNSAzNSAxIDAgMCAyNCAwIiBmaWxsPSJub25lIiBzdHJva2U9IiNlY2VjZWMiLz48L2c+PC9zdmc+");
}

.cg-wrap piece.king.black {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIGQ9Ik0yMi41IDExLjYzVjYiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiLz48cGF0aCBkPSJNMjIuNSAyNXM0LjUtNy41IDMtMTAuNWMwIDAtMS0yLjUtMy0yLjVzLTMgMi41LTMgMi41Yy0xLjUgMyAzIDEwLjUgMyAxMC41IiBmaWxsPSIjMDAwIiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIvPjxwYXRoIGQ9Ik0xMS41IDM3YzUuNSAzLjUgMTUuNSAzLjUgMjEgMHYtN3M5LTQuNSA2LTEwLjVjLTQtNi41LTEzLjUtMy41LTE2IDRWMjd2LTMuNWMtMy41LTcuNS0xMy0xMC41LTE2LTQtMyA2IDUgMTAgNSAxMFYzN3oiIGZpbGw9IiMwMDAiLz48cGF0aCBkPSJNMjAgOGg1IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIi8+PHBhdGggZD0iTTMyIDI5LjVzOC41LTQgNi4wMy05LjY1QzM0LjE1IDE0IDI1IDE4IDIyLjUgMjQuNWwuMDEgMi4xLS4wMS0yLjFDMjAgMTggOS45MDYgMTQgNi45OTcgMTkuODVjLTIuNDk3IDUuNjUgNC44NTMgOSA0Ljg1MyA5IiBzdHJva2U9IiNlY2VjZWMiLz48cGF0aCBkPSJNMTEuNSAzMGM1LjUtMyAxNS41LTMgMjEgMG0tMjEgMy41YzUuNS0zIDE1LjUtMyAyMSAwbS0yMSAzLjVjNS41LTMgMTUuNS0zIDIxIDAiIHN0cm9rZT0iI2VjZWNlYyIvPjwvZz48L3N2Zz4=");
}

cg-board {
  background-color: #ffc394;
}

.alertify .ajs-dimmer {
  z-index: 1981;
  opacity: .5;
  background-color: #252525;
  margin: 0;
  padding: 0;
  position: fixed;
  inset: 0;
}

.alertify .ajs-modal {
  z-index: 1981;
  padding: 0;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.alertify .ajs-dialog {
  background-color: #fff;
  outline: 0;
  max-width: 500px;
  min-height: 110px;
  margin: 5% auto;
  padding: 24px 24px 0;
  position: relative;
}

.alertify .ajs-dialog.ajs-capture:before {
  content: "";
  z-index: 1;
  display: block;
  position: absolute;
  inset: 0;
}

.alertify .ajs-reset {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  display: inline !important;
  position: absolute !important;
}

.alertify .ajs-commands {
  z-index: 2;
  margin: -14px 24px 0 0;
  position: absolute;
  right: 4px;
}

.alertify .ajs-commands button {
  cursor: pointer;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  padding: 10px;
  display: none;
}

.alertify .ajs-commands button.ajs-close {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAh0lEQVQYlY2QsQ0EIQwEB9cBAR1CJUaI/gigDnwR6NBL/7/xWLNrZ2b8EwGotVpr7eOitWa1VjugiNB7R1UPrKrWe0dEAHBbXUqxMQbeewDmnHjvyTm7C3zDwAUd9c63YQdUVdu6EAJzzquz7HXvTiklt+H9DQFYaxFjvDqllFyMkbXWvfpXHjJrWFgdBq/hAAAAAElFTkSuQmCC");
}

.alertify .ajs-commands button.ajs-maximize {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAOUlEQVQYlWP8//8/AzGAhYGBgaG4uBiv6t7eXkYmooxjYGAgWiELsvHYFMCcRX2rSXcjoSBiJDbAAeD+EGu+8BZcAAAAAElFTkSuQmCC");
}

.alertify .ajs-header {
  background-color: #fff;
  margin: -24px -24px 0;
  padding: 16px 24px;
}

.alertify .ajs-body {
  min-height: 56px;
}

.alertify .ajs-body .ajs-content {
  padding: 16px 24px 16px 16px;
}

.alertify .ajs-footer {
  background-color: #fff;
  min-height: 43px;
  margin-left: -24px;
  margin-right: -24px;
  padding: 4px;
}

.alertify .ajs-footer .ajs-buttons.ajs-primary {
  text-align: right;
}

.alertify .ajs-footer .ajs-buttons.ajs-primary .ajs-button {
  margin: 4px;
}

.alertify .ajs-footer .ajs-buttons.ajs-auxiliary {
  float: left;
  clear: none;
  text-align: left;
}

.alertify .ajs-footer .ajs-buttons.ajs-auxiliary .ajs-button {
  margin: 4px;
}

.alertify .ajs-footer .ajs-buttons .ajs-button {
  min-width: 88px;
  min-height: 35px;
}

.alertify .ajs-handle {
  z-index: 1;
  cursor: se-resize;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMS8xNEDQYmMAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAQ0lEQVQYlaXNMQoAIAxD0dT7H657l0KX3iJuUlBUNOsPPCGJm7VDp6ryeMxMuDsAQH7owW3pyn3RS26iKxERMLN3ugOaAkaL3sWVigAAAABJRU5ErkJggg==");
  width: 10px;
  height: 10px;
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: scaleX(1);
}

.alertify.ajs-no-overflow .ajs-body .ajs-content {
  overflow: hidden !important;
}

.alertify.ajs-no-padding.ajs-maximized .ajs-body .ajs-content {
  padding: 0;
  left: 0;
  right: 0;
}

.alertify.ajs-no-padding:not(.ajs-maximized) .ajs-body {
  margin-left: -24px;
  margin-right: -24px;
}

.alertify.ajs-no-padding:not(.ajs-maximized) .ajs-body .ajs-content {
  padding: 0;
}

.alertify.ajs-no-padding.ajs-resizable .ajs-body .ajs-content {
  left: 0;
  right: 0;
}

.alertify.ajs-maximizable .ajs-commands button.ajs-maximize, .alertify.ajs-maximizable .ajs-commands button.ajs-restore, .alertify.ajs-closable .ajs-commands button.ajs-close {
  display: inline-block;
}

.alertify.ajs-maximized .ajs-dialog {
  width: 100% !important;
  max-width: none !important;
  height: 100% !important;
  margin: 0 auto !important;
  top: 0 !important;
  left: 0 !important;
}

.alertify.ajs-maximized.ajs-modeless .ajs-modal {
  min-height: 100% !important;
  max-height: none !important;
  margin: 0 !important;
  position: fixed !important;
}

.alertify.ajs-maximized .ajs-commands button.ajs-maximize {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAASklEQVQYlZWQ0QkAMQhDtXRincOZX78KVtrDCwgqJNEoIB3MPLj7lRUROlpyVXGzby6zWuY+kz6tj5sBMTMAyVV3/595RbOh3cAXsww1raeiOcoAAAAASUVORK5CYII=");
}

.alertify.ajs-maximized .ajs-dialog, .alertify.ajs-resizable .ajs-dialog {
  padding: 0;
}

.alertify.ajs-maximized .ajs-commands, .alertify.ajs-resizable .ajs-commands {
  margin: 14px 24px 0 0;
}

.alertify.ajs-maximized .ajs-header, .alertify.ajs-resizable .ajs-header {
  margin: 0;
  padding: 16px 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.alertify.ajs-maximized .ajs-body, .alertify.ajs-resizable .ajs-body {
  min-height: 224px;
  display: inline-block;
}

.alertify.ajs-maximized .ajs-body .ajs-content, .alertify.ajs-resizable .ajs-body .ajs-content {
  position: absolute;
  inset: 50px 24px;
  overflow: auto;
}

.alertify.ajs-maximized .ajs-footer, .alertify.ajs-resizable .ajs-footer {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.alertify.ajs-resizable:not(.ajs-maximized) .ajs-dialog {
  min-width: 548px;
}

.alertify.ajs-resizable:not(.ajs-maximized) .ajs-handle {
  display: block;
}

.alertify.ajs-movable:not(.ajs-maximized) .ajs-header {
  cursor: move;
}

.alertify.ajs-modeless .ajs-dimmer, .alertify.ajs-modeless .ajs-reset {
  display: none;
}

.alertify.ajs-modeless .ajs-modal {
  max-width: none;
  max-height: 0;
  overflow: visible;
}

.alertify.ajs-modeless.ajs-pinnable .ajs-commands button.ajs-pin {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAQklEQVQYlcWPMQ4AIAwCqU9u38GbcbHRWN1MvKQDhQFMEpKImGJA0gCgnYw0V0rwxseg5erT4oSkQVI5d9f+e9+xA0NbLpWfitPXAAAAAElFTkSuQmCC");
  display: inline-block;
}

.alertify.ajs-modeless.ajs-unpinned .ajs-modal {
  position: absolute;
}

.alertify.ajs-modeless.ajs-unpinned .ajs-commands button.ajs-pin {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAO0lEQVQYlWP8//8/AzGAiShV6AqLi4txGs+CLoBLMYbC3t5eRmyaWfBZhwwYkX2NTxPRvibKjRhW4wMAhxkYGbLu3pEAAAAASUVORK5CYII=");
}

.alertify.ajs-modeless:not(.ajs-unpinned) .ajs-body {
  max-height: 500px;
  overflow: auto;
}

.alertify.ajs-basic .ajs-header {
  opacity: 0;
}

.alertify.ajs-basic .ajs-footer {
  visibility: hidden;
}

.alertify.ajs-frameless .ajs-header {
  opacity: 0;
  z-index: 1;
  min-height: 60px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.alertify.ajs-frameless .ajs-footer {
  display: none;
}

.alertify.ajs-frameless .ajs-body .ajs-content {
  position: absolute;
  inset: 0;
}

.alertify.ajs-frameless:not(.ajs-resizable) .ajs-dialog {
  padding-top: 0;
}

.alertify.ajs-frameless:not(.ajs-resizable) .ajs-dialog .ajs-commands {
  margin-top: 0;
}

.ajs-no-overflow {
  outline: 0;
  overflow: hidden !important;
}

.ajs-no-overflow.ajs-fixed {
  position: fixed;
  inset: 0;
  overflow-y: scroll !important;
}

.ajs-no-selection, .ajs-no-selection * {
  -webkit-user-select: none;
  user-select: none;
}

@media screen and (max-width: 568px) {
  .alertify .ajs-dialog {
    min-width: 150px;
  }

  .alertify:not(.ajs-maximized) .ajs-modal {
    padding: 0 5%;
  }

  .alertify:not(.ajs-maximized).ajs-resizable .ajs-dialog {
    min-width: initial;
    min-width: auto;
  }
}

@-moz-document url-prefix() {
  .alertify button:focus {
    outline: 1px dotted #3593d2;
  }
}

.alertify .ajs-dimmer, .alertify .ajs-modal {
  transition-property: opacity, visibility;
  transition-duration: .25s;
  transition-timing-function: linear;
  transform: translate3d(0, 0, 0);
}

.alertify.ajs-hidden .ajs-dimmer, .alertify.ajs-hidden .ajs-modal {
  visibility: hidden;
  opacity: 0;
}

.alertify.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-duration: .5s;
}

.alertify.ajs-out.ajs-hidden .ajs-dialog {
  animation-duration: .25s;
}

.alertify .ajs-dialog.ajs-shake {
  animation-name: ajs-shake;
  animation-duration: .1s;
  animation-fill-mode: both;
}

@keyframes ajs-shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.alertify.ajs-slide.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-slideIn;
  animation-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

.alertify.ajs-slide.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-slideOut;
  animation-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

.alertify.ajs-zoom.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-zoomIn;
}

.alertify.ajs-zoom.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-zoomOut;
}

.alertify.ajs-fade.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-fadeIn;
}

.alertify.ajs-fade.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-fadeOut;
}

.alertify.ajs-pulse.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-pulseIn;
}

.alertify.ajs-pulse.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-pulseOut;
}

.alertify.ajs-flipx.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-flipInX;
}

.alertify.ajs-flipx.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-flipOutX;
}

.alertify.ajs-flipy.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-flipInY;
}

.alertify.ajs-flipy.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-flipOutY;
}

@keyframes ajs-pulseIn {
  0%, 100%, 20%, 40%, 60%, 80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes ajs-pulseOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  100% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

@keyframes ajs-zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.25, .25, .25);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes ajs-zoomOut {
  0% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

  100% {
    opacity: 0;
    transform: scale3d(.25, .25, .25);
  }
}

@keyframes ajs-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ajs-fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ajs-flipInX {
  0% {
    opacity: 0;
    transition-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    transition-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  100% {
    transform: perspective(400px);
  }
}

@keyframes ajs-flipOutX {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  100% {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

@keyframes ajs-flipInY {
  0% {
    opacity: 0;
    transition-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    transition-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  100% {
    transform: perspective(400px);
  }
}

@keyframes ajs-flipOutY {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  100% {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

@keyframes ajs-slideIn {
  0% {
    margin-top: -100%;
  }

  100% {
    margin-top: 5%;
  }
}

@keyframes ajs-slideOut {
  0% {
    margin-top: 5%;
  }

  100% {
    margin-top: -100%;
  }
}

.alertify-notifier {
  z-index: 1982;
  width: 0;
  position: fixed;
  overflow: visible;
  transform: translate3d(0, 0, 0);
}

.alertify-notifier .ajs-message {
  opacity: 0;
  width: 260px;
  max-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: .25s;
  transition-timing-function: linear;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.alertify-notifier .ajs-message.ajs-visible {
  opacity: 1;
  max-height: 100%;
  margin-top: 10px;
  padding: 15px;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

.alertify-notifier .ajs-message.ajs-success {
  background: #5bbd72f2;
}

.alertify-notifier .ajs-message.ajs-error {
  background: #d95c5cf2;
}

.alertify-notifier .ajs-message.ajs-warning {
  background: #fcf8d7f2;
}

.alertify-notifier .ajs-message .ajs-close {
  cursor: pointer;
  background-color: #00000080;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAABGdBTUEAALGPC/xhBQAAAFBJREFUGBl1j0EKADEIA+ve/P9f9bh1hEihNBfjVCO1v7RKVqJK4h8gM5cAPR42AkQEpSXPwMTyoi13n5N9YqJehm3Fnr7nL1D0ZEbD5OubGyC7a9gx+9eNAAAAAElFTkSuQmCC");
  background-position: center;
  background-repeat: no-repeat;
  border-top-right-radius: 2px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
}

.alertify-notifier.ajs-top {
  top: 10px;
}

.alertify-notifier.ajs-bottom {
  bottom: 10px;
}

.alertify-notifier.ajs-right {
  right: 10px;
}

.alertify-notifier.ajs-right .ajs-message {
  right: -320px;
}

.alertify-notifier.ajs-right .ajs-message.ajs-visible {
  right: 290px;
}

.alertify-notifier.ajs-left {
  left: 10px;
}

.alertify-notifier.ajs-left .ajs-message {
  left: -300px;
}

.alertify-notifier.ajs-left .ajs-message.ajs-visible {
  left: 0;
}

.alertify-notifier.ajs-center {
  left: 50%;
}

.alertify-notifier.ajs-center .ajs-message {
  transform: translateX(-50%);
}

.alertify-notifier.ajs-center .ajs-message.ajs-visible {
  transition-timing-function: cubic-bezier(.57, .43, .1, .65);
  left: 50%;
}

.alertify-notifier.ajs-center.ajs-top .ajs-message {
  top: -300px;
}

.alertify-notifier.ajs-center.ajs-top .ajs-message.ajs-visible {
  top: 0;
}

.alertify-notifier.ajs-center.ajs-bottom .ajs-message {
  bottom: -300px;
}

.alertify-notifier.ajs-center.ajs-bottom .ajs-message.ajs-visible {
  bottom: 0;
}

.ajs-no-transition.alertify .ajs-dialog, .ajs-no-transition.alertify .ajs-dimmer, .ajs-no-transition.alertify .ajs-modal, .ajs-no-transition.alertify-notifier .ajs-message {
  transition: none !important;
  animation: none !important;
}

@media (prefers-reduced-motion: reduce) {
  .alertify .ajs-dialog, .alertify .ajs-dimmer, .alertify .ajs-modal, .alertify-notifier .ajs-message {
    transition: none !important;
    animation: none !important;
  }
}

.alertify .ajs-dimmer {
  opacity: 1;
  background-color: #000000d9;
}

.alertify .ajs-dialog {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-width: 50%;
  min-height: 137px;
}

.alertify .ajs-header {
  color: #555;
  background-color: #fff;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  padding: 1.5rem 2rem;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.6em;
  font-weight: 700;
}

.alertify .ajs-body {
  color: #555;
  font-family: Roboto, sans-serif;
}

.alertify .ajs-body .ajs-content .ajs-input {
  color: #000000b3;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #00000026;
  border-radius: .3125em;
  outline: 0;
  width: 100%;
  margin: 0;
  padding: .65em 1em;
  font-size: 1em;
  transition: background-color .3s ease-out, box-shadow .2s, border-color .2s, -webkit-box-shadow .2s;
}

.alertify .ajs-body .ajs-content .ajs-input:active {
  background-color: #fafafa;
  border-color: #0000004d;
}

.alertify .ajs-body .ajs-content .ajs-input:focus {
  color: #000000d9;
  border-color: #0003;
}

.alertify.ajs-resizable .ajs-content, .alertify.ajs-maximized:not(.ajs-resizable) .ajs-content {
  top: 64px;
  bottom: 74px;
}

.alertify .ajs-footer {
  background-color: #fff;
  border-top: none;
  border-radius: 0 0 5px 5px;
  padding: 1rem 2rem;
}

.alertify-notifier .ajs-message {
  color: #000;
  text-align: center;
  background: #fffffff2;
  border: 1px solid #ddd;
  border-radius: 2px;
}

.alertify-notifier .ajs-message.ajs-success {
  color: #fff;
  text-shadow: -1px -1px #00000080;
  background: #5bbd72f2;
}

.alertify-notifier .ajs-message.ajs-error {
  color: #fff;
  text-shadow: -1px -1px #00000080;
  background: #d95c5cf2;
}

.alertify-notifier .ajs-message.ajs-warning {
  background: #fcf8d7f2;
  border-color: #999;
}

::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

::-webkit-scrollbar-button {
  background-color: #888;
}

::-webkit-scrollbar-track {
  background-color: #000;
}

::-webkit-scrollbar-track-piece {
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 3px;
  height: 50px;
}

::-webkit-scrollbar-corner {
  background-color: #646464;
}

::-webkit-resizer {
  background-color: #666;
}

form {
  background-color: #393939;
  border: 3px solid #46346e;
  border-radius: 1em;
  margin: 1em;
  padding: 1em;
}

form label {
  text-align: end;
  vertical-align: middle;
  width: 15%;
  margin-right: 1em;
  display: inline-block;
}

form input[type="text"] {
  vertical-align: middle;
  min-width: 40%;
  margin-top: 1em;
  margin-bottom: 1em;
}

form textarea {
  vertical-align: middle;
  min-width: 40%;
  height: 6em;
  margin-top: 1em;
  margin-bottom: 1em;
}

form select {
  vertical-align: middle;
  min-width: 40%;
  height: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.button {
  color: #fff;
  opacity: .8;
  background-color: #d652bb;
  border-radius: 1em;
  padding: .4em;
  font-size: 1.3em;
  box-shadow: -2px 2px #aaa;
}

.button:hover {
  opacity: 1;
  transition: all .3s;
}

.button:active {
  transform: translateY(4px);
  box-shadow: 0 4px #555;
}

.color {
  display: inline-table;
}

.colorgrid {
  grid-template-rows: repeat(auto-fill, minmax(0, 1fr));
  grid-template-columns: repeat(8, 1fr);
  display: grid;
}

.colorchoice {
  opacity: .8;
  height: 10vw;
}

.colorchoice:hover {
  opacity: 1;
}

.huebee__container {
  background: #444;
  border: 5px solid #222;
  border-radius: 0;
  top: 20px;
}

.huebee__cursor {
  border: 5px solid #19f;
}

/*# sourceMappingURL=index.f82812d2.css.map */
