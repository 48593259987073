::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}
::-webkit-scrollbar-button {
  background-color: #888;
}
::-webkit-scrollbar-track {
  background-color: #000;
}
::-webkit-scrollbar-track-piece {
  background-color: #000;
}
::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #444;
  border-radius: 3px;
}
::-webkit-scrollbar-corner {
  background-color: #646464;
}
::-webkit-resizer {
  background-color: #666;
}

form {
  padding: 1em;
  margin: 1em;
  border: 3px solid #46346e;
  border-radius: 1em;
  background-color: #393939;
}
form label {
  text-align: end;
  display: inline-block;
  width: 15%;
  margin-right: 1em;
  vertical-align: middle;
}
form input[type='text'] {
  min-width: 40%;
  margin-top: 1em;
  margin-bottom: 1em;
  vertical-align: middle;
}
form textarea {
  min-width: 40%;
  height: 6em;
  margin-top: 1em;
  margin-bottom: 1em;
  vertical-align: middle;
}
form select {
  min-width: 40%;
  height: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
  vertical-align: middle;
}
.button {
  font-size: 1.3em;
  padding: 0.4em;
  border-radius: 1em;
  color: #fff;
  background-color: #d652bb;
  opacity: 0.8;
  box-shadow: -2px 2px #aaa;
}
.button:hover {
  transition: 0.3s;
  opacity: 1;
}
.button:active {
  box-shadow: 0 4px #555;
  transform: translateY(4px);
}
