.color {
  display: inline-table;
}

.colorgrid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(auto-fill, minmax(0px, 1fr));
}

.colorchoice {
  height: 10vw;
  opacity: 0.8;
}

.colorchoice:hover {
  opacity: 1;
}

.huebee__container {
  top: 20px; /* position */
  background: #444;
  border: 5px solid #222;
  border-radius: 0;
}

.huebee__cursor {
  border: 5px solid #19f;
}
