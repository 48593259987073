.dark {
  background-color: #222;
  color: #ddd;
}

.dark h1,
.dark h2,
.dark h3 {
  color: #fff;
}

.dark h4 {
  color: #888;
}
.dark a {
  color: #aac;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
.dark a:hover {
  color: white;
}
nav a {
  color: black;
}
.dark nav a {
  color: #ccc;
  text-decoration: none;
}

.layout {
  display: grid;
}

.menu {
  display: flex;
  justify-content: space-between;
  margin: 1em 1em 0.5em;
  padding: 0.5em 1em;
  background: #8883;
}
.board {
  padding-bottom: 100%;
}
.gamePage .board {
  height: 0;
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 3;
  grid-row-end: 9;
}
.cg-wrap coords.ranks {
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.cg-wrap coords.files {
  left: 0px;
  top: 0px;
  bottom: 0px;
}
.myturn {
  background: #e5e0b6;
}
.dark .myturn {
  background: #777;
}
.gamethumb {
  border: wheat;
  border-style: outset;
  margin: 4px;
  padding: 4px;
  width: 100%;
  min-width: -webkit-fill-available;
  overflow: hidden;
}
.gamethumb:hover {
  background: #fff;
  overflow: visible;
}
.dark .gamethumb:hover {
  color: black;
  background: #fffdea;
}
.selector {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(auto-fill, minmax(0px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border: burlywood;
  border-style: groove;
  width: fit-content;
}
section {
  padding: 1em;
}
.fullscreen {
  height: 75vh;
  max-width: 75vh;
}
.status {
  display: inline-flex;
  margin-right: 1em;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  border: solid;
  border-width: 1.5px;
  border-color: white;
}
.connected {
  background-color: green;
}
.disconnected {
  background-color: red;
}
.logo {
  width: 4em;
}
i {
  border-style: solid;
  border-color: #393939;
  border-radius: 10px;
  padding: 0.5rem;
  width: fit-content;
}
i:hover {
  background-color: #393939;
}
i:active {
  animation: blinker 0.2s 1;
}
.cui-button {
  border-radius: 5px;
  box-shadow: 0 1px 0 #777;
}
.dark .cui-button {
  border-style: solid;
  border-color: #393939;
  background: linear-gradient(to bottom, #3c3934, #33312e 100%);
  color: #aaa;
}
.glow {
  box-shadow: 0px 0px 3px 1px #d753bb;
}
.profile {
  padding-bottom: 1em;
  display: grid;
  margin: 1em;
  padding: 1em;
  border-radius: 1em;
  overflow: auto;
  background: #88888833;
}
.dark .profile {
  background: #444;
}
.toolbar {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-column-end: 10;
  grid-column-start: 1;
  align-items: center;
  justify-items: center;
}
.inline {
  display: inline-grid;
}
.tool {
  margin-right: 1em;
}
.vl {
  border-left: thick solid #393939;
}
.turn {
  padding: 0.5rem;
  min-width: 2em;
  min-height: 2em;
}
.w {
  background-color: white;
  color: black;
}
.b {
  background-color: black;
  color: white;
}
.gamePage {
  width: 100%;
  max-width: min(82vw, 82vh);
  height: 70vh;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(12, 1fr);
}
.top_user {
  grid-row-start: 2;
}
.bottom_user {
  grid-row-start: 12;
}
.svgicon {
  width: 1.5rem;
}
